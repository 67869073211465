import React from "react";
import { useTheme } from "@mui/styles";
import { Box, Typography, useMediaQuery } from "@mui/material";
import json2mq from "json2mq";
import { useLocation } from "react-router-dom";
import useTranslation from "../../hooks/useTranslation";

const useStyles = (theme, isMobile) => ({
  container: {
    borderRadius: "53px",
    display: "flex",
    justifyContent: { xs: "center" },
    alignItems: "center",
    bgcolor: theme.palette.white.main,
    flexDirection: { xs: "column", md: "row" },
    zIndex: 1,
    boxShadow: { md: "0 4px 92px 0 rgba(0, 31, 85, 0.06)" },
    width: {
      xs: "100vw",
      sm: "100vw",
      md: "850px",
      lg: "1100px",
      xl: "1275px",
    },
    height: { md: "304.66px", lg: "304.66px", xl: "457px" },
    mb: isMobile ? "3rem" : "15rem",
  },
  img: {
    borderRadius: "53px",
  },
  name: {
    fontFamily: theme.typography.montserrat.bold,
    color: theme.palette.blue.main,
    fontSize: { xs: "18px", sm: "20px", md: "20px", lg: "22px", xl: "30px" },
    lineHeight: "36px",
    width: { md: "100%" },
  },
  subtitle: {
    fontFamily: theme.typography.montserrat.regular,
    fontSize: {
      xs: "20px",
      lg: "21.5px",
      xl: "22px",
    },
    lineHeight: "36px",
    color: theme.palette.blue.main,
    width: { md: "100%" },
  },
  text: {
    fontFamily: theme.typography.anaHeim.regular,
    fontSize: { xs: "25px", md: "19px", lg: "24px", xl: "29px" },
    lineHeight: {
      xs: "33px",
      lg: "35px",
      xl: "44px",
    },
    color: theme.palette.blue.main,
    textAlign: { xs: "center", md: "start" },
    maxWidth: {
      xs: "268px",
      sm: "398.75px",
      md: "500px",
      lg: "660.25px",
      xl: "791px",
    },
  },
  testimony: {
    display: "flex",
    flexDirection: "column",
    alignItems: { xs: "center", md: "center", lg: "center", xl: "center" },
    height: { md: "70%", lg: "90%", xl: "auto" },
    justifyContent: "space-evenly",
  },
});

const FounderTestimony = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:899px)");
  const classes = useStyles(theme, isMobile);

  const xs = useMediaQuery(
    json2mq({
      maxWidth: 899,
    })
  );
  const md = useMediaQuery(
    json2mq({
      maxWidth: 1200,
      minWidth: 900,
    })
  );
  const lg = useMediaQuery(
    json2mq({
      maxWidth: 1535,
      minWidth: 1201,
    })
  );

  const stylesHelper = () => {
    if (xs)
      return { img: { width: "auto", height: "auto", marginTop: "60px" } };
    else if (md)
      return { img: { width: "300px", height: "325px", marginTop: "20px" } };
    else if (lg)
      return { img: { width: "300px", height: "325px", marginTop: "35px" } };
  };

  const imageMeasures = stylesHelper() && stylesHelper().img;
  const { pathname } = useLocation();
  const { founderImg, words, identity } = useTranslation(
    pathname,
    "FounderTestimony"
  );

  return (
    <Box sx={classes.container}>
      <img
        src={founderImg.src}
        alt={founderImg.alt}
        style={{ ...classes.img, ...imageMeasures }}
      />

      <Box sx={classes.testimony}>
        <Typography sx={classes.text}>
          {words}
          <br></br>
          <br></br>
          <Typography sx={classes.name}>
            {identity[0]}
            <br></br>
            {identity[1]}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

export default FounderTestimony;
