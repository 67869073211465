import { Box, Button, keyframes } from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useState } from "react";
import TranslationText from "../Translations/TranslationText";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CircleIcon from "@mui/icons-material/Circle";

const showUp = keyframes`
  from {
  height: 0;
  font-size: 0;
  margin: 0;
  padding: 0;
  } to {
  height: auto;
  font-size: inherit;
  margin: inherit;
  padding: 12px 20px,
  }
  `;

const hide = keyframes`
  from {
  height: auto;
  font-size: inherit;
  margin: inherit;
  padding: inherit;
  } to {
  height: 0;
  font-size: 0;
  margin: 0;
  padding: 0;
  }
  `;

const fadeIn = keyframes`
  from {
  opacity: 0;
  } to {
  opacity: 1;
  }
  `;

const fadeOut = keyframes`
  from {
  opacity: 1;
  } to {
  opacity: 0;
  }
  `;

const useStyles = (theme, isExpanded) => ({
  container: {
    mb: isExpanded ? "20px" : undefined,
  },
  tab: {
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
    borderTop: "1px solid #bcc5cfb5",
    borderBottom: "1px solid #bcc5cfb5",
    borderRadius: 0,
    textTransform: "none",
    "&:hover": {
      background: "rgba(28, 122, 217, 0.1)",
    },
    p: "12px 20px",
  },
  selected: {
    background: "rgba(28, 122, 217, 0.1)",
  },
  title: {
    container: {
      display: "inline-block",
      minWidth: "216px",
      mr: "16px",
    },
    bold: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "36px",
      color: theme.palette.primary.main,
    },
  },
  subtitle: {
    normal: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "36px",
      color: theme.palette.primary.main,
    },
  },
  tabIcon: { color: theme.palette.lightBlue, fontSize: "24px" },
  content: {
    p: "12px 20px",
  },
  description: {
    container: {
      mb: "20px",
    },
    normal: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "20px",
      color: theme.palette.primary.main,
    },
  },
  columns: {
    display: "flex",
  },
  column: {
    p: "4px 40px 4px 24px",
    backgroundColor: "transparent",
    borderLeft: "1px dashed rgba(171, 171, 171, 0.6)",
  },
  columnTitle: {
    container: {
      mb: "4px",
      lineHeight: 0,
    },
    bold: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "20px",
      color: theme.palette.primary.main,
    },
  },
  item: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  columnItem: {
    normal: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "20px",
      color: theme.palette.primary.main,
    },
  },
  circleIcon: {
    alignSelf: "flex-start",
    margin: "12px 0 0 0",
    color: theme.palette.lightBlue,
    fontSize: "6px",
  },
  showUp: {
    animation: `${fadeIn} 0.25s ease-in-out forwards, ${showUp} 0.25s ease-in-out forwards`,
  },
  hide: {
    animation: `${fadeOut} 0.25s ease-in-out forwards, ${hide} 0.25s ease-in-out forwards`,
  },
  pointerEventsNone: {
    pointerEvents: "none",
  },
});

const AccordionTab = ({
  title,
  subtitle,
  description,
  columns,
  initiallyExpanded = false,
}) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(initiallyExpanded);
  const classes = useStyles(theme, isExpanded);

  return (
    <Box sx={classes.container}>
      <Button
        sx={{ ...classes.tab, ...(isExpanded && classes.selected) }}
        onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
      >
        <Box component="span">
          <TranslationText
            component="span"
            textSx={{ bold: classes.title.bold }}
            sx={classes.title.container}
          >
            {title}
          </TranslationText>
          <TranslationText
            component="span"
            textSx={{ normal: classes.subtitle.normal }}
          >
            {subtitle}
          </TranslationText>
        </Box>
        {isExpanded ? (
          <RemoveIcon sx={classes.tabIcon} />
        ) : (
          <AddIcon sx={classes.tabIcon} />
        )}
      </Button>
      <Box
        sx={{
          ...classes.content,
          ...(isExpanded ? classes.showUp : classes.hide),
          ...(!isExpanded && classes.pointerEventsNone),
        }}
      >
        <TranslationText
          textSx={{ normal: classes.description.normal }}
          sx={classes.description.container}
        >
          {description.desktop}
        </TranslationText>
        <Box sx={classes.columns}>
          {columns.map(({ title, items }) => (
            <Box sx={classes.column} key={title}>
              <TranslationText
                sx={classes.columnTitle.container}
                textSx={{ bold: classes.columnTitle.bold }}
              >
                {title}
              </TranslationText>
              {items.map((item) => (
                <Box sx={classes.item} key={item}>
                  <CircleIcon sx={classes.circleIcon} />
                  <TranslationText textSx={{ bold: classes.columnItem.normal }}>
                    {item}
                  </TranslationText>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default AccordionTab;
