import { Box, SvgIcon } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import TranslationText from "../components/Translations/TranslationText";
import useTranslation from "../hooks/useTranslation";
import { useLocation } from "react-router-dom";

const useStyles = (theme) => ({
  container: {
    boxSizing: "border-box",
    backgroundColor: theme.palette.background.lightGray,
    width: "100%",
    p: {
      xl: "60px 96px 72px 96px",
      lg: "60px 96px 72px 96px",
      md: "60px 96px 72px 96px",
      xs: "60px 24px 72px 24px",
    },
  },
  subContainer: {
    boxSizing: "border-box",
    background: "linear-gradient(180deg, #F4F4F4 0%, #FFF 100%)",
    boxShadow: "5px 5px 20px 0px rgba(79, 79, 79, 0.20)",
    borderRadius: "60px",
    p: {
      xs: "12px",
      md: "12px",
      lg: "12px 12px 12px 60px",
      xl: "12px 12px 12px 60px",
    },
    display: "flex",
    flexDirection: { xs: "column", md: "column", lg: "row", xl: "row" },
    justifyContent: "space-between",
    alignItems: "center",
    gap: { xs: "16px", md: "16px", lg: "28px", xl: "28px" },
  },
  title: {
    container: {
      textAlign: { xs: "center", md: "center", lg: "left", xl: "left" },
      p: { xs: "0 32px", md: "16px", lg: "0px", xl: "0px" },
    },
    normal: {
      color: "#001F55",
      fontFamily: theme.typography.Montserrat,
      fontSize: { xs: "25px", md: "25px", lg: "40px", xl: "40px" },
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "48px",
    },
  },
  listContainer: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    p: { xs: "32px", md: "48px", lg: "56px", xl: "56px" },
    borderRadius: "60px",
    background: "rgba(28, 122, 217, 0.10)",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },
  dotImg: {
    width: "10px",
    height: "10px",
    alignSelf: "flex-start",
    mt: "16px",
  },
  listText: {
    container: {
      textAlign: "left",
    },
    normal: {
      color: "#001F55",
      fontFamily: theme.typography.Anaheim,
      fontSize: { xs: "18px", md: "18px", lg: "22px", xl: "22px" },
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "40px",
    },
    italic: {
      color: "#001F55",
      fontFamily: theme.typography.Anaheim,
      fontSize: { xs: "18px", md: "18px", lg: "22px", xl: "22px" },
      fontStyle: "italic",
      fontWeight: 400,
      lineHeight: "40px",
    },
  },
});

const DescriptionBanner = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { pathname } = useLocation();
  const { title, list } = useTranslation(pathname, "DescriptionBanner");

  return (
    <Box sx={classes.container}>
      <Box sx={classes.subContainer}>
        <TranslationText sx={classes.title.container} textSx={classes.title}>
          {title}
        </TranslationText>
        <Box sx={classes.listContainer}>
          {list.map((item) => (
            <Box sx={classes.listItem} key={item}>
              <SvgIcon
                sx={classes.dotImg}
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="5"
                  transform="rotate(90 5 5)"
                  fill="#1C7AD9"
                />
              </SvgIcon>
              <TranslationText
                sx={classes.listText.container}
                textSx={classes.listText}
              >
                {item}
              </TranslationText>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default DescriptionBanner;
