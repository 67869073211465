import React from "react";
import { Box } from "@mui/material";
import Sections from "../components/FrequentQuestions/Sections";
import Hero from "../commons/Hero";
import OverlapText from "../commons/OverlapText";

const useStyles = () => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
});

const IntroductoryCourse = () => {
  const classes = useStyles();

  return (
    <Box sx={classes.container}>
      <Hero onlyTitle />
      <OverlapText componentName="FaqsText" />
      <Sections />
    </Box>
  );
};

export default IntroductoryCourse;
