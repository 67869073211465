const COLORS = {
  green: "rgba(91, 212, 81, 1)",
  pink: "rgba(232, 50, 236, 1)",
  gold: "rgba(236, 173, 50, 1)",
};

export const code = [
  [
    { phrase: "const seconds", color: COLORS.gold },
    { phrase: "=", color: COLORS.pink },
    { phrase: "3600const minutes", color: COLORS.green },
  ],
  [
    { phrase: "=", color: COLORS.pink },
    { phrase: "seconds /", color: COLORS.gold },
    { phrase: "60const hours = minutes", color: COLORS.green },
  ],
  [
    { phrase: "/", color: COLORS.gold },
    { phrase: "60const days", color: COLORS.green },
    { phrase: "=", color: COLORS.pink },
    { phrase: "hours / 24const", color: COLORS.green },
  ],
  [
    { phrase: "weeks", color: COLORS.green },
    { phrase: "=", color: COLORS.pink },
    { phrase: "days / 7const months", color: COLORS.green },
    { phrase: "=", color: COLORS.pink },
  ],
  [
    { phrase: "days / 30const years", color: COLORS.gold },
    { phrase: "=", color: COLORS.pink },
    { phrase: "months / 12", color: COLORS.gold },
  ],
];
