import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useLocation } from "react-router-dom";
import useTranslation from "../../hooks/useTranslation";
import TranslationSectionTitle from "../../commons/TranslationSectionTitle";

const useStyles = (theme, isMobile) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: isMobile ? "50px" : "100px",
  },
  title: {
    container: {
      mt: "80px",
      mb: "32px",
    },
  },
  line: {
    width: "25px",
    border: `1px solid ${theme.palette.red.main}`,
    marginRight: "8px",
    backgroundColor: theme.palette.red.main,
  },
  inputs: {
    width: "100%",
    height: "fit-content",
    padding: "0",
    margin: "26px 0 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    select: {
      minWidth: "276px",
      height: "47px",
      borderRadius: "56px",
      boxShadow: "0 1px 12px 0 rgba(0, 0, 0, 0.1)",
      color: theme.palette.blue.main,
    },
  },
  sliderContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "visible",
    marginTop: "30px",
  },
  slider: {
    marginTop: isMobile ? "0" : "110px",
    width: "98%",
    height: "fit-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "visible",
  },
  typography: {
    section: {
      fontSize: isMobile ? "14px" : "16px",
      fontWeight: "bold",
      letterSpacing: "2px",
      color: theme.palette.red.main,
      marginTop: isMobile ? "59px" : "0",
    },
    sectionDescription: {
      fontSize: isMobile ? "32px" : { xl: "65px", md: "55px", xs: "50px" },
      fontFamily: theme.typography.montserrat.regular,
      color: theme.palette.blue.main,
      marginTop: isMobile ? "24px" : "10px",
      width: isMobile ? "170px" : "fit-content",
      subtitle: {
        fontSize: isMobile ? "18px" : { xl: "28px", md: "28px", xs: "28px" },
        width: isMobile ? "90%" : "80%",
        maxWidth: isMobile ? "350px" : "1500px",
        color: theme.palette.blue.main,
        fontFamily: theme.typography.montserrat.regular,
      },
    },
  },
});

const Booking = () => {
  const isMobile = useMediaQuery("(max-width:899px)");
  const theme = useTheme();
  const classes = useStyles(theme, isMobile);
  const { pathname } = useLocation();
  const { normal } = useTranslation(pathname, "Booking");

  return isMobile ? (
    <Box style={classes.container}>
      <TranslationSectionTitle
        title={normal.title}
        subtitle={normal.subtitle}
        sx={classes.title.container}
      />
      <Typography sx={classes.typography.sectionDescription.subtitle}>
        {normal.nextDates}
      </Typography>
    </Box>
  ) : (
    <Box style={classes.container}>
      <TranslationSectionTitle
        title={normal.title}
        subtitle={normal.subtitle}
        sx={classes.title.container}
      />
      <Typography sx={classes.typography.sectionDescription.subtitle}>
        {normal.nextDates}
      </Typography>
    </Box>
  );
};

export default Booking;
