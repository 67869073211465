import styled from "@emotion/styled";

import { IconButton, Menu as MenuMui, Typography } from "@mui/material";
import MenuIconMui from "@mui/icons-material/Menu";

export const MenuIconContainer = styled(IconButton)(({ theme, open }) => ({
  borderRadius: "20px",
  width: "57px",
  height: "38px",
  border: `1px solid ${theme.palette.primary.main}`,
  background: open ? "rgba(28, 122, 217, 0.1)" : "transparent",
  "@media (min-width:960px)": {
    display: "none",
  },
}));

export const MenuIcon = styled(MenuIconMui)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const Menu = styled(MenuMui)(({ _theme }) => ({
  transform: "translate(-17px, 5px)",
  borderRadius: "20px 0 20px 20px",
  ".MuiMenu-list": {
    borderRadius: "20px 0 20px 20px",
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
  ".MuiMenu-paper": {
    borderRadius: "20px 0 20px 20px",
    width: "230px",
  },
}));

export const MenuText = styled(Typography)({
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "25px",
});

export const ArrowIconMenuButton = styled("img")(({ collapse }) => ({
  transition: "transform 0.2s",
  transform: collapse ? "rotate(180deg)" : "none",
  padding: "12px 0",
}));

export const ArrowIconOption = styled("img")(({ suboption }) => ({
  width: "7px",
  height: "10px",
  transform: suboption ? "rotate(180deg)" : "rotate(-90deg)",
  marginLeft: "7px",
}));
