import React from "react";

import { Box, useMediaQuery } from "@mui/material";
import Carrousel from "../../../commons/Carrousel";
import Testimonial from "../../../commons/Cards/Testimonial";

import useTranslation from "../../../hooks/useTranslation";
import { useLocation } from "react-router";

import { CarrouselItem, Container, Title, Subtitle } from "./styles";

import { getSlidesToShow } from "../../../helpers/carrousel";

const Testimonials = () => {
  const { pathname } = useLocation();
  const componentName = "Testimonials";
  const { title, section, testimonials } = useTranslation(
    pathname,
    componentName
  );

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isDesktop = useMediaQuery("(min-width: 1500px)");

  const slidesToShow = getSlidesToShow(isDesktop, isMobile);

  const renderTestimonialsCarrouselItems = () =>
    testimonials.map((testimonial) => (
      <CarrouselItem key={`bootcamp-testimonial-${testimonial.name}`}>
        <Testimonial
          key={`bootcamp-testimonial-${testimonial.name}`}
          {...testimonial}
        />
      </CarrouselItem>
    ));

  return (
    <Container>
      <Box>
        <Subtitle variant="h3"> {section} </Subtitle>
        <Title variant="h2">
          <b>{title[0]}</b>
          <br />
          {title[1]}
        </Title>
      </Box>
      <Carrousel slidesToShow={slidesToShow} slidesToScroll={slidesToShow}>
        {renderTestimonialsCarrouselItems()}
      </Carrousel>
    </Container>
  );
};

export default Testimonials;
