import React from "react";
import Stars from "../../img/Course intro stars.svg";
import json2mq from "json2mq";
import { Box, Divider, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useLocation } from "react-router-dom";
import useTranslation from "../../hooks/useTranslation";

const useStyles = (theme, isMobile) => ({
  divider: {
    marginTop: isMobile ? "77.5px" : "163px",
    width: "80%",
    height: "15px",
    color: "black",
  },
  container: {
    width: "100%",
    marginTop: isMobile ? "75px" : { xl: "128px", lg: "128px", md: "128px" },
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  info: {
    display: "flex",
    flexDirection: "column",
    justifyContent: isMobile ? "center" : "flex-start",
    alignItems: isMobile ? "center" : "flex-start",
    marginTop: "50px",
  },
  typography: {
    title: {
      marginTop: "33px",
      fontSize: isMobile ? "32px" : { xl: "65px", lg: "60px", md: "55px" },
      fontFamily: theme.typography.montserrat.bold,
      color: theme.palette.blue.main,
    },
    subtitle: {
      textAlign: isMobile ? "center" : "left",
      marginTop: "35px",
      width: isMobile ? "279px" : "559px",
      fontFamily: theme.typography.montserrat.regular,
      fontSize: isMobile ? "23px" : { xl: "30px", lg: "28px", md: "25px" },
      color: theme.palette.blue.main,
    },
  },
});

const ClassCard = () => {
  const isMobile = useMediaQuery("(max-width:899px)");
  const theme = useTheme();
  const classes = useStyles(theme, isMobile);
  const { pathname } = useLocation();
  const { title, subtitle, img } = useTranslation(pathname, "ClassCard");

  const xs = useMediaQuery(
    json2mq({
      maxWidth: 899,
    })
  );
  const md = useMediaQuery(
    json2mq({
      maxWidth: 1200,
    })
  );
  const lg = useMediaQuery(
    json2mq({
      maxWidth: 1640,
      minWidth: 1201,
    })
  );
  const xl = useMediaQuery(
    json2mq({
      minWidth: 1641,
    })
  );

  const stylesHelper = () => {
    if (xs) return { img: { width: "95%", marginTop: "2rem" } };
    if (md) return { img: { width: "35%" } };
    if (lg) return { img: { width: "40%" } };
    if (xl) return { img: { width: "40%" } };
  };

  return (
    <>
      <Divider sx={classes.divider} />
      <Box sx={classes.container}>
        <Box sx={classes.info}>
          <img src={Stars} alt="estrellas" />
          <Typography sx={classes.typography.title}>{title}</Typography>
          <Typography sx={classes.typography.subtitle}>
            {subtitle[0]}
            <b>{subtitle[1]}</b>
          </Typography>
        </Box>
        <img
          src={img.src}
          alt={img.alt}
          style={stylesHelper() && stylesHelper().img}
        />
      </Box>
      <Divider sx={classes.divider} />
    </>
  );
};

export default ClassCard;
