import styled from "@emotion/styled";
import { Box } from "@mui/material";
import gridBackground from "../../../img/GetToKnowUs/gridBackground.svg";

export const Container = styled("div")(({ theme, isMobile }) => ({
  backgroundImage: !isMobile ? `url(${gridBackground})` : "",
  // backgroundSize: "cover",
  backgroundPosition: "20%",
  borderTop: isMobile && "1px solid rgba(171, 171, 171, 0.6)",
  width: "90%",
  padding: "5rem 12rem",
  [theme.breakpoints.down("md")]: {
    padding: "0",
    paddingTop: "3rem",
  },
}));

export const MediaBox = styled(Box)`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;

  @media (max-width: 1200px) {
    justify-content: space-around;
    flex-wrap: wrap;
  }
`;

export const MediaImg = styled("img")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "8rem",
    height: "8rem",
    objectFit: "scale-down",
  },

  [theme.breakpoints.between("md", "xl")]: {
    width: "11rem",
    height: "11rem",
    objectFit: "scale-down",
  },

  [theme.breakpoints.up("xl")]: {
    width: "12rem",
    height: "12rem",
    objectFit: "scale-down",
  },
}));
