import styled from "@emotion/styled";
import { Paper, Button, Typography } from "@mui/material";

export const MenuOption = styled(Paper)(({ theme }) => ({
  background: theme.palette.background.paper,
  padding: "17px 0 17px 0",
  display: "flex",
  flexDirection: "column",
}));

export const MenuButton = styled(Button)({
  borderRadius: 0,
  padding: "8px 0",
  display: "flex",
  gap: "7px",
  "&:hover": {
    opacity: 1,
    background: "transparent",
  },
  "&:active": {
    transform: "none",
  },
});

export const ArrowIconMenuButton = styled("img")(({ collapse }) => ({
  transition: "transform 0.2s",
  transform: collapse ? "rotate(180deg)" : "none",
  padding: "12px 0",
}));

export const ButtonOption = styled(Button)({
  padding: "5px 60px 5px 25px",
  borderRadius: 0,
  textTransform: "none",
  textAlign: "left",
  display: "flex",
  gap: "5px",
  justifyContent: "flex-start",
  "&:hover": {
    background: "rgba(28, 122, 217, 0.10)",
  },
});

export const TextOption = styled(Typography)({
  fontSize: "12px",
});

export const ArrowIconOption = styled("img")(({ suboption }) => ({
  width: "7px",
  height: "10px",
  transform: suboption ? "rotate(180deg)" : "rotate(-90deg)",
}));
