import styled from "@emotion/styled";
import { Box, Divider as MuiDivider } from "@mui/material";
import TranslationText from "../Translations/TranslationText";

export const Container = styled(Box)(({ theme }) => ({
  boxSizing: "border-box",
  width: "100%",
  marginTop: "64px",
  background: theme.palette.primary.main,
  padding: "64px 104px",
  [theme.breakpoints.down("md")]: {
    padding: "60px 36px",
  },
}));

export const RatingBox = styled(Box)(({ theme, isMobile }) => ({
  display: "flex",
  flexDirection: isMobile ? "column" : "row",
  gap: isMobile ? "32px" : "48px",
  justifyContent: "space-between",
  alignItems: "center",
  background: "#1C79D94D",
  borderRadius: "60px",
  padding: "32px 60px",
  [theme.breakpoints.down("md")]: {
    padding: "48px 40px",
  },
  [theme.breakpoints.up("sm")]: {},
  [theme.breakpoints.up("xs")]: {},
}));

export const TextContent = styled(Box)(({ theme }) => ({
  // marginRight: "48px",
  [theme.breakpoints.up("lg")]: {},
  [theme.breakpoints.up("md")]: {},
  [theme.breakpoints.up("sm")]: {},
  [theme.breakpoints.up("xs")]: {},
}));

export const Text = styled(TranslationText)(({ theme }) => ({
  color: theme.palette.background.lightGray,
}));

export const RankingContent = styled(Box)(({ theme, isMobile }) => ({
  display: "flex",
  flexDirection: isMobile ? "column" : "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "44px",
  [theme.breakpoints.up("lg")]: {},
  [theme.breakpoints.up("md")]: {},
  [theme.breakpoints.up("sm")]: {},
  [theme.breakpoints.up("xs")]: {},
}));

export const Separator = styled(MuiDivider)(({ theme }) => ({
  background: "#1C7AD9",
  // width: "1px",
  [theme.breakpoints.up("md")]: {},
  [theme.breakpoints.up("sm")]: {},
  [theme.breakpoints.up("xs")]: {},
}));
