import styled from "@emotion/styled";

import { Box, Typography } from "@mui/material";

export const Code = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

export const Container = styled(Box)({
  backgroundColor: "#010E25",
  padding: "3rem 1rem 1rem 3rem",
  width: "309px",
  height: "144px",
  boxShadow: "5px 5px 20px 0px rgba(0, 0, 0, 0.25)",
  borderRadius: "35px 35px 0px 35px",
});

export const CodeLine = styled(Box)({
  display: "flex",
  gap: "5px",
});

export const CodeText = styled(Typography)({
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "20px",
  margin: 0,
});

export const CodeNumber = styled(Typography)({
  color: "#D7D7D7",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
  marginRight: "12px",
  width: "8px",
  display: "flex",
  justifyContent: "center",
});
