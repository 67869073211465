import styled from "@emotion/styled";

export const Container = styled("div")(({ _theme }) => ({
  width: "90%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "58px",
}));

export const ArrowContainer = styled("span")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const ArrowImage = styled("img")(({ theme }) => ({
  color: theme.palette.grey.main,
  width: "1rem",
}));
