import styled from "@emotion/styled";

import { Box, Typography } from "@mui/material";

export const CarrouselItem = styled(Box)`
  overflow: hidden;
  background: transparent;
  padding: 60px 0 40px 0;
  display: flex !important;
  justify-content: center;
`;

export const Container = styled(Box)({
  width: "100%",
  margin: "4rem 0",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "3rem",
});

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  textAlign: "center",
  fontSize: "25px",
  lineHeight: "35px",

  [theme.breakpoints.up("md")]: {
    fontSize: "32px",
    lineHeight: "45px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "40px",
  },
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.lightBlue,
  fontFamily: theme.typography.Anaheim.fontFamily,
  fontWeight: 400,
  textAlign: "center",
  lineHeight: "30px",
  fontSize: "20px",
  [theme.breakpoints.up("sm")]: {
    fontSize: "22px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "26px",
  },
}));
