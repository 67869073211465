import React from "react";
import { Link, useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import useTranslation from "../../../hooks/useTranslation";
import { Container, MediaBox, MediaImg } from "./styles";
import TranslationSectionTitle from "../../../commons/TranslationSectionTitle";

const GetToKnowUs = () => {
  const isMobile = useMediaQuery("(max-width:899px)");
  const { pathname } = useLocation();
  const { title, media } = useTranslation(pathname, "GetToKnowUs");

  return (
    <Container isMobile={isMobile}>
      <TranslationSectionTitle
        title={title}
        subtitle={""}
        containerSx={{ width: "55%", margin: "auto" }}
      />

      <MediaBox>
        {media.map(({ link, img, name }, index) => {
          return (
            <Link href={link} key={name}>
              <MediaImg src={img} alt={index} key={index} />
            </Link>
          );
        })}
      </MediaBox>
    </Container>
  );
};

export default GetToKnowUs;
