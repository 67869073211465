/** @format */

import React from "react";

//material ui
import { useTheme } from "@mui/styles";
import { Box, Typography, useMediaQuery } from "@mui/material";

//components
import MoreInfoButton from "../Buttons/MoreInfo";

const useStyles = (theme, _wantToApplyButton, background) => ({
  container: {
    backgroundImage: { md: `url(${background})` },
    backgroundRepeat: { md: "no-repeat" },
    background: {
      xs: "linear-gradient(to bottom, #011f54 0%, #03183e 100%)",
      md: "auto",
    },
    backgroundSize: { md: "contain" },
    height: {
      xs: "411px",
      sm: "487.75px",
      md: "564.5px",
      lg: "641.25px",
      xl: "718px",
    },
    width: { xs: "287px", sm: "362px", md: "437px", lg: "512px", xl: "587px" },
    borderRadius: "33px",
    display: "flex",
    justifyContent: { xs: "center", md: "flex-start" },
    alignItems: "center",
    flexDirection: "column",
  },
  img: {
    width: "139px",
    height: "143px",
    marginTop: "41px",
  },
  title: {
    maxWidth: { xs: "233px", md: "294px" },
    color: theme.palette.white.main,
    fontFamily: theme.typography.montserrat.bold,
    fontSize: { xs: "25px", sm: "27px", md: "27px", lg: "31px", xl: "33px" },
    lineHeight: {
      xs: "32px",
      sm: "33.25px",
      md: "32.23px",
      lg: "35.75px",
      xl: "37px",
    },
    textAlign: "center",
    height: {
      xs: "68px",
      sm: "72.25px",
      md: "72.25px",
      lg: "80.75px",
      xl: "85px",
    },
    mt: { xs: "10px", sm: "10px", md: "245px", lg: "305px", xl: "359px" },
    mb: { xs: "5px", sm: "5.75px", md: "6.5px", lg: "7.25px", xl: "8px" },
  },
  subtitle: {
    fontFamily: theme.typography.anaHeim.regular,
    fontSize: {
      xs: "17px",
      sm: "17.75px",
      md: "17.75px",
      lg: "19.25px",
      xl: "20px",
    },
    lineHeight: {
      xs: "23px",
      sm: "25.25px",
      md: "26px",
      lg: "29.75px",
      xl: "32px",
    },
    textAlign: "center",
    maxWidth: {
      xs: "211px",
      sm: "245.75px",
      md: "280.5px",
      lg: "315.25px",
      xl: "350px",
    },
    color: theme.palette.white.main,
    height: "78px",
  },
});

const BootcampOptions = ({
  title,
  subtitle,
  img,
  mb,
  wantToApplyButton,
  background,
  redirect,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme, wantToApplyButton, background);

  const isMobile = useMediaQuery("(max-width:899px)");

  return (
    <Box sx={classes.container}>
      {isMobile && <img src={img} alt="ilustration" style={classes.img} />}

      <Typography sx={classes.title}>{title}</Typography>

      <Typography sx={{ ...classes.subtitle, mb }}>{subtitle}</Typography>

      <MoreInfoButton
        mb="28px"
        mt="13px"
        navigateTo={redirect}
        mainColor="#fbbf30"
        secondaryColor="#eda135"
        iconColor="#e39b2e"
      />
    </Box>
  );
};

export default BootcampOptions;
