import styled from "@emotion/styled";

import { Box } from "@mui/material";

export const Menu = styled(Box)({
  display: "none",
  "@media (min-width:960px)": {
    display: "flex",
    gap: "20px",
  },
});
