import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

export const Container = styled(Box)(({ theme, ismobile }) => ({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  flexDirection: "column",
  height: !ismobile ? "100%" : "auto",
  width: "70%",

  [theme.breakpoints.up("lg")]: {
    padding: "none",
  },
}));

export const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.blue.main,
  fontFamily: theme.typography.Anaheim.fontFamily,
  textAlign: "center",
  marginTop: "26.5px",

  [theme.breakpoints.up("xs")]: {
    fontSize: "19px",
    lineHeight: "24px",
  },

  [theme.breakpoints.up("md")]: {
    fontSize: "23px",
    lineHeight: "32px",
    width: "170px",
  },

  [theme.breakpoints.up("lg")]: {
    width: "235px",
  },
}));
