//material ui
import React from "react";
import { useTheme } from "@mui/styles";
import { Typography, Box } from "@mui/material";

//json2mq
import { useLocation } from "react-router-dom";
import useTranslation from "../../../hooks/useTranslation";
import BorderLine from "../../../img/BorderLine.svg";

const CardBenefitsDesktop = () => {
  const useStyles = (theme) => ({
    container: {
      display: "flex",
      flexDirection: { xs: "row" },
      alignItems: { xs: "center" },
      justifyContent: "center",
      width: { xs: "100%" },
    },
    cardContent: {
      alignItems: "center",
      justifyContent: "center",
      height: "420px",
      flexDirection: "column",
      textAlign: "start",
      width: { md: "25vw", lg: "20vw" },
      mr: "20px",
      ml: "20px",
    },
    subtitle: {
      position: "relative",
      top: "-180px",
      pt: "1%",
      pl: { md: "45px", lg: "40px" },
      wordBreak: "break-word",
      fontFamily: theme.typography.Anaheim,
      color: theme.palette.primary.main,
    },
    title: {
      position: "relative",
      top: "-200px",
      pl: { md: "45px", lg: "40px" },
      color: theme.palette.primary.main,
      whiteSpace: "nowrap",
      height: "20px",
      fontSize: "110%",
    },
    img: {
      top: "-210px",
      position: "relative",
      left: "30%",
    },
    lineBorder: {
      position: "relative",
      right: "35%",
      top: "25px",
    },
  });

  const theme = useTheme();
  const classes = useStyles(theme);
  const { pathname } = useLocation();
  const componentName = "CardsBenefitsDesktop";
  const translation = useTranslation(pathname, componentName);
  const { cards } = translation;

  return (
    <Box sx={classes.container}>
      {cards.map((card, i) => (
        <Box sx={classes.cardContent} key={`${card.title}-${i}`}>
          {card.img && (
            <img src={card.img.src} alt={card.img.alt} style={classes.img} />
          )}
          <img src={BorderLine} alt="border" style={classes.lineBorder} />
          <Typography variant="h5" fontWeight={"bold"} sx={classes.title}>
            {card.title}
          </Typography>
          <Typography variant="h5" sx={classes.subtitle}>
            {card.subtitle}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default CardBenefitsDesktop;
