import { Box, SvgIcon } from "@mui/material";
import React from "react";
import TranslationText from "../components/Translations/TranslationText.jsx";

const useStyles = () => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  svg: {
    fill: "none",
  },
});

const CheckMarkIcon = () => {
  const classes = useStyles();

  return (
    <SvgIcon
      sx={classes.svg}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      stroke="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 11.5L9.5 13.5L14.5 8.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 2.33782C7.47087 1.48697 9.17856 1 11 1C16.5228 1 21 5.47715 21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 9.17856 1.48697 7.47087 2.33782 6"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

const CheckMarkText = ({ children, textSx, sx }) => {
  const classes = useStyles();

  return (
    <Box sx={{ ...classes.container, ...sx }}>
      <CheckMarkIcon />
      <TranslationText
        textSx={{
          normal: textSx?.normal,
          bold: textSx?.bold,
          italic: textSx?.italic,
        }}
      >
        {children}
      </TranslationText>
    </Box>
  );
};

export default CheckMarkText;
