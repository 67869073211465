import React from "react";
import { useTheme } from "@mui/styles";
import { useMediaQuery, Typography, Box } from "@mui/material";
import useTranslation from "../../hooks/useTranslation";
import { useLocation } from "react-router-dom";

const useStyles = (theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: { xs: "center", md: "center" },
    flexDirection: "column",
  },
  title: {
    maxWidth: { xs: "277px", md: "700px", lg: "1026px" },
    textAlign: { xs: "center", md: "inherit" },
    fontFamily: theme.typography.montserrat.regular,
    fontSize: { xs: "35px", md: "45px", lg: "55px", xl: "75px" },
    lineHeight: { xs: "43px", md: "57px", lg: "71px", xl: "85px" },
    mb: "26px",
  },
  span: { fontWeight: "bold" },
  subtitle: {
    maxWidth: { xs: "286px", md: "718px" },
    width: { md: "718px" },
    textAlign: { xs: "center", md: "center" },
    fontFamily: theme.typography.montserrat.regular,
    fontSize: { xs: "16px", md: "22px" },
    lineHeight: { xs: "26px", md: "39px" },
    height: "136px",
  },
});

const LandingTitle = () => {
  const isMobile = useMediaQuery("(max-width:899px)");
  const theme = useTheme();
  const classes = useStyles(theme, isMobile);
  const { pathname } = useLocation();
  const { title, description } = useTranslation(pathname, "LandingTitle");

  return (
    <Box sx={classes.container}>
      <Typography sx={classes.title}>
        <span style={classes.span}>{title}</span>
      </Typography>
      <Typography sx={classes.subtitle}>
        <span style={classes.span}>{description[0]}</span>
        {description[1]}
        <br></br>
        {description[2]}
      </Typography>
    </Box>
  );
};

export default LandingTitle;
