/** @format */

import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import legacyThemeConfig from "./legacy.jsx";
import { buttonStyles } from "./components/Button.jsx";
import { linkStyles } from "./components/Link.jsx";
import { typographyStyles } from "./components/Typography.jsx";

const AppTheme = ({ children }) => {
  const theme = createTheme({
    components: {
      ...typographyStyles,
      ...linkStyles,
      ...buttonStyles,
      MuiCard: {
        styleOverrides: {
          root: {
            "&.MuiPaper-root": {
              boxShadow: "5px 5px 20px rgba(79, 79, 79, 0.2)",
              borderRadius: "60px",
              padding: "2rem",
            },
          },
        },
      },
    },
    palette: {
      ...legacyThemeConfig.palette,
      primary: {
        main: "#001f55",
        hover: "rgba(28, 122, 217, 0.1)",
      },
      secondary: {
        main: "#FF012B",
        hover: "#CF0000",
        disabled: "rgba(255, 1, 43, 0.5)",
      },
      lightBlue: "#1C7AD9",
      lightGray: "#ABABAB99",
      background: {
        paper: "linear-gradient(90deg, #F4F4F4 0%, #FFFFFF 100%)",
        lightGray: "#F4F4F4",
      },
    },
    typography: {
      ...legacyThemeConfig.typography,
      fontSize: 16,
      fontFamily: "Montserrat, sans-serif",
      fontWeightBold: 700,
      fontWeightLight: 400,
      fontWeightMedium: 400,

      Anaheim: {
        fontFamily: "Anaheim, sans-serif",
      },

      Montserrat: {
        fontFamily: "Montserrat, sans-serif",
      },

      h1: {
        fontSize: 50,
        lineHeight: "60px",
      },
      h2: {
        fontSize: 40,
        lineHeight: "79px",
      },
      h3: {
        fontSize: 26,
        lineHeight: "36px",
      },
      h4: {
        fontSize: 22,
        lineHeight: "35px",
      },
      h5: {
        fontSize: 20,
        lineHeight: "25px",
      },
      h6: {
        fontSize: 18,
        lineHeight: "22px",
      },
    },
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default AppTheme;
