/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/jsx-filename-extension */
import globantLogo from "../img/companies/LogoGlobant.svg";
import ibmLogo from "../img/companies/LogoIBM.svg";
import jpMorganLogo from "../img/companies/LogoJP.svg";
import balloonLogo from "../img/companies/LogoBalloon.svg";
import dotBackgroundImg from "../img/BootcampText/dotBackgroundImg.svg";
import CupStar from "../img/CupStar.svg";
import UserHeart from "../img/UserHeartRounded.svg";
import Rocket from "../img/Rocket.svg";
import gitLogo from "../img/ToolsJS/1280px-Git-logo.svg";
import awsLogo from "../img/ToolsJS/awsLogo.svg";
import chaiLogo from "../img/ToolsJS/chaiLogo.svg";
import cypressLogo from "../img/ToolsJS/cypres.svg";
import dockerLogo from "../img/ToolsJS/DockerLogo.svg";
import sqlLogo from "../img/ToolsJS/sql-logo 2.svg";
import typescriptLogo from "../img/ToolsJS/typescriptLogo.svg";
import sequelize from "../img/ToolsJS/sequelizeLogo.svg";
import commandLineLogo from "../img/ToolsJS/commandLogo.svg";
import scrumLogo from "../img/ToolsJS/scrumLogo.svg";
import mochaLogo from "../img/ToolsJS/mochaLogo.svg";
import jsLogo from "../img/ToolsJS/JavaScript-logo 4.svg";
import jenkins from "../img/ToolsJS/jenkinsLogo.svg";
import jestLogo from "../img/ToolsJS/jest.svg";
import JWTlogo from "../img/ToolsJS/JWTLogo.svg";
import nodejsLogo from "../img/ToolsJS/nodeLogo.svg";
import reactLogo from "../img/ToolsJS/react.svg";
import reduxLogo from "../img/ToolsJS/redux.svg";
import sassLogo from "../img/ToolsJS/sassLogo.svg";
import expressLogo from "../img/ToolsJS/express.svg";
import mongodbLogo from "../img/ToolsJS/MongoDB-Logo.svg";
import nextLogo from "../img/ToolsJS/nextLogo.svg";
import githubLogo from "../img/ToolsJS/GitHub-Logo.svg";
import messageIcon from "../img/Icons/message.svg";
import bookingMonthsCalendarIcon from "../img/Bootcamp js calendario.svg";
import bookingClockIcon from "../img/Bootcamp js alarm.svg";
import bookingSheetIcon from "../img/Bootcamp js duration.svg";
import bookingComputerIcon from "../img/Bootcamp js modality.svg";
import bookingDaysCalendarIcon from "../img/Bootcamp js days.svg";
import bookingNextMonthJSBCMonthsCalendarIcon from "../img/Bootcamp js calendario 2.svg";
import bookingNextMonthJSBCClockIcon from "../img/Bootcamp js alarm 2.svg";
import bookingNextMonthJSBCSheetIcon from "../img/Bootcamp js duration 2.svg";
import bookingNextMonthJSBCComputerIcon from "../img/Bootcamp js modality 2.svg";
import bookingNextMonthJSBCDaysCalendarIcon from "../img/Bootcamp js days 2.svg";
import guillermoNeuberger from "../img/Home/Testimonials/Guillermo_Neuberger.webp";
import rosangelysReyes from "../img/Home/Testimonials/Rosangelys_Reyes.webp";
import paulaQuiriti from "../img/Home/Testimonials/Paula_Quiriti.webp";
import paulaQuiritiBC from "../img/Home/Testimonials/Paula_Quiriti_BC.webp";
import facundoMuller from "../img/Home/Testimonials/Facundo_Muller.webp";
import yesicaBarros from "../img/Home/Testimonials/Yesica_Barrios.webp";
import franciscoRaineri from "../img/Home/Testimonials/Francisco_Raineri.webp";
import julietaPuigPeralta from "../img/Home/Testimonials/Julieta_Puig.webp";
import ignacioRodriguez from "../img/Home/Testimonials/Ignacio_Rodriguez.webp";
import meliLogo from "../img/companies/LogoMeli.svg";
import maitenPeinetti from "../img/Home/Testimonials/Maiten_Peinetti.webp";
import claudioMazzoli from "../img/Home/Testimonials/Claudio_Mazzoli.webp";
import juliaSelma from "../img/Home/Testimonials/Julia_Selma.webp";
import modelitLogo from "../img/companies/LogoModelit.svg";
import cavuLogo from "../img/companies/LogoCavu.svg";
import checkboxLogo from "../img/companies/LogoCheckbox.svg";
import noCountryLogo from "../img/companies/LogoNoCountry.svg";
import bunkerLogo from "../img/companies/LogoBunker.svg";
import introBackgroundIcon from "../img/Course intro.svg";
import jsbcBackgroundIcon from "../img/Bootcamp-js.svg";
import courseReport from "../img/HomeReviews/CourseReport.webp";
import switchUp from "../img/HomeReviews/SwitchUp.webp";
import step1 from "../img/Home/Road/Step_one.svg";
import step1Number from "../img/Home/Road/Step_number_one.svg";
import step2 from "../img/Home/Road/Step_two.svg";
import step2Number from "../img/Home/Road/Step_number_two.svg";
import step3 from "../img/Home/Road/Step_three.svg";
import step3Number from "../img/Home/Road/Step_number_three.svg";
import jobPlacement1 from "../img/JobPlacement/MapLocation.svg";
import jobPlacement2 from "../img/JobPlacement/smile.svg";
import jobPlacement5 from "../img/JobPlacement/UsersGroup.svg";
import jobPlacement7 from "../img/JobPlacement/Case.svg";
import jobPlacement10 from "../img/JobPlacement/Share.svg";
import LogoP5Desktop from "../img/Navbar/LogoP5Desktop.svg";
import LogoP5Mobile from "../img/Navbar/LogoP5Mobile.svg";
import CNNLogo from "../img/Media-Logos/Logo CNN.svg";
import diarioPerfilLogo from "../img/LogoDiarioPerfil.png";
import forbesLogo from "../img/Media-Logos/Logo Forbes.svg";
import infobaeLogo from "../img/Media-Logos/Logo Infobae.svg";
import infoTechnologyLogo from "../img/Media-Logos/Logo InfoTechnology.svg";
import laNacionLogo from "../img/Media-Logos/Logo La Nacion.svg";
import applyIntroLogo from "../img/Apply main intro mobile.svg";
import applyJSLogo from "../img/Apply main js mobile.svg";
import successIcon from "../img/EmailIlustration.png";
import errorIcon from "../img/ErrorIlustration.png";
import errorBackground from "../img/ErrorBackground.png";
import HtmlLogo from "../img/HTMLLogo.svg";
import CssLogo from "../img/CSSLogo.svg";
import NetlifyLogo from "../img/netlify.svg";
import BootstrapLogo from "../img/bootstrapLogo.svg";
import JsonLogo from "../img/json.svg";
import VsCodeLogo from "../img/VSCodeLogo.svg";
import introCertificate from "../img/intro-certificate.png";
import dotBackgroundChallengeImg from "../img/HomeDesktop01.png";
import javascriptIcon from "../img/IlustrationJavascript.png";
import javascriptCard from "../img/JavascriptBcCardDesktop.png";
import gridBackground from "../img/Miscelanea01LandingBc.png";
import founderPicture from "../img/FounderImage.png";
import challengeSuccess from "../img/Challenge/success.svg";
import challengeFailure from "../img/Challenge/failure.svg";
import challengeError from "../img/Challenge/error.svg";
import notMobileAllowed from "../img/Challenge/notMobileAllowed.svg";
import heroSantuchoImg from "../img/Hero/santiagoSantucho.webp";
import heroCodeImg from "../img/Hero/code.svg";
import heroDesktopBackgroundImg from "../img/Hero/desktopBackground.svg";
import heroMobileBackgroundImg from "../img/Hero/mobileBackground.svg";
import jsbcHeroMainImg from "../img/Hero/jsbcHeroMainImg.webp";
import noVacancyIcon from "../img/Dates/NoVacancy.svg";
import lastVacancyIcon from "../img/Dates/LastVacancy.svg";
import soonIcon from "../img/Dates/Soon.svg";
import exclamationIcon from "../img/Dates/Exclamation.svg";
import introHeroMainImg from "../img/Hero/introHeroMainImg.webp";
import RocketIcon from "../img/Icons/rocket.svg";
import aboutUsHeroMainImg from "../img/Hero/aboutUsHeroMainImg.webp";
import faqsHeroDesktopBackgroundImg from "../img/Hero/faqsHeroDesktopBackgroundImg.svg";

const en = {
  Global: {
    Navbar: {
      applyButton: {
        text: "APLICAR",
        icon: RocketIcon,
        alt: "Icono de cohete",
        href: "/aplica",
      },
      logo: {
        src: { mobile: LogoP5Mobile, desktop: LogoP5Desktop },
        alt: "Logo Plataforma 5",
      },
      menu: [
        {
          label: "Carrera",
          options: [
            { label: "Curso Introductorio", href: "/intro" },
            {
              label: "Javascript Bootcamp",
              options: [
                {
                  label: "Programa",
                  href: "/bootcamp/javascript#program",
                },
                {
                  label: "Precio",
                  href: "/bootcamp/javascript#price",
                },
              ],
              href: "/bootcamp/javascript",
            },
          ],
        },
        {
          label: "Nosotros",
          href: "/nosotros",
        },
        {
          label: "Preguntas frecuentes",
          href: "/faqs",
        },
      ],
      mobileMenu: [
        { label: "Curso Introductorio", href: "/intro" },
        {
          label: "Javascript Bootcamp",
          options: [
            { label: "Programa", href: "/bootcamp/javascript#program" },
            { label: "Precio", href: "/bootcamp/javascript#price" },
          ],
          href: "/bootcamp/javascript",
        },
        {
          label: "Nosotros",
          href: "/nosotros",
        },
        {
          label: "Preguntas frecuentes",
          href: "/faqs",
        },
      ],
    },
  },
  "/bootcamp/javascript": {
    // --------------------------------------------------------------- //
    AccordionProgram: {
      title: "Convertite en **Full Stack Developer**",
      subtitle: "Programa",
      description: {
        desktop:
          "Nuestro Bootcamp te convierte en real-job-ready en 29 semanas con una propuesta 100% enfocada en las necesidades del mundo IT",
        mobile: "Nuestro Bootcamp te convierte en real-job-ready en 29 semanas",
      },
      link: {
        href: "https://drive.google.com/file/d/1tUcbx0bJ9WBskEeNrUo1L8jEthQ11HVr/view?usp=drive_link",
        text: "VER PROGRAMA",
      },
    },
    // --------------------------------------------------------------- //
    AccordionBox: {
      tabs: [
        {
          title: "**MÓDULO 1 | Full-Time**",
          subtitle: "Semanas 1 - 8",
          description: {
            desktop:
              "Durante la Junior Phase trabajarás con Video Lectures, Workshops, Pair Programming y Q&A en vivo en estas tecnologías:",
            mobile:
              "Durante la Junior Phase **perfeccionarás tu base técnica** con Video Lectures, Workshops, Pair Programming y Code Reviews",
          },
          columns: [
            {
              title: "**FUNDAMENTOS**",
              items: [
                "Git",
                "Command Line",
                "JavaScript Vanilla",
                "Algoritmos",
              ],
            },
            {
              title: "**BACKEND**",
              items: [
                "Node.js",
                "HTTP",
                "Express.js",
                "SQL y PostgreSQL",
                "Sequelize",
                "Testing y JWT",
              ],
            },
            {
              title: "**FRONTEND**",
              items: ["CSS", "Axios y Fetch", "DOM", "React", "Redux"],
            },
          ],
        },
        {
          title: "**MÓDULO 2 | Full-Time**",
          subtitle: "Semanas 9 - 14",
          description: {
            desktop:
              "Durante la Senior Phase tendrás desafíos prácticos de mayor complejidad para mejorar tus skills profesionales como developer:",
            mobile:
              "Durante la Senior Phase, tendrás **desafíos prácticos de mayor complejidad** para mejorar tus skills profesionales como developer.",
          },
          columns: [
            {
              title: "**DEVOPS**",
              items: ["Docker", "MongoDB", "Deploy"],
            },
            {
              title: "**SOFT SKILLS**",
              items: ["Scrum", "Talking Circle", "Tech Talks"],
            },
            {
              title: "**PROJECTS**",
              items: [
                "Solo Practice (Individual)",
                "Práctica Profesional (Grupal)",
                "Challenge Técnico Final",
              ],
            },
          ],
        },
        {
          title: "**MÓDULO 3 | Full-Time**",
          subtitle: "Semanas 15 - 16",
          description: {
            desktop:
              "Contarás con diferentes talleres y recursos, brindados por nuestro staff de Job Placement, para potenciar tu perfil:",
            mobile:
              "Contarás con diferentes talleres y recursos, brindados por nuestro staff de Job Placement, para **potenciar tu perfil.**",
          },
          columns: [
            {
              title: "**CAREER COACH**",
              items: [
                "Optimización de CV",
                "Perfil en LinkedIn",
                "Simulacros de entrevista",
                "Estrategias de búsqueda laboral",
              ],
            },
            {
              title: "**EMPLEABILIDAD**",
              items: [
                "Pasantías profesionales",
                "Workshops con expertos en RRHH",
              ],
            },
            {
              title: "**RECRUITING**",
              items: ["Meetups con empresas", "Bolsa de trabajo exclusiva"],
            },
          ],
        },
        {
          title: "**MÓDULO 4 | Part-Time**",
          subtitle: "Semanas 17 - 29",
          description: {
            desktop:
              "Trabajarás con un equipo en un caso real de una empresa IT con objetivos y sprints marcados. Además tendrás que presentar entregables semanales:",
            mobile:
              "Trabajarás con un equipo en un **caso de una empresa real** con objetivos claros, sprints y entregables semanales.",
          },
          columns: [
            {
              title: "**UPSKILLING**",
              items: [
                "AWS",
                "Jenkins",
                "Cypress",
                "REST Best Practices",
                "Next.js",
                "Seguridad (CORS, JWT y XSS)",
                "Testing",
                "TypeScript",
              ],
            },
            {
              title: "**CODE LIKE A PRO**",
              items: [
                "Mentoreo con devs senior",
                "Demos y retros semanales",
                "Code review",
                "Práctica guiada",
              ],
            },
            {
              title: "**PROJECT**",
              items: ["IT Business Case"],
            },
          ],
        },
      ],
    },
    // --------------------------------------------------------------- //
    Hero: {
      title: ["**JavaScript**", "**Bootcamp**"],
      subtitle: {
        desktop:
          "Domina una de las tecnologías centrales de la web y **conviértete en Full Stack Developer**",

        mobile: "**Convertite en Full Stack Developer**",
      },
      description: [],
      checkList: {
        desktop: [],
        mobile: [],
      },
      mainImg: {
        src: jsbcHeroMainImg,
        alt: "programmer",
      },
      secondaryImg: null,
      backgroundImg: {
        desktop: {
          src: heroDesktopBackgroundImg,
          alt: "code background",
        },
        mobile: {
          src: heroMobileBackgroundImg,
          alt: "code background",
        },
      },
      button: {
        title: "Aplicar",
        to: "/aplica",
      },
    },
    // --------------------------------------------------------------- //
    BootcampText: {
      dotBackgroundImg: { src: dotBackgroundImg, alt: "Fondo de puntos" },
      content: {
        desktop:
          "Potencia tu carrera aprendiendo el lenguaje más utilizado por las empresas tecnológicas de todo el mundo",
        mobile: "Potencia tu carrera aprendiendo el lenguaje más usado en IT",
      },
    },
    // --------------------------------------------------------------- //
    BenefitsTitle: {
      title: "Razones para elegirnos",
      subtitle: "¿Por qué P5?",
    },
    // --------------------------------------------------------------- //
    CardsBenefitsMobile: {
      cards: [
        {
          img: { src: UserHeart, alt: "Computadora" },
          title: "FOCO EN LAS PERSONAS",
          subtitle:
            "Acompañamiento personalizado en todo tu proceso de formación",
        },
        {
          img: { src: CupStar, alt: "Copa" },
          title: "NIVEL TÉCNICO",
          subtitle:
            "Diferénciate con un nivel superior: más de 1.000 hs de codeo en las últimas tecnologías",
        },
        {
          img: { src: Rocket, alt: "Versátil" },
          title: "INSERCIÓN LABORAL",
          subtitle:
            "Potenciamos tu empleabilidad con talleres de CV, simulacros de entrevistas, entre otros",
        },
      ],
    },
    // --------------------------------------------------------------- //
    CardsBenefitsDesktop: {
      cards: [
        {
          img: { src: UserHeart, alt: "user" },
          title: "FOCO EN LAS PERSONAS",
          subtitle:
            "Te acompañamos de manera personalizada en las distintas etapas de formación y en diferentes niveles (técnico, profesional y personal)",
        },
        {
          img: { src: CupStar, alt: "copa" },
          title: "NIVEL TÉCNICO",
          subtitle:
            "Diferenciate con un nivel técnico superior: más de 1000 horas de formación en las últimas tecnologías y proyectos propuestos por empresas reales",
        },
        {
          img: { src: Rocket, alt: "cohete" },
          title: "INSERCIÓN LABORAL",
          subtitle:
            "Potenciamos tu empleabilidad con talleres de armado de CV, meetups con empresas, simulacros de entrevistas y acompañamiento profesional",
        },
      ],
    },
    // --------------------------------------------------------------- //
    UnitsCarrousel: {
      title: "CONOCE MÁS",
      subtitle: "Programa",
      button: "Descarga el programa",
      units: [
        {
          unit: "PREP COURSE",
          stage: "",
          period: "80 horas",
          title: "JavaScript Prep",
          lessons: [
            { topic: "Command Line" },
            { topic: "Git" },
            { topic: "JavaScript en profundidad" },
          ],
        },
        {
          unit: "JUNIOR PHASE",
          stage: "",
          period: "",
          title: "Estructuras de datos",
          lessons: [
            { topic: "Queue (ADT)" },
            { topic: "Linked List (DS)" },
            { topic: "Binary Trees" },
            { topic: "Hash Tables" },
          ],
        },
        {
          unit: "JUNIOR PHASE",
          stage: "",
          period: "",
          title: "Selectores",
          lessons: [
            { topic: "Document Object Model (DOM)" },
            { topic: "DOM Tree" },
            { topic: "Selectores" },
            { topic: "Serialización" },
            { topic: "Tests" },
          ],
        },
        {
          unit: "JUNIOR PHASE",
          stage: "",
          period: "",
          title: "Sorting",
          lessons: [
            { topic: "Algoritmos de Ordenamiento" },
            { topic: "Bubble Sort" },
            { topic: "Merge Sort" },
            { topic: "Benchmarking" },
            { topic: "Insertion Sort y Heap Sort" },
          ],
        },
        {
          unit: "JUNIOR PHASE",
          stage: "",
          period: "",
          title: "Node.js",
          lessons: [
            { topic: "Bash" },
            { topic: "Built-in globals" },
            { topic: "Built-in modules" },
            { topic: "Node Package Manager (NPM)" },
            { topic: "Web Servers" },
            { topic: "Express" },
            { topic: "Auth" },
            { topic: "Axios" },
          ],
        },
        {
          unit: "JUNIOR PHASE",
          stage: "",
          period: "",
          title: "SQL",
          lessons: [
            { topic: "Base de Datos" },
            { topic: "Sentencias" },
            { topic: "QueriesSQL" },
            { topic: "Schema Design" },
            { topic: "PostgreSQL" },
          ],
        },
        {
          unit: "JUNIOR PHASE",
          stage: "",
          period: "",
          title: "Promesas (avanzado)",
          lessons: [
            { topic: "Estados" },
            { topic: "Vanilla Callbacks" },
            { topic: "Librería Mocha" },
            { topic: "Librería Bluebird" },
            { topic: "Métodos" },
          ],
        },
        {
          unit: "JUNIOR PHASE",
          stage: "",
          period: "",
          title: "HTML y CSS (avanzado)",
          lessons: [
            {
              topic: "Repaso avanzado de HTML",
            },
            {
              topic: "Flexbox",
            },
            {
              topic: "Sass",
            },
            { topic: "Media Query" },
          ],
        },
        {
          unit: "JUNIOR PHASE",
          stage: "",
          period: "",
          title: "React",
          lessons: [
            { topic: "Sintaxis JSX" },
            { topic: "React App" },
            { topic: "Hooks" },
            { topic: "React Router" },
            { topic: "Context" },
            { topic: "Redux" },
          ],
        },
        {
          unit: "JUNIOR PHASE",
          stage: "",
          period: "",
          title: "Developer Toolbox",
          lessons: [
            { topic: "Git / GitHub" },
            { topic: "Docker" },
            { topic: "Metodologías ágiles (Scrum)" },
            { topic: "Trello" },
            { topic: "R.E.A.C.T.O." },
          ],
        },
        {
          unit: "SENIOR PHASE",
          stage: "",
          period: "1 semana",
          title: "Solo Week",
          lessons: [
            { topic: "Desarrollo Full Stack" },
            { topic: "Manejo avanzado de API y package.json" },
            { topic: "Metodologías ágiles (SCRUM)" },
            { topic: "Implementación de Features - método MoSCoW" },
          ],
        },
        {
          unit: "SENIOR PHASE",
          stage: "",
          period: "2 semanas",
          title: "Proyecto Grupal",
          lessons: [
            { topic: "Desarrollo Full Stack" },
            { topic: "Metodologías ágiles (SCRUM)" },
            { topic: "Implementación de Features - método MoSCoW" },
            { topic: "Trabajo por sprints" },
            { topic: "Code review con jurado" },
            { topic: "Gestión de Proyectos" },
          ],
        },
        {
          unit: "SENIOR PHASE",
          stage: "",
          period: "4 semanas",
          title: "Práctica Profesional",
          lessons: [
            { topic: "Desarrollo Full Stack" },
            { topic: "Metodologías ágiles (SCRUM)" },
            { topic: "Implementación de Features - método MoSCoW" },
            { topic: "Trabajo por sprints" },
            { topic: "Gestión de Proyectos" },
            { topic: "Proyecto con empresas reales" },
            {
              topic: "Presentación de avances semanales con el cliente (demos)",
            },
          ],
        },
        {
          unit: "SENIOR PHASE",
          stage: "",
          period: "",
          title: "Experiencias",
          lessons: [
            { topic: "Pair-programming" },
            { topic: "Checkpoints" },
            { topic: "Tech Talks" },
            { topic: "Talking Circle" },
            { topic: "Encuentros con graduados y expertos" },
          ],
        },
        {
          unit: "POST BOOTCAMP",
          stage: "",
          period: "2 semanas",
          title: "Job Placement",
          lessons: [
            { topic: "Estrategia de búsqueda laboral" },
            { topic: "Preparación de CV + perfil en LinkedIn" },
            { topic: "Simulación de entrevistas" },
            { topic: "MeetUps con empresas IT" },
          ],
        },
      ],
    },
    // --------------------------------------------------------------- //
    Tools: {
      // -> TODO Sumar imágenes y que se vean dos filas con máximo 3 por fila idealmente
      // FIXME -> arreglar tamaños de las imágenes
      title: "Herramientas",
      subtitle: "Aprenderás a usar",
      logosImg: [
        { src: jsLogo, alt: "Logo JavaScript", title: "JavaScript" },
        { src: mongodbLogo, alt: "Logo MongoDB", title: "MongoDB" },
        { src: nodejsLogo, alt: "Logo Node.js", title: "Node.js" },
        { src: reactLogo, alt: "Logo React", title: "React" },
        { src: expressLogo, alt: "Logo Express", title: "Express" },
        { src: reduxLogo, alt: "Logo Redux", title: "Redux" },
        { src: sequelize, alt: "Logo sequelize", title: "Sequelize" },
        { src: jestLogo, alt: "Logo jest", title: "Jest" },
        { src: sqlLogo, alt: "Logo Sql", title: "SQL" },
        { src: mochaLogo, alt: "Logo mocha", title: "Mocha" },
        { src: chaiLogo, alt: "Logo Chai", title: "Chai" },
        {
          src: awsLogo,
          alt: "Logo AWS",
          title: `Amazon
                   Web
                Services`,
        },
        { src: gitLogo, alt: "Logo Git", title: "Git" },
        { src: JWTlogo, alt: "Logo jwt", title: "JWT" },
        { src: githubLogo, alt: "Logo GitHub", title: "GitHub" },
        { src: jenkins, alt: "Logo jenkins", title: "Jenkins" },
        {
          src: commandLineLogo,
          alt: "Logo Command Line",
          title: "Command  Line",
        },
        { src: cypressLogo, alt: "Logo cypress", title: "Cypress" },
        { src: sassLogo, alt: "Logo sass", title: "Sass" },
        { src: dockerLogo, alt: "Logo docker", title: "Docker" },
        { src: typescriptLogo, alt: "Logo typescript", title: "TypeScript" },
        { src: nextLogo, alt: "Logo next", title: "Next.js" },
        { src: scrumLogo, alt: "Logo Scrum", title: "Scrum" },
      ],
    },
    // --------------------------------------------------------------- //
    Funding: {
      title: "**Precios**",
      subtitle: "Nuestras opciones de financiación:",
      fundingCards: [
        {
          subtitle: "**Precio especial**",
          title: "**CONTADO**",
          description: "Pagá de forma anticipada y aprovechá el descuento. 😎",
          pricing: [
            ["**Total: USD 2.900**", "**4.500**"],
            ["**Ahorrás: USD 1.600 🔥🔥**"],
          ],
          finePrint: "Aprovechá esta **promo** por **tiempo limitado.**",
          to: "/aplica/javascript/fechas",
        },
        {
          subtitle: "**85% financiado**",
          title: "**DIFERIDO**",
          description: "Pagá una **seña** y el resto al conseguir trabajo.",
          pricing: [
            ["**Total: USD 3.900**", "**4.500**"],
            ["**Ahorrás: USD 600 🔥**"],
          ],
          finePrint: "Podés pagar en **hasta 24 cuotas mensuales.**",
          to: "/aplica/javascript/fechas",
        },
        {
          subtitle: "**100% financiado**",
          title: "**FULL DIFERIDO**",
          description: "Empezá a pagar **al conseguir trabajo.**",
          clarification: "**Cupos limitados**",
          pricing: [
            ["**Total: USD 4.500**"],
            ["**Inversión inicial: USD 0 🤑**"],
          ],
          finePrint: "Podés pagar en **hasta 24 cuotas mensuales.**",
          to: "/aplica/javascript/fechas",
        },
      ],
    },
    // --------------------------------------------------------------- //
    Schedule: {
      title: "AGENDA",
      subtitle: ["Próximas ", "fechas"],
      errorLoadingCourses:
        "Oops! parece que hubo un error y no pudimos cargar los cursos... intenta nuevamente en unos segundos",
    },
    // --------------------------------------------------------------- //
    BookingMobileNextMonth: {
      typeText: "ONLINE EN VIVO",
      inscriptionTimeLimitText: "Cierre de inscripción:",
      lastPositionsText: "ÚLTIMAS VACANTES",
      noPositionsText: "NO HAY VACANTES",
      applyButtonContent: "Aplicar",
      applyButtonContentNotAvailable: "Proximamente",
      contents: [
        {
          img: {
            src: bookingNextMonthJSBCMonthsCalendarIcon,
            alt: "Calendario de mes",
          },
          title: "Fechas:",
        },
        {
          img: { src: bookingNextMonthJSBCClockIcon, alt: "Reloj" },
          title: "Horario:",
        },
        {
          img: { src: bookingNextMonthJSBCSheetIcon, alt: "Hoja" },
          title: "Duración:",
        },
        {
          img: { src: bookingNextMonthJSBCComputerIcon, alt: "Computadora" },
          title: "Modalidad:",
        },
        {
          img: {
            src: bookingNextMonthJSBCDaysCalendarIcon,
            alt: "Calendario de días",
          },
          title: "Días:",
        },
      ],
    },
    // --------------------------------------------------------------- //
    BookingMobile: {
      typeText: "ONLINE EN VIVO",
      inscriptionTimeLimitText: "Cierre de inscripción:",
      lastPositionsText: "ÚLTIMAS VACANTES",
      noPositionsText: "NO HAY VACANTES",
      applyButtonContent: "Aplicar",
      applyButtonContentNotAvailable: "Proximamente",
      contents: [
        {
          img: { src: bookingMonthsCalendarIcon, alt: "Calendario de mes" },
          title: "Fechas:",
        },
        { img: { src: bookingClockIcon, alt: "Reloj" }, title: "Horario:" },
        { img: { src: bookingSheetIcon, alt: "Hoja" }, title: "Duración:" },
        {
          img: { src: bookingComputerIcon, alt: "Computadora" },
          title: "Modalidad:",
        },
        {
          img: { src: bookingDaysCalendarIcon, alt: "Calendario de días" },
          title: "Días:",
        },
      ],
    },
    // --------------------------------------------------------------- //
    // --------------------------------------------------------------- //
    BookingDesktopNextMonth: {
      typeText: " ONLINE - EN VIVO",
      inscriptionTimeLimitText: "Cierre de inscripción: ",
      lastPositionsText: "ÚLTIMAS VACANTES",
      noPositionsText: "NO HAY VACANTES",
      applyButtonContent: "Aplicar",
      applyButtonContentNotAvailable: "Proximamente",
      contents: [
        {
          img: {
            src: bookingNextMonthJSBCMonthsCalendarIcon,
            alt: "Calendario de mes",
          },
          title: "Fechas:",
        },
        {
          img: { src: bookingNextMonthJSBCClockIcon, alt: "Reloj" },
          title: "Horario:",
        },
        {
          img: { src: bookingNextMonthJSBCSheetIcon, alt: "Hoja" },
          title: "Duración:",
        },
        {
          img: { src: bookingNextMonthJSBCComputerIcon, alt: "Computadora" },
          title: "Modalidad:",
        },
        {
          img: {
            src: bookingNextMonthJSBCDaysCalendarIcon,
            alt: "Calendario de días",
          },
          title: "Días:",
        },
      ],
    },
    // --------------------------------------------------------------- //
    BookingDesktop: {
      typeText: " ONLINE - EN VIVO",
      inscriptionTimeLimitText: "Cierre de inscripción: ",
      lastPositionsText: "ÚLTIMAS VACANTES",
      noPositionsText: "NO HAY VACANTES",
      applyButtonContent: "Aplicar",
      applyButtonContentNotAvailable: "Proximamente",
      contents: [
        {
          img: { src: bookingMonthsCalendarIcon, alt: "Calendario de mes" },
          title: "Fechas:",
        },
        { img: { src: bookingClockIcon, alt: "Reloj" }, title: "Horario:" },
        { img: { src: bookingSheetIcon, alt: "Hoja" }, title: "Duración:" },
        {
          img: { src: bookingComputerIcon, alt: "Computadora" },
          title: "Modalidad:",
        },
        {
          img: { src: bookingDaysCalendarIcon, alt: "Calendario de días" },
          title: "Días:",
        },
      ],
    },
    Dates: {
      error: {
        icon: messageIcon,
        text: [
          "Para conocer las proximas ediciones,",
          "escríbenos a",
          "admisiones@plataforma5.la",
        ],
      },
      title: "Javascript Bootcamp",
      subtitle: "Agendá",
      course: {
        modality: {
          online: "ONLINE",
          person: "PRESENCIAL",
        },
        states: {
          available: {
            text: "ULTIMAS VACANTES",
            icon: lastVacancyIcon,
          },
          notAvailable: {
            text: "VACANTES AGOTADAS",
            icon: noVacancyIcon,
          },
          soon: {
            text: "Proximamente",
            icon: soonIcon,
          },
        },
        events: [
          { text: "Cierre de inscripción", mobile: true },
          "Fechas",
          { text: "Duración", mobile: true },
        ],
        modules: [
          {
            title: "Módulos 1,2 y 3 (Full Time): ",
            items: [
              {
                text: "**+800 hs de formación**",
              },
            ],
          },
          {
            title: "Módulo 4 (Part Time): ",
            items: [{ text: "**+200hs de formación**" }],
          },
        ],
        note: {
          desktop: ["Podés rendir", "el examen de admisión hasta el "],
          mobile: ["Podés rendir", "el challenge hasta el "],
          icon: exclamationIcon,
        },
        applyButtonText: "APLICAR",
      },
    },
    // --------------------------------------------------------------- //
    TypicalDay: {
      title: "Un día típico",
      firstSubtitle:
        "Estos serán tus horarios durante el Módulo 1 del Bootcamp (Junior Phase).",
      secondSubtitle: "Preparate para vivir este entrenamiento intensivo.",
      desktopActivities: [
        {
          hour: "9:30 ARG",
          name: "VIDEO LECTURES 🍿",
          description:
            "En las clases teóricas, los profesores explican nuevos conceptos con los tendrás que trabajar. Estas son en formato video.",
        },
        {
          hour: "11:00 ARG",
          name: "WORKSHOPS 🧠",
          description:
            "Durante el Workshop resolverás un desafío práctico haciendo pair-programming con un compañero en vivo. Contarás con un espacio de consultas a cargo del Staff P5.",
        },
        {
          hour: "13:00 ARG",
          name: "ALMUERZO Y RELAX 🍲",
        },
        {
          hour: "14:00 ARG",
          name: "WORKSHOPS 🧠 (CONTINUACIÓN)",
          description:
            "Continúas el Workshop con tu pair programming hasta la Code Review.",
        },
        {
          hour: "17:30 ARG",
          name: "CODE REVIEW 🧩",
          description:
            "Si bien hay muchas maneras de resolver cada desafío, al final de cada Workshop tendrás acceso a la solución propuesta por nuestro Dev Team. Para que puedas volver a verla, las Code Reviews están grabadas.",
        },
        {
          hour: "18:30 ARG",
          name: "Q&A: SESIÓN DE CONSULTAS ❓",
          description:
            "Luego de la Code Review, contás con una sesión grupal de Q&A en vivo liderada por los asistentes para despejar tus dudas.",
        },
      ],
      mobileActivities: [
        {
          hour: "9:30 ARG",
          name: "VIDEO LECTURES 🍿",
          description:
            "En las clases teóricas los profesores explican nuevos conceptos.",
        },
        {
          hour: "11:00 ARG",
          name: "WORKSHOPS 🧠",
          description:
            "Resolverás un desafío práctico haciendo pair-programming con un compañero en vivo.",
        },
        {
          hour: "13:00 ARG",
          name: "ALMUERZO Y RELAX 🍲",
        },
        {
          hour: "14:00 ARG",
          name: "WORKSHOPS 🧠",
          description:
            "Continúas el Workshop con tu pair programming hasta la Code Review.",
        },
        {
          hour: "17:30 ARG",
          name: "CODE REVIEW 🧩",
          description:
            "Al final de cada Workshop, tendrás acceso a la solución propuesta por nuestro Dev Team en formato video",
        },
        {
          hour: "18:30 ARG",
          name: "Q&A: CONSULTAS ❓",
          description:
            "Sesión grupal de consultas donde nuestro staff te ayudará a despejar tus dudas",
        },
      ],
      desktopNote:
        "* Este cronograma full-time tendrá algunas modificaciones en los módulos 2 y 3. A partir del módulo 4, las actividades pasan a ser part-time.",
      mobileNote: "*Ejemplo de cronograma para los módulos Full Time.",
    },
    // --------------------------------------------------------------- //
    Testimonials: {
      title: ["Conocé la experiencia ", "de nuestros graduados"],
      section: "Testimonios",
      testimonials: [
        {
          name: "Julieta Puig Peralta",
          imageSrc: julietaPuigPeralta,
          text: "P5 siempre va a ser un lugar que me ayudó a redefinir mis límites, a darme cuenta de mi potencial y conseguir oportunidades laborales muy valiosas. El Bootcamp es una experiencia que recomiendo para cualquier curioso de la programación. ¡La calidez humana y el profesionalismo nunca dejaron de sorprenderme!",
          companySrc: jpMorganLogo,
        },
        {
          name: "Paula Quiriti",
          imageSrc: paulaQuiritiBC,
          text: "Hacer el Bootcamp y comenzar a trabajar en programación significó una mejora real en mi calidad de vida. Este bello rubro me dio la posibilidad de llegar mejor parados a fin de mes y poder encarar proyectos con mi pareja que siempre terminábamos postergando. Estoy muy agradecida con P5 por haber sido facilitadores de esta nueva etapa.",
          companySrc: meliLogo,
        },
        {
          name: "Francisco Alvarez Raineri",
          imageSrc: franciscoRaineri,
          text: "El Bootcamp de P5 es una experiencia única que te puede cambiar la vida. El equipo humano es excelente y hacen buen énfasis en las habilidades tanto duras como blandas. Si querés aprender programación web en poco tiempo, es una excelente oportunidad. Pero no es fácil, requiere muchísimo esfuerzo y dedicación.",
          companySrc: meliLogo,
        },
        {
          name: "Rosangelys Reyes",
          imageSrc: rosangelysReyes,
          text: "El Bootcamp en Plataforma 5 fue una de mis mejores experiencias. Si alguien quiere hacer un salto en su carrera y está comprometido con eso, se lo recomiendo al 100%. Requiere mucha dedicación, pero es muy llevadero, no te aburrís y te sentís acompañado. Además, como es un mundo gigante, hay muchísimas áreas para desarrollarse laboralmente.",
          companySrc: modelitLogo,
        },
        {
          name: "Facundo Müller",
          imageSrc: facundoMuller,
          text: "Hermoso lugar para aprender tanto los conocimientos técnicos como las habilidades blandas que vas a necesitar en tu día a día como Dev, de la mano de un equipo de grandes personas que están presentes durante todo el camino y te brindan todas las herramientas para que puedas incorporar todo el conocimiento y egresar listo para comenzar a trabajar. ¡Totalmente recomendado!",
          companySrc: bunkerLogo,
        },
        {
          name: "Yesica Barros",
          imageSrc: yesicaBarros,
          text: "Para mí fue una experiencia maravillosa, fue tan lindo pasar de no saber programar y gracias a todo el proceso de aprendizaje y el apoyo que recibí de P5, lograr finalizar el Bootcamp y en una semana conseguir mi primer trabajo en IT. P5 realmente me potenció y me dio las herramientas para afrontar y superar cada desafío presente en este mercado IT tan demandante y cambiante.",
          companySrc: cavuLogo,
        },
        {
          name: "Ignacio Rodríguez",
          imageSrc: ignacioRodriguez,
          text: "¡Hermoso haber transitado este camino junto a tantas personas que me ayudaron, enseñaron y acompañaron para que hoy pueda ser Full-Stack Developer! Gracias por la calidad humana y técnica por supuesto al staff de P5 y a todos mis compañeros por generar un entorno de trabajo muy pero muy copado.",
          companySrc: balloonLogo,
        },
        {
          name: "Maiten Peinetti",
          imageSrc: maitenPeinetti,
          text: "Feliz de poder compartirles este logro. 🎉 El Bootcamp fue una experiencia hermosa, llena de esfuerzo y desafíos. Agradezco de corazón a todas las personas que me acompañaron, desde el staff de Plataforma 5 como todos mis compañeros. ¡Fue un placer transitar este camino con ustedes!",
          companySrc: checkboxLogo,
        },
        {
          name: "Claudio Mazzoli",
          imageSrc: claudioMazzoli,
          text: "Estoy muy feliz y orgulloso de haber aprendido nuevas tecnologías en tan poco tiempo y de ver realizado este gran paso hacia nuevos rumbos en el mundo IT, en los cuales el aprendizaje es un loop infinito. Agradezco con todo el ❤️ a mi familia por apoyarme, a toda la gente de P5 y en especial a mis compañeros, esperando cruzarlos en algún proyecto.",
          companySrc: noCountryLogo,
        },
      ],
    },
    WantToApplyButton: {
      title: "Aplicar",
    },
    LaborInsertion: {
      title: ["Potencia", " tu inserción laboral"],
      subtitle: "Job Placement",
      description:
        "Durante el bootcamp, nuestro equipo de Job Placement te ayuda ",
      descriptionFollowUp: "a mejorar tu empleabilidad:",
      jobPlacement: [
        {
          img: jobPlacement1,
          text: "Diseño de perfil profesional y estrategia de búsqueda laboral",
          marginBottom: "75px",
        },
        {
          img: jobPlacement2,
          text: "Armado y optimización de CV y perfil de LinkedIn",
          marginBottom: "75px",
        },
        {
          img: jobPlacement5,
          text: "Workshops con expertos en Recursos Humanos y empresas aliadas",
          marginBottom: "75px",
        },
        {
          img: jobPlacement7,
          text: "Bolsa de trabajo exclusiva",
          marginBottom: "75px",
        },
        {
          img: jobPlacement10,
          text: "Red de contactos con Reclutadores + graduados de P5",
          marginBottom: { xs: "31px", md: "75px" },
        },
      ],
    },
  },
  "/": {
    Hero: {
      title: ["Aprendé a **programar**.", "**Cambiá** tu vida."],
      subtitle: {
        desktop: "**Conviértete en Full Stack Developer en 7 meses**",
        mobile: "**Conviértete en Full Stack Developer en 7 meses**",
      },
      description: [],
      checkList: {
        desktop: [
          "Diferenciate con un nivel técnico superior",
          "Trabaja con proyectos reales de empresas",
          "Potencia tu empleabilidad con nosotros",
        ],
        mobile: [],
      },
      mainImg: {
        src: heroSantuchoImg,
        alt: "programmer",
      },
      secondaryImg: {
        src: heroCodeImg,
        alt: "code",
      },
      backgroundImg: {
        desktop: {
          src: heroDesktopBackgroundImg,
          alt: "code background",
        },
        mobile: {
          src: heroMobileBackgroundImg,
          alt: "code background",
        },
      },
      button: {
        title: "Aplicar",
        to: "/aplica",
      },
    },
    ApplyNowButton: {
      title: "Aplicar ahora",
    },
    Home: {
      ratings: [
        {
          mainText: "COURSE REPORT",
          rankingText: "Ranking promedio",
          ranking: "5/5",
          img: courseReport,
          mb: "28px",
        },
        {
          mainText: "SWITCHUP",
          rankingText: "Ranking promedio",
          ranking: "4.89/5",
          img: switchUp,
        },
      ],
    },
    P5Commitment: {
      title: "Sumate a nuestros graduados",
      subtitle: "que trabajan en:",
    },
    Road: {
      title: "Tu camino Dev",
      subtitle: ["Te acompañamos hasta tu primer", "trabajo en IT"],
      steps: [
        {
          title: "CURSO INTRODUCTORIO",
          description: "Empieza desde 0 y rinde el examen de admisión",
          imageSrc: step1,
          numberSrc: step1Number,
        },
        {
          title: "CODING BOOTCAMP",
          description: "+1.000 hs de\u00A0aprendizaje técnico",
          imageSrc: step2,
          numberSrc: step2Number,
        },
        {
          title: "PRIMER TRABAJO IT",
          description: "Trabaja en la industria con más oportunidades",
          imageSrc: step3,
          numberSrc: step3Number,
        },
      ],
      buttonText: "EMPEZAR",
    },
    Testimonials: [
      {
        name: "Julia Selma",
        imageSrc: juliaSelma,
        text: "Hoy hago esta publicación muy agradecida: Con mi familia por haberme acompañado en el intenso proceso de aprendizaje que elegí para dar el vuelco a mi vida profesional, los 4 hicimos un gran esfuerzo. Con P5 por haberme dado herramientas para insertarme en este nuevo mercado que me apasiona y de donde me llevo un gran número de buenos amigos.",
        companySrc: ibmLogo,
        companyName: "IBM",
      },
      {
        name: "Guillermo Nauberger",
        imageSrc: guillermoNeuberger,
        text: "Fueron 3 meses muy intensos y estoy muy agradecido por la oportunidad, me permitió hacer las dos cosas que más me gustan, aprender y programar. ¡Es increíble el impacto que tiene en la vida de uno hacer lo que le apasiona! Y mucho más compartiendo con otras personas. ¡Gracias P5 por el acompañamiento, la organización y la excelente formación!",
        companySrc: globantLogo,
        companyName: "Globant",
      },
      {
        name: "Paula Quiriti",
        imageSrc: paulaQuiriti,
        text: "Hacer el Bootcamp y comenzar a trabajar en programación significó una mejora real en mi calidad de vida. Este bello rubro me dio la posibilidad de llegar mejor parados a fin de mes y poder encarar proyectos con mi pareja que siempre terminábamos postergando. Estoy muy agradecida con Plataforma 5 por haber sido facilitadores de esta nueva etapa.",
        companySrc: meliLogo,
        companyName: "Mercado Libre",
      },
    ],
    Testimonies: {
      title: "TESTIMONIOS",
      subtitle: "Más de **5.000 estudiantes** nos recomiendan",
    },
    TestimoniesSlider: {
      videos: [
        "https://youtube.com/embed/y-UDMLso_lo",
        "https://youtube.com/embed/7gSdHLpwBLg",
        "https://youtube.com/embed/Uj-W2lCPKGc",
        "https://youtube.com/embed/EzFefTeULRs",
        "https://youtube.com/embed/J6uA7JptICI",
        "https://youtube.com/embed/7AESYfVynUY",
      ],
    },
    GetToKnowUs: {
      title: "Plataforma 5 **en los medios**",
      media: [
        {
          img: forbesLogo,
          name: "FORBES",
          link: "https://www.forbesargentina.com/negocios/forma-programadores-cuatro-meses-inserta-mercado-laboral-historia-plataforma-5-n12797",
        },
        { img: CNNLogo, name: "CNN" },
        {
          img: laNacionLogo,
          name: "LN",
          link: "https://www.lanacion.com.ar/economia/negocios/cursos-intensivos-cualquiera-pueda-programar-nid07082021",
        },
        {
          img: diarioPerfilLogo,
          name: "PERFIL",
          link: "https://www.perfil.com/noticias/reperfilar/programacion-como-convertirte-en-un-experto-y-cambiar-de-carrera-en-solamente-4-meses.phtml",
        },
        {
          img: infobaeLogo,
          name: "INFOBAE",
          link: "https://www.infobae.com/opinion/2022/02/23/por-que-faltan-tantos-programadores-y-como-subsanarlo/",
        },
        {
          img: infoTechnologyLogo,
          name: "TECHNOLOGY",
          link: "https://www.cronista.com/infotechnology/it-business/en-plena-pandemia-capacitaron-a-mas-de-250-personas-que-hoy-trabajan-en-empresas-como-mercado-libre-globant-y-etermax/",
        },
      ],
    },
  },
  "/aplica": {
    HeroSection: {
      title: "¿En qué curso quieres anotarte?",
      subtitle: ["Por favor", "selecciona tu curso"],
    },
    Courses: {
      intro: {
        title: "CURSO INTRODUCTORIO",
        img: applyIntroLogo,
        description:
          "Aprende desde cero HTML, CSS, JavaScript, las bases de la programación.",
        hoverText: "¿EMPIEZAS DE CERO?",
      },
      jsbc: {
        title: "JAVASCRIPT BOOTCAMP",
        img: applyJSLogo,
        description:
          "Conquista una de las tecnologías centrales de la web y conviértete en un Developer Full Stack.",
        hoverText: "¿YA SABES PROGRAMAR?",
      },
    },
    ApplyButton: {
      title: "Aplicar",
    },
  },
  "/aplica/intro": {
    ApplyIntro: {
      title: "Curso Introductorio",
      description: [
        "Aprende desde cero ",
        "HTML, CSS y JavaScript.",
        "Estas son las bases de la programación que te permitirán subir de nivel para realizar un Bootcamp.",
      ],
      img: introBackgroundIcon,
      subtitle: ["Por favor, ", "selecciona la modalidad"],
    },
    Modalities: {
      title: "**Modalidades**",
      description: "Cursada",
      modalityCardATR: {
        title: ["INTRO ", "ATR"],
        description: "ESTUDIA POR TU CUENTA",
        installment: { arg: "ÚNICO PAGO", rdm: "" },
        benefits: [
          { description: "Nuevo contenido Full Stack", isCon: true },
          { description: "Clases con profesor en vivo", isCon: true },
          { description: "Acceso al curso frontend" },
          { description: "+100 videos explicativos" },
          { description: "+100 ejercicios de práctica" },
          { description: "4 proyectos para tu portfolio" },
          { description: "Acceso a la comunidad P5" },
        ],
        applyButtonText: "Inscribirme",
      },
      modalityCardIntroTeacher: {
        title: ["INTRO ", "CON PROFESOR"],
        description: {
          arg: "ESTUDIÁ CON CLASES EN VIVO",
          rdm: "ESTUDIA CON CLASES EN VIVO",
        },
        installment: {
          arg: "TOTAL:",
          rdm: "",
        },
        benefits: [
          { description: "Nuevo contenido Full Stack", isPro: true },
          { description: "Clases con profesor en vivo", isPro: true },
          { description: "Canal exclusivo de consultas" },
          { description: "Acompañamiento del staff" },
        ],
        applyButtonText: "Inscribirme",
      },
    },
    BackButton: {
      title: "Volver",
    },
  },
  "/aplica/intro/fechas": {
    ApplyIntroDates: {
      title: "Curso Introductorio",
      description: [
        "Aprende desde cero ",
        " HTML, CSS, JavaScript, las bases de la programación.",
      ],
      img: introBackgroundIcon,
      subtitle: ["Por favor, ", "selecciona la edición"],
    },
    BackButton: {
      title: "Volver",
    },
  },
  "/aplica/javascript/fechas": {
    ApplyJavascriptDates: {
      title: "JavaScript Bootcamp",
      description: [
        "Conquista una de las tecnologías centrales de la web y conviértete ",
        "en un Developer Full Stack.",
      ],
      img: jsbcBackgroundIcon,
      subtitle: ["Por favor, ", "selecciona la edición"],
    },
    BackButton: {
      title: "Volver",
    },
  },
  "/aplicar/challenge": {
    Challenge: {
      section: "ADMISIÓN AL BOOTCAMP",
      title: "PASO 1: TEST NIVELATORIO",
      subtitle: [
        <>
          <li> Completa tu nombre y apellido </li>
          <li> Haz click en Start Assessment </li>
          <li>
            {" "}
            Cuentas con <b> 15 minutos </b> para resolverlo{" "}
          </li>{" "}
        </>,
      ],
      backgroundImg: applyIntroLogo,
    },
    Modal: {
      inactive: {
        text: "En este paso, deberás hacer un test sobre Javascript en 15 minutos. Haz click en Start Assessment para empezarlo. 💪",
        buttonText: "Continuar",
      },
      active: {
        text: "Parece que abandonaste el challenge antes de tiempo! Al hacer click, continuarás con tu intento anterior.",
        buttonText: "Estoy listo",
      },
    },
  },
  "/aplicar/challenge/feedback": {
    Result: {
      success: {
        title: "**¡Felicitaciones!**",
        subtitle: [
          "En breve, recibirás un correo para avanzar con el proceso de admisión.",
          "Revisa también tu casilla de spam.",
        ],
        backgroundImg: challengeSuccess,
        note: ["¿Tienes dudas?", "Escribenos a ", " admisiones@plataforma5.la"],
      },
      failure: {
        title: "**Estuviste muy cerca de alcanzar el nivel esperado**",
        subtitle: [
          "¡No te preocupes! Con un poco más de práctica, podrás superar este desafío.",
          "En breve, recibirás un email con los próximos pasos.",
          "(Por las dudas, revisa tu correo no deseado)",
        ],
        backgroundImg: challengeFailure,
        note: ["¿Tienes dudas?", "Escribenos a", "admisiones@plataforma5.la"],
      },
      error: {
        title: "**¡Algo salió mal!**",
        subtitle: [],
        backgroundImg: challengeError,
        note: [
          "",
          "Por favor, escribinos a ",
          "admisiones@plataforma5.la ",
          "y te ayudaremos a solucionarlo",
        ],
      },
      notAllowed: {
        title:
          "En breve **recibirás un email con el próximo paso del proceso de admisión.**",
        subtitle: ["(Por las dudas, revisa tu correo no deseado)"],
        backgroundImg: notMobileAllowed,
        note: ["¿Tienes dudas?", "Escribenos a ", " admisiones@plataforma5.la"],
      },
      retry: {
        title: "**¡Tu solicitud no se completó!**",
        subtitle: [
          "Ya aprobaste este test.",
          "El próximo paso es completar el formulario",
          "que te mandamos por email.",
        ],
        backgroundImg: challengeError,
        note: [
          "¿No te llegó el email?",
          "Escribenos a ",
          "admisiones@plataforma5.la ",
          "y te ayudaremos a solucionarlo",
        ],
      },
      maxRetry: {
        title:
          "**Llegaste al límite de intentos posibles para esta edición del Bootcamp**",
        subtitle: [
          "Haciendo nuestro Curso Introductorio podrás",
          "prepararte para la próxima edición.",
        ],
        button: { text: "Ir al Intro", href: "/aplica/intro" },
        backgroundImg: challengeFailure,
        note: ["¿Tienes dudas?", "Escríbenos a", "admisiones@plataforma5.la"],
      },
      backgroundImg: dotBackgroundChallengeImg,
    },
  },
  "/aplicar/payment": {
    ApplyEmail: {
      title: {
        intro: "¡Muchas gracias por comprar!",
        bootcamp:
          "¡Nos alegra que quieras convertirte en Full Stack Developer!",
      },
      img: {
        src: successIcon,
        alt: "Ícono de mano con pulgar arriba.",
      },
      description: [
        "Una vez confirmado tu pago, recibirás un ",
        "email ",
        "con el ",
        "primer paso",
        " del proceso de admisión.",
        "para acceder al ",
        "curso.",
        "En breve, recibirás un ",
      ],
      disclaimer: "(Por las dudas, revisa tu correo no deseado)",
      emailNotReceived: [
        "¿No te llegó el mail? Escríbenos a admisiones@plataforma5.la",
      ],
    },
    ApplyError: {
      img: {
        src: errorIcon,
        alt: "Ícono de mano con pulgar abajo.",
      },
      background: {
        src: errorBackground,
        alt: "Fondo de pantalla de error.",
      },
      title: "Tu solicitud no se completó",
      description:
        "Escríbenos a admisiones@plataforma5.la y te ayudaremos a solucionarlo.",
    },
  },
  "/aplica/intro/free": {
    ApplyForm: {
      Header: {
        title: "Curso Introductorio:",
        subtitle:
          "Aprende desde cero HTML, CSS, JavaScript, las bases de la programación.",
        backgroundImg: introBackgroundIcon,
      },
      Breadcrumbs: [
        {
          href: "/aplica/intro",
          label: "Aplica",
        },
        {
          href: "/aplica/intro/free",
          label: "Clase Gratuita",
        },
        {
          label: <b>Inscripción</b>,
        },
      ],
      ModalityCard: {
        title: "APRENDE A PROGRAMAR.",
        online: "CAMBIA TU VIDA",
        async: "PRUEBA CLASE GRATUITA",
        modality: "CAMBIA TU VIDA | PRUEBA CLASE GRATUITA",
        shift: "TURNO",
      },
      Form: {
        unfilledFieldsError: "Por favor, completa todos los campos.",
        notCoincidentEmailError: "Las direcciones de email no coinciden.",
        invalidEmailError: "Por favor, ingresa un email válido.",
        helper:
          "¿Tienes dudas con el proceso de inscripción? Escríbenos a admisiones@plataforma5.la",
        title: "¡Descubre si te gusta programar con nuestra clase gratuita!",
        subtitle: [
          "Ofrecemos una clase gratuita sobre HTML y CSS.",
          "",
          "Déjanos tus datos para enviarte por email el acceso a la clase.",
        ],
      },
    },
  },
  "/aplica/intro/free/result": {
    Result: {
      success: {
        backgroundImg: successIcon,
        title: "**¡Gracias por inscribirte!**",
        subtitle: [
          "En breve, recibirás un email con los accesos a la clase gratuita.",
          "(Por las dudas, revisa tu correo no deseado)",
        ],
        note: [
          "¿No te llegó el mail?",
          "Escribenos a",
          "admisiones@plataforma5.la",
        ],
      },
      error: {
        backgroundImg: errorIcon,
        title: "**Tu solicitud no se completó**",
        subtitle: [],
        note: [
          "Te ayudaremos a solucionarlo.",
          "Escribenos a",
          "admisiones@plataforma5.la",
        ],
      },
    },
  },
  "/aplica/intro/formulario": {
    ApplyForm: {
      Header: {
        title: "Curso Introductorio:",
        subtitle:
          "Aprende desde cero HTML, CSS, JavaScript, las bases de la programación.",
        backgroundImg: introBackgroundIcon,
      },
      Breadcrumbs: [
        {
          href: "/aplica/intro",
          label: "Aplica",
        },
        {
          href: "/aplica/intro/fechas",
          label: "Curso Introductorio",
        },
        {
          label: <b>Inscripción</b>,
        },
      ],
      Form: {
        unfilledFieldsError: "Por favor, completa todos los campos.",
        notCoincidentEmailError: "Las direcciones de email no coinciden.",
        invalidEmailError: "Por favor, ingresa un email válido.",
        title: "FINALIZA TU INSCRIPCIÓN",
        subtitle: [
          [
            "",
            "Con esta información crearemos tu cuenta de Pledu,",
            " nuestra plataforma educativa.",
          ],
        ],
        helper:
          "¿Tienes dudas con el proceso de inscripción? Escríbenos a admisiones@plataforma5.la",
        total: "Total a pagar:",
        payButtonText: "Ir a PayPal",
      },
      ModalityCard: {
        title: "EMPIEZA HOY. MANEJA TUS TIEMPOS",
        online: "ONLINE",
        async: "ASÍNCRONO",
        modality: "ONLINE | ASÍNCRONO",
        shift: "TURNO",
      },
    },
  },
  "/aplica/javascript/formulario": {
    ApplyForm: {
      Header: {
        title: "Javascript Bootcamp",
        subtitle: [
          "Conquista una de las tecnologías",
          ["centrales de la web y", "conviértete en un "],
          ["", "Developer Full Stack."],
        ],
        backgroundImg: jsbcBackgroundIcon,
      },
      Breadcrumbs: [
        {
          href: "/aplica",
          label: "Aplica",
        },
        {
          href: "/aplica/javascript/fechas",
          label: "Javascript",
        },
        {
          label: <b>Inscripción</b>,
        },
      ],
      Form: {
        unfilledFieldsError: "Por favor, completa todos los campos.",
        notCoincidentEmailError: "Las direcciones de email no coinciden.",
        invalidEmailError: "Por favor, ingresa un email válido.",
        title: "Inscripción al Bootcamp",
        subtitle: [
          ["Antes de empezar, ingresa tu ", "email", "."],
          [
            "",
            "* Si realizaste algún curso con nosotros, por favor ingresa el mismo email que usas para acceder a Pledu.",
          ],
        ],
        helper:
          "¿Tienes dudas con el proceso de inscripción? Escríbenos a admisiones@plataforma5.la",
        total: "Total a pagar:",
        payButtonText: "Ir a PayPal",
      },
    },
    BackButton: {
      title: "Volver",
    },
    ModalityCard: {
      title: "EMPIEZA HOY. MANEJA TUS TIEMPOS",
      online: "ONLINE",
      async: "ASÍNCRONO",
      modality: "ONLINE | ASÍNCRONO",
      shift: "TURNO",
    },
    BreadCrumbs: {
      crumbsText: [
        "Aplica",
        { atr: "Estudia por tu cuenta", introTeacher: "Con profesor" },
        "Finaliza tu inscripción",
      ],
    },
  },
  "/intro": {
    Hero: {
      title: ["**Curso Introductorio**", "**de Programación**"],
      subtitle: {
        desktop:
          "**Aprende las bases de programación** (HTML, CSS y JavaScript)",
        mobile: "**Aprende las bases de programación:** HTML, CSS y Javascript",
      },
      description: [],
      checkList: {
        desktop: [],
        mobile: [],
      },
      mainImg: {
        src: introHeroMainImg,
        alt: "programmer",
      },
      secondaryImg: null,
      backgroundImg: {
        desktop: {
          src: heroDesktopBackgroundImg,
          alt: "code background",
        },
        mobile: {
          src: heroMobileBackgroundImg,
          alt: "code background",
        },
      },
      button: {
        title: "Comprar",
        to: "/aplica/intro",
      },
    },
    Introduction: {
      title: {
        desktop: "Prepárate **en 7 semanas** y entra al **Bootcamp**",
        mobile: "Prepárate **en 7 semanas** para el **Bootcamp**",
      },
      subtitle: "",
      clarification: [
        "**NUEVO PROGRAMA FULL STACK* **",
        "_*(EXCLUSIVO PARA LA MODALIDAD CON PROFESOR)_",
      ],
      description: {
        desktop: [
          "Este curso está diseñado para que **aprendas desde cero los conceptos para el examen de admisión al Javascript Bootcamp** de forma **100% remota.**",
          "**Son 63 horas** de contenido teórico-práctico donde aprenderás HTML, CSS y JavaScript con videos, guías, tests, ejercicios y proyectos.",
        ],
        mobile: [
          "**Aprende desde cero** los conceptos para el examen de admisión al **Bootcamp.**",
          "Curso **100% remoto** donde aprenderás con contenido teórico-práctico sobre **HTML, CSS y JavaScript.**",
        ],
      },
      link: {
        href: "https://drive.google.com/file/d/1Y2vFx_tZFCtQ6oLK83d_Q_nynBk1Dzvj/view",
        text: "VER PROGRAMA",
      },
    },
    DescriptionBanner: {
      title: "Al finalizar el curso, podrás:",
      list: [
        "Entender las bases teóricas de la programación Web",
        "Resolver ejercicios y desafíos de complejidad media con JavaScript",
        "Realizar una página web Full Stack con un proyecto CRUD (sólo para Intro Con Profesor)",
      ],
    },
    IntroText: {
      dotBackgroundImg: { src: dotBackgroundImg, alt: "Fondo de puntos" },
      content: {
        desktop:
          "Diseña tu **primera página web Full Stack** y descubre si la programación es para vos",
        mobile:
          "Diseña tu **primera página web Full Stack** y descubre si la programación es para vos",
      },
    },
    UnitsCarrousel: {
      title: "**Programa**",
      subtitle: "Conoce más",
      units: [
        {
          unit: "Clase 1",
          title: "HTML",
          lessons: [
            { topic: "Introducción a internet" },
            { topic: "Introducción a HTML" },
            {
              topic: "Tipos de tags, tags con atributos y tags auto-contenidos",
            },
            { topic: "Publicación de páginas online" },
          ],
        },
        {
          unit: "Clase 2",
          title: "HTML",
          lessons: [
            { topic: "Introducción a CSS" },
            { topic: "Selectores CSS" },
            { topic: "Selectores avanzados" },
            { topic: "ID y Clase" },
          ],
        },
        {
          unit: "Clase 3",
          title: "Formularios HTML",
          lessons: [
            { topic: "Divs y Spans" },
            { topic: "Formularios" },
            { topic: "Otros tipos de inputs" },
          ],
        },
        {
          unit: "Clase 4",
          title: "CSS Modelo de caja y posicionamiento.",
          lessons: [
            { topic: "Pseudo selectores" },
            { topic: "Box model" },
            { topic: "Posicionamiento" },
            { topic: "Flexbox" },
          ],
        },
        {
          unit: "Clase 5",
          title: "CSS Frameworks",
          lessons: [
            { topic: "Introducción a Bootstrap" },
            { topic: "Sitios responsive" },
            { topic: "Componentes" },
          ],
        },
        {
          unit: "Clase 6",
          title: "Proyecto I: Tu Portfolio",
          lessons: [
            { topic: "Elaboración de Single-Page Application con HTML y CSS" },
          ],
        },
        {
          unit: "Clase 7",
          title: "Introducción a JavaScript",
          lessons: [
            { topic: "Introducción a JavaScript" },
            { topic: "Tipos de datos" },
            { topic: "Variables" },
            { topic: "ES6: Template Literals" },
          ],
        },
        {
          unit: "Clase 8",
          title: "If/Else",
          lessons: [
            { topic: "Estructura condicional if - else" },
            { topic: "Operadores lógicos y de comparación" },
            { topic: "Operador Ternario" },
          ],
        },
        {
          unit: "Clase 9",
          title: "While Loop",
          lessons: [
            { topic: "Estructura repetitiva while" },
            { topic: "Acumuladores y contadores" },
            { topic: "Objeto Math" },
          ],
        },
        {
          unit: "Clase 10",
          title: "Funciones",
          lessons: [
            { topic: "Declaración y llamado de una Función" },
            { topic: "Manejo de Funciones con múltiples parámetros" },
            { topic: "Keyword return" },
            { topic: "ES6:  Arrow Functions y Parámetros por default" },
          ],
        },
        {
          unit: "Clase 11",
          title: "Arreglos",
          lessons: [
            { topic: "Creación y acceso a los valores de un Arreglo" },
            { topic: "Métodos de Arreglos: push, pop, indexOf, etc." },
            { topic: "Iteración de Arreglos: For Loop y ForEach" },
            { topic: "Métodos de Arreglos en ES6: map, reduce, filter" },
          ],
        },
        {
          unit: "Clase 12",
          title: "Objetos",
          lessons: [
            { topic: "Creación y acceso a los valores de un Objeto" },
            { topic: "Modificando Objetos" },
            { topic: "Recorriendo un Objeto" },
          ],
        },
        {
          unit: "Clase 13",
          title: "Manipulando el DOM",
          lessons: [
            { topic: "¿Qué es el DOM?" },
            {
              topic:
                "Manipulación de texto, estilo y contenidos de los elementos",
            },
            { topic: "getAttribute() y setAttribute()" },
          ],
        },
        {
          unit: "Clase 14",
          title: "Eventos",
          lessons: [
            { topic: "¿Qué es un Evento?" },
            {
              topic:
                "Diferentes Eventos: click, mouseover, mouseleave,  keydown, etc.",
            },
            { topic: "Argumentos de un Evento" },
            { topic: "Producción de un juego: Typer D" },
          ],
        },
        {
          unit: "Clase 15",
          title: "Proyecto II: Color Game",
          lessons: [
            { topic: "Realización de un juego dinámico con JavaScript" },
          ],
        },
        {
          unit: "Clase 16",
          title: "POO (Programación Orientada a Objetos)",
          lessons: [
            { topic: "Paradigmas de programación" },
            { topic: "Clases" },
            { topic: "Objetos" },
            { topic: "Herencia" },
            { topic: "Objetos Nativos de JS" },
          ],
        },
        {
          unit: "Clase 17",
          title: "Proyecto II: To Do List",
          lessons: [
            {
              topic:
                "Realización de una herramienta de uso cotidiano con JavaScript",
            },
          ],
        },
        {
          unit: "Clase 18",
          title: "AJAX & JSON",
          lessons: [
            { topic: "Modelo Cliente - Servidor" },
            { topic: "AJAX & JSON" },
            { topic: "APIs" },
            {
              topic:
                "Proyecto IV: Realización de página con la utilización de API",
            },
          ],
        },
      ],
      downloadButtonText: "Descargar",
    },
    Technologies: {
      title: "**Aprenderás a usar**",
      subtitle: "Herramientas",
    },
    TechnologiesCarrousel: {
      imgs: [
        VsCodeLogo,
        HtmlLogo,
        CssLogo,
        jsLogo,
        BootstrapLogo,
        JsonLogo,
        NetlifyLogo,
      ],
      alt: "tecnología",
    },
    ClassCard: {
      title: "Certifica tus logros",
      subtitle: [
        "Obtén tu certificado y ",
        "suma credenciales como programador.",
      ],
      img: { src: introCertificate, alt: "Certificado" },
    },
    Modalities: {
      title: "**Modalidades**",
      description: "Cursada",
      modalityCardATR: {
        title: ["INTRO ", "ATR"],
        description: "ESTUDIA POR TU CUENTA",
        installment: { arg: "ÚNICO PAGO", rdm: "" },
        benefits: [
          { description: "Nuevo contenido Full Stack", isCon: true },
          { description: "Clases con profesor en vivo", isCon: true },
          { description: "Acceso al curso frontend" },
          { description: "+100 videos explicativos" },
          { description: "+100 ejercicios de práctica" },
          { description: "4 proyectos para tu portfolio" },
          { description: "Acceso a la comunidad P5" },
        ],
        applyButtonText: "Inscribirme",
      },
      modalityCardIntroTeacher: {
        title: ["INTRO ", "CON PROFESOR"],
        description: {
          arg: "ESTUDIÁ CON CLASES EN VIVO",
          rdm: "ESTUDIA CON CLASES EN VIVO",
        },
        installment: {
          arg: "TOTAL:",
          rdm: "",
        },
        benefits: [
          { description: "Nuevo contenido Full Stack", isPro: true },
          { description: "Clases con profesor en vivo", isPro: true },
          { description: "Canal exclusivo de consultas" },
          { description: "Acompañamiento del staff" },
        ],
        applyButtonText: "Inscribirme",
      },
    },
    Booking: {
      load: {
        title: "**Intro con Profesor**",
        subtitle: "Agenda",
      },
      err: {
        title:
          "Oops! parece que hubo un error y no pudimos cargar las clases... intenta nuevamente en unos segundos",
      },
      normal: {
        title: "**Intro con Profesor**",
        subtitle: "Agenda",
        nextDates:
          "¡Gracias por tu interés! 😊 Actualmente este curso sólo está dirigido a estudiantes a través de alianzas con gobiernos y empresas. Si no estás vinculado a una de estas alianzas, ¡estate atento a esta página para futuras novedades!",
      },
    },
    YourPace: {
      title: "Prueba una clase gratis",
      subtitle: [
        "Descubre nuestro Curso Introductorio de Programación.",
        "No hace falta que tengas conocimientos previos",
      ],
      link: {
        text: "Inscribirme",
        to: "/aplica/intro/free",
      },
    },
    Community: {
      title: "Comunidad P5",
      subtitle:
        "Súmate al Curso Introductorio y accede a los beneficios de nuestra comunidad en Discord. Comparte tus dudas, memes, experiencias y aprende acompañado. ¡Ya somos más de 5.000 personas!",
      buttonText: "Quiero ser parte",
    },
    // --------------------------------------------------------------- //
    Tools: {
      title: "Herramientas",
      subtitle: "Aprenderás a usar",
      logosImg: [
        { src: jsLogo, alt: "Logo JavaScript", title: "JavaScript" },
        { src: mongodbLogo, alt: "Logo MongoDB", title: "MongoDB" },
        { src: nodejsLogo, alt: "Logo Node.js", title: "Node.js" },
        { src: reactLogo, alt: "Logo React", title: "React" },
        { src: expressLogo, alt: "Logo Express", title: "Express" },
        { src: reduxLogo, alt: "Logo Redux", title: "Redux" },
        { src: sequelize, alt: "Logo sequelize", title: "Sequelize" },
        { src: jestLogo, alt: "Logo jest", title: "Jest" },
        { src: sqlLogo, alt: "Logo Sql", title: "SQL" },
        { src: mochaLogo, alt: "Logo mocha", title: "Mocha" },
        { src: chaiLogo, alt: "Logo Chai", title: "Chai" },
        {
          src: awsLogo,
          alt: "Logo AWS",
          title: `Amazon
                       Web
                    Services`,
        },
        { src: gitLogo, alt: "Logo Git", title: "Git" },
        { src: JWTlogo, alt: "Logo jwt", title: "JWT" },
        { src: githubLogo, alt: "Logo GitHub", title: "GitHub" },
        { src: jenkins, alt: "Logo jenkins", title: "Jenkins" },
        {
          src: commandLineLogo,
          alt: "Logo Command Line",
          title: "Command  Line",
        },
        { src: cypressLogo, alt: "Logo cypress", title: "Cypress" },
        { src: sassLogo, alt: "Logo sass", title: "Sass" },
        { src: dockerLogo, alt: "Logo docker", title: "Docker" },
        { src: typescriptLogo, alt: "Logo typescript", title: "TypeScript" },
        { src: nextLogo, alt: "Logo next", title: "Next.js" },
        { src: scrumLogo, alt: "Logo Scrum", title: "Scrum" },
      ],
    },
  },
  "/campaign/lorem-ipsum": {
    LandingBootcamp: {
      courseReport: {
        title: "Lorem Ipsum",
        rankingText: "Lorem Ipsum",
        ranking: "Lorem Ipsum",
        img: {
          src: courseReport,
          alt: "Lorem Ipsum",
        },
      },
      switchUp: {
        title: "Lorem Ipsum",
        rankingText: "Lorem Ipsum",
        ranking: "Lorem Ipsum",
        img: {
          src: switchUp,
          alt: "Lorem Ipsum",
        },
      },
    },
    LandingTitle: {
      title: "Lorem Ipsum",
      description: ["Lorem Ipsum", " Lorem Ipsum ", "Lorem Ipsum"],
    },
    TakeTheLeap: {
      title: "Lorem Ipsum",
      subtitle: "Lorem Ipsum",
      grid: { src: gridBackground, alt: "Lorem Ipsum" },
      jsbc: {
        title: "Lorem Ipsum",
        subtitle: "Lorem Ipsum",
        icon: {
          src: javascriptIcon,
          alt: "Lorem Ipsum",
        },
        card: {
          src: javascriptCard,
          alt: "Lorem Ipsum",
        },
      },
    },
    MoreInfoButton: {
      buttonText: "Lorem Ipsum",
    },
    FounderTestimony: {
      founderImg: {
        src: founderPicture,
        alt: "Lorem Ipsum",
      },
      words: "''Lorem Ipsum''.",
      identity: ["Lorem Ipsum", "Lorem Ipsum"],
    },
  },
  "/nosotros": {
    Hero: {
      title: ["Somos", "**Plataforma 5**"],
      subtitle: {
        desktop:
          "Acompañando a nuestros alumnos a dar el salto de carrera desde el 2017.",
        mobile:
          "Acompañando a nuestros alumnos a dar el salto de carrera desde el 2017.",
      },
      description: "",
      checkList: {
        desktop: [],
        mobile: [],
      },
      mainImg: {
        src: aboutUsHeroMainImg,
        alt: "programmer",
      },
      secondaryImg: null,
      backgroundImg: {
        desktop: {
          src: heroDesktopBackgroundImg,
          alt: "code background",
        },
        mobile: {
          src: heroMobileBackgroundImg,
          alt: "code background",
        },
      },
    },
    AboutUsText: {
      dotBackgroundImg: { src: dotBackgroundImg, alt: "Fondo de puntos" },
      content: {
        desktop:
          "**Aprende a programar con nosotros y acelera tu inserción laboral**",
        mobile:
          "**Aprende a programar con nosotros y acelera tu inserción laboral**",
      },
    },
    HeroSection: {
      heroTitle: "CONOCE PLATAFORMA 5",
      section: "NUESTRA HISTORIA",
      introTitle: "Primer Coding Bootcamp de Argentina",
      intro: [
        "“Empecé ",
        "P5 ",
        "porque quería ",
        "aprender a programar ",
        "y no sabía por dónde empezar. Después de pasar mucho tiempo haciendo cursos online sin un camino claro, encontré los ",
        "Coding Bootcamps ",
        "que recién aparecían en Estados Unidos, y decidí traer el formato a Latinoamérica para ayudar a otros que estuvieran en mi lugar”.",
      ],
      introAuthor: "Santi Scanlan | CEO - Founder",
      heroextDesktop: "¡Nos apasiona lo que hacemos y siempre vamos por más!",
      hero: [
        "Nuestro objetivo es ",
        "ayudarte ",
        "a conseguir ",
        "tu primer ",
        "trabajo en programación",
        ", no importa tu edad ni de dónde vengas.",
      ],
    },
    Motivation: {
      sections: [
        {
          section: "PLATAFORMA EDUCATIVA",
          sectionDescription: "PLEDU",
          info: [
            "Sencilla e intuitiva, nuestra plataforma favorece a que la experiencia de nuestros estudiantes sea completa y guiada. Aprender a programar a nivel profesional requiere de muchísimas horas de práctica, y sobre todo de mucha determinación. ¡Simplifica tu experiencia con Pledu!",
          ],
        },
        {
          section: "NUESTROS VALORES",
          sectionDescription: "Invertimos en tu éxito",
          info: [
            [
              "Nuestros alumnos confían en este proceso para ",
              "cambiar su futuro",
              ". Por eso, con cada inscripción asumimos el compromiso de que lo logren. Ya sea acompañándolos en los momentos de frustración, con el mejor contenido e incluso financiando a quienes lo necesiten.",
            ],
            [
              "También, creemos que el ",
              "compromiso debe ser mutuo",
              ". Quien ingresa al Bootcamp tiene que comprometerse con dar el 100% para llegar hasta el final exitosamente.",
            ],
          ],
        },
      ],
    },
  },
  "/faqs": {
    Hero: {
      title: ["**Preguntas frecuentes**"],
      subtitle: {
        desktop: "",
        mobile: "",
      },
      description: "",
      checkList: {
        desktop: [],
        mobile: [],
      },
      mainImg: {
        src: aboutUsHeroMainImg,
        alt: "programmer",
      },
      secondaryImg: null,
      backgroundImg: {
        desktop: {
          src: faqsHeroDesktopBackgroundImg,
          alt: "code background",
        },
        mobile: {
          src: heroMobileBackgroundImg,
          alt: "code background",
        },
      },
    },
    FaqsText: {
      dotBackgroundImg: { src: dotBackgroundImg, alt: "Fondo de puntos" },
      content: {
        desktop:
          "**Resuelve tus dudas** con esta selección de preguntas más consultadas",
        mobile: "**Despeja tus dudas** con esta selección de preguntas",
      },
    },
    Faqs: [
      {
        section: "TUS PREGUNTAS",
        title: "Curso Introductorio",
        questions: [
          {
            question:
              "¿Cuál es la diferencia entre el Curso Introductorio con Profesor y el ATR?",
            text: [
              "Las principales diferencias entre las dos modalidades que ofrecemos son:",
              "Con el **ATR** accedes al contenido **Frontend**, mientras que el nuevo **Intro con Profesor** aborda un programa introductorio **Full Stack** (Frontend y Backend).",
              "Al comprar el Intro Con Profesor, vas a contar con clases online en vivo con un profesor del staff P5. Las clases se dictan 3 veces por semana y dura 7 semanas. El ATR no incluye clases en vivo, es un opción 100% autodidacta y vas a poder hacerlo según tus tiempos.",
            ],
          },
          {
            question: "¿Hay un límite de edad para anotarme?",
            text: [
              "No. Para nosotros **la edad no es una limitante** y consideramos que tampoco lo es para insertarse laboralmente en el rubro de IT. La edad de nuestros estudiantes va desde 16 años hasta más de 55 años.",
            ],
          },
          {
            question: "¿Qué computadora necesito para cursar?",
            text: [
              "Para realizar el Curso Introductorio puedes utilizar cualquier sistema operativo (Linux, Windows, Mac). Una **notebook con un procesador Intel Core i3 y 4GB de memoria RAM está perfecto.**",
              "Si tienes pensado hacer un Coding Bootcamp te recomendamos ya tener una computadora con un procesador i5, 8GB de memoria RAM y 125 GB de memoria disponible en el disco.",
              "Además, ten en cuenta que para el JavaScript Bootcamp se requiere el uso de sistema operativo Linux o Mac.",
            ],
          },
          {
            question: "¿Entregan una certificación al terminar?",
            text: [
              "Sí. En Plataforma 5 apuntamos a un nuevo sistema educativo centrado en la práctica y en los resultados demostrables.",
              "Una vez que hayas finalizado el Curso Introductorio y entregado los proyectos prácticos a desarrollar, **recibirás un certificado** que avala los conocimientos adquiridos.",
            ],
          },
          {
            question: "¿Puedo conseguir trabajo luego del Curso Introductorio?",
            text: [
              "El objetivo de este curso es prepararte para entrar al Coding Bootcamp.",
            ],
          },
          {
            question: "¿Hay clases en vivo para las personas que cursamos ATR?",
            text: [
              "No. Puedes reforzar tus conocimientos de JavaScript con nuestras clases grabadas en Youtube.",
            ],
          },
          {
            question:
              "¿A quién le puedo consultar mis dudas técnicas si estoy cursando ATR?",
            text: [
              "Utilizamos la plataforma **Discord** para comunicarnos. Puedes escribir en los canales específicos y la Comunidad P5 te ayudará en lo que necesites. Asimismo, te sugerimos contestar preguntas de otras personas, mirar y comentar trabajos e inspeccionar su código para seguir aprendiendo de manera colaborativa.",
            ],
          },
          {
            question: "¿Puedo cambiarme de “ATR” a “Con Profesor”?",
            text: [
              "Sí. Para hacerlo, envíanos un mail a admisiones@plataforma5.la",
              "Se te cobrará el monto diferencial que hay entre las dos modalidades.",
            ],
          },
          {
            question:
              "¿Si apruebo el Curso Introductorio tengo que hacer el Coding Challenge?",
            text: [
              "Sí. El Curso Introductorio te brinda las herramientas necesarias para rendir el Coding Challenge. Sin embargo, todas **las personas que quieran ingresar a nuestros Coding Bootcamps deben aprobar el examen técnico de admisión.**",
            ],
          },
        ],
      },
      {
        section: "TUS PREGUNTAS",
        title: "Coding Challenge",
        questions: [
          {
            question: "¿Qué es el Coding Challenge?",
            text: [
              "El Coding Challenge es el examen técnico de admisión a nuestros Coding Bootcamps.",
              "Si ya dominas los conceptos del Curso Introductorio, inicia tu proceso de admisión a nuestros Coding Bootcamps completando el formulario de ejercicios nivelatorios.",
            ],
          },
          {
            question: "¿Qué temas entran en el Coding Challenge?",
            text: [
              "**Variables, If/Else, While y For Loops, Funciones, Arreglos y Objetos de JavaScript.**",
              "Practica mucho cada uno de estos temas hasta dominarlos para tener éxito en tu Challenge.",
            ],
          },
          {
            question: "¿El proceso de admisión al Coding Bootcamp es gratuito?",
            text: [
              "**Sí.** El proceso de admisión al Coding Bootcamp **es completamente gratuito.** ",
            ],
          },
          {
            question: "¿Cómo es la dinámica del Coding Challenge?",
            text: [
              "Es un **examen sincrónico** de 5 ejercicios que escalan en dificultad en la plataforma Qualified. Se realiza **a través de una videollamada** en día y horario a pactar.",
            ],
          },
          {
            question: "¿Puedo rendir el Coding Challenge más de una vez?",
            text: [
              "Sí. El Coding Challenge puede rendirse **hasta dos veces** en cada edición de Bootcamp.",
            ],
          },
          {
            question:
              "Si no pude entrar en una edición del Coding Bootcamp, ¿puedo volver a hacer la admisión en otro momento?",
            text: [
              "Sí. Es muy importante que quienes aspiren a ingresar a nuestros Coding Bootcamps sean perseverantes y mantengan el foco en sus objetivos.",
              "En caso de que **no apruebes la admisión técnica**, vamos a delinear juntos un **camino** para que puedas reforzar los conocimientos y **volver a intentarlo.** ",
            ],
          },
        ],
      },
      {
        section: "TUS PREGUNTAS",
        title: "Coding Bootcamp",
        questions: [
          {
            question: "¿Qué es un Coding Bootcamp?",
            text: [
              "Un Coding Bootcamp es un curso intensivo de programación que busca desarrollar tu talento como developer y ayudarte a conseguir empleo en IT. ",
              "Nuestro Bootcamp te convierte en real-job-ready en 29 semanas con una propuesta 100% enfocada en las tecnologías y necesidades del mundo tech.",
              "Conviértete en Full Stack Developer con el mejor nivel técnico, aplicando lo aprendido en proyectos profesionales reales.",
            ],
          },
          {
            question: "¿Hay opciones de financiación?",
            text: [
              "Sí. Contamos con **tres planes:**",
              "**Pago Full Diferido:** ¡Financiamos el 100% de tu Bootcamp! Empieza a pagar al conseguir tu primer empleo, en hasta 24 cuotas. El valor total es de USD 4.500. Los cupos son limitados.",
              "**Pago Diferido:** Abona un pago inicial mínimo de USD 600 y , el monto restante, lo empiezas a pagar recién cuando consigas tu primer empleo, en hasta 24 cuotas. El valor total es de USD 3.900. Ahorrás USD 600.",
              "**Pago De Contado:** Abona antes de iniciar el Coding Bootcamp en un único pago y aprovecha un descuento. El valor total es de USD 2.900. Ahorrás USD 1.600.",
            ],
          },
          {
            question: "¿Es requisito hablar inglés?",
            text: [
              "**El inglés no es requisito obligatorio**, pero te ayudará a comprender mejor la documentación y te permitirá acceder a material complementario para tu formación. Ten en cuenta también que algunas empresas pueden solicitar un nivel intermedio de inglés en sus búsquedas.",
            ],
          },
          {
            question: "¿Qué computadora necesito?",
            text: [
              "En el JavaScript Bootcamp solamente utilizamos sistema operativo Linux o Mac y necesitás una **computadora con procesador i5, 8GB de memoria RAM y 125 GB de memoria disponible en el disco** es lo más conveniente. Si el presupuesto te lo permite, recomendamos un procesador i7, 12 GB de memoria RAM y 256 GB de memoria disponible",
            ],
          },
          {
            question: "¿Me ayudan a conseguir trabajo?",
            text: [
              "En P5 **nos comprometemos a potenciar la búsqueda de empleo** de quienes egresan de nuestros Bootcamps.",
              "De la misma forma, **las personas que realizan el Bootcamp deben buscar activamente trabajo** para incrementar sus chances de éxito.",
              "Nuestro objetivo principal es que el 100% de nuestros bootcampers se inserte en el mercado laboral IT.",
            ],
          },
          {
            question:
              "En los planes financiados, ¿se cobra un porcentaje fijo de mi sueldo?",
            text: [
              "No. Una vez que **consigas tu primer trabajo** y según tu tipo de actividad laboral, el equipo de Administración armará un esquema a tu medida, **con un plan de pagos de hasta 24 cuotas mensuales** y consecutivas (para los planes “Pago Diferido” y “Full Diferido”).",
              "Antes de comenzar el Coding Bootcamp, con la **firma del Acuerdo de Pago** se explicitan las pautas importantes respecto a los tiempos de devolución y los montos. De esta forma, le damos un marco a tu compromiso de pago.",
            ],
          },
          {
            question: "¿Qué pasa si no consigo trabajo?",
            text: [
              "Desde P5 vamos a **formarte en todas las skills necesarias para implementar una estrategia de búsqueda laboral exitosa**. Sin embargo, recae en ti la responsabilidad última de conseguir el trabajo, ya que eres tú quien va a buscar empleos, postularse e ir a las entrevistas.",
              "Al finalizar el bootcamp deberás asumir el compromiso de entrar en Búsqueda Laboral Activa (BLA) y perseverar en ella hasta lograr el objetivo de tu primer trabajo en IT. Si bien los tiempos de inserción varían caso a caso, **todos nuestros graduados que perseveran en la búsqueda logran el objetivo**.",
            ],
          },
        ],
      },
      {
        section: "TUS PREGUNTAS",
        title: "Job Placement",
        questions: [
          {
            question: "¿Qué significa Job Placement?",
            text: [
              "El área de Job Placement de Plataforma 5 interviene para acompañar a quienes egresan de un Bootcamp en su inserción laboral. El equipo te ofrecerá herramientas para el desarrollo de tu estrategia profesional: armado de CV, perfil en redes sociales laborales y entrevistas grupales con empresas (Recruiting Weeks)",
            ],
          },
          {
            question: "¿Qué implica buscar activamente trabajo?",
            text: [
              "Buscar activamente trabajo significa **realizar de manera sistematizada, periódica y proactiva acciones para aumentar la probabilidad de conseguir un primer empleo como developer.** Esto implica tener una estrategia de búsqueda, diseñar un plan con objetivos generales y específicos, y realizar acciones todos los días para acercarse a la meta planteada.",
            ],
          },
          {
            question: "¿Qué son las Recruiting Weeks?",
            text: [
              "A través de decenas de encuentros virtuales, breves y grupales, tendemos un **puente entre los bootcampers y una gran variedad de empresas empleadoras** para acercarlos a su primer trabajo.",
              "Además, organizamos **Webinars** sobre temas destacados para la industria de la programación o sobre recursos útiles para potenciar su inserción laboral.",
            ],
          },
        ],
      },
    ],
  },
};

export default en;
