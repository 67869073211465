export const linkStyles = {
  MuiLink: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        cursor: "pointer",
        transition: "all 0.3s ease-in-out",
        display: "inline-flex",
        alignItems: "center",
        gap: "8px",
        textTransform: "uppercase",
        borderRadius: "20px",
        padding: "0 20px",
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "40px",
        "&:hover": {
          backgroundColor:
            theme.palette?.[ownerState?.variant]?.hover ||
            theme.palette.background.default,
        },
        "&.Mui-disabled": {
          opacity: 0.6,
          color: theme.palette.text.disabled,
        },
      }),
    },
    variants: [
      {
        props: {
          variant: "primary",
        },
        style: {
          backgroundColor: "#001f55",
          color: "white",
        },
      },
      {
        props: {
          variant: "secondary",
        },
        style: {
          backgroundColor: "#FF012B",
          color: "white",
        },
      },
    ],
  },
};
