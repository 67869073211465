/** @format */

//material ui
import React from "react";
import { useTheme } from "@mui/styles";
import { Typography, Box } from "@mui/material";

//json2mq
import { useLocation } from "react-router-dom";
import useTranslation from "../../../hooks/useTranslation";

const CardsBenefitsMobile = () => {
  const useStyles = (theme) => ({
    container: {
      display: "flex",
      flexDirection: { xs: "column" },
      alignItems: { xs: "center" },
      height: { xs: "auto" },
    },
    firstCard: {
      display: "flex",
      textAlign: "center",
      flexDirection: { xs: "column" },
      alignItems: { xs: "center" },
      color: theme.palette.primary.main,
      height: { xs: "260px" },
      width: { xs: "299px" },
      borderBottom: "1px dashed rgba(171, 171, 171, 0.6)",
      mt: { xs: "46px" },
    },
    subtitle: {
      mt: "10px",
      fontFamily: theme.typography.Anaheim,
    },
  });

  const theme = useTheme();
  const classes = useStyles(theme);

  const { pathname } = useLocation();
  const componentName = "CardsBenefitsMobile";
  const translation = useTranslation(pathname, componentName);

  const { cards } = translation;

  return (
    <Box sx={classes.container}>
      {cards.map((card, i) => (
        <Box sx={classes.firstCard} key={`${card.title}-${i}`}>
          {card.img && (
            <img
              src={card.img.src}
              alt={card.img.alt}
              //style={stylesHelper() && stylesHelper()[`${number}Icon`]}
            />
          )}
          <Typography variant="h5" fontWeight={"bold"}>
            {" "}
            {card.title}
          </Typography>
          <Typography variant="h5" sx={classes.subtitle}>
            {card.subtitle}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default CardsBenefitsMobile;
