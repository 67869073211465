import React from "react";

import { Container, ApplyButton, ApplyButtonText, Divider } from "./styles";

import Language from "../LanguageMenu";

const Actions = ({ isMobile, sx }) => {
  return (
    <Container sx={sx}>
      <Divider />
      <a
        href="mailto:contacto@plataforma5.la"
        rel="noopener noreferrer"
        target="_blank"
      >
        <ApplyButton variant="primary">
          <ApplyButtonText>CONTACTO</ApplyButtonText>
        </ApplyButton>
      </a>
      <Divider />
      <Language isMobile={isMobile} />
    </Container>
  );
};

export default Actions;
