import React from "react";
//material ui
import { useMediaQuery, Box } from "@mui/material";

//components
import CardsBenefitsMobile from "./CardsBenefitsMobile";
import CardsBenefitsDesktop from "./CardsBenefitsDesktop";

import useTranslation from "../../../hooks/useTranslation";
import { useLocation } from "react-router-dom";

import { Title, Subtitle } from "./styles";

const CardsBenefits = () => {
  const { pathname } = useLocation();
  const isMobile = useMediaQuery("(max-width:899px)");
  const componentName = "BenefitsTitle";
  const translation = useTranslation(pathname, componentName);
  const { title, subtitle } = translation;

  return (
    <Box sx={{ marginTop: "6rem" }}>
      <Box>
        <Subtitle>{subtitle}</Subtitle>
        <Title>{title}</Title>
      </Box>
      {isMobile ? <CardsBenefitsMobile /> : <CardsBenefitsDesktop />}
    </Box>
  );
};

export default CardsBenefits;
