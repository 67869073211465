/** @format */

//img
import LogoMeli from "../img/companies/LogoMeli.svg";
import LogoItesa from "../img/companies/LogoItesa.webp";
import LogoOracle from "../img/companies/LogoOracle.webp";
import LogoBesedo from "../img/companies/LogoBesedo.webp";
import LogoCloudGaia from "../img/companies/LogoCloudGaia.webp";
import LogoGlobant from "../img/companies/LogoGlobant.webp";
import LogoDeloitte from "../img/companies/LogoDeloitte.webp";
import LogoFreebird from "../img/companies/LogoFreebird.webp";
import LogoJPMorgan from "../img/companies/LogoJP.svg";
import LogoTehnisys from "../img/companies/LogoTehnisys.webp";
import LogoMicrosoft from "../img/companies/LogoMicrosoft.webp";
import LogoAccenture from "../img/companies/LogoAccenture.webp";
import LogoCognizant from "../img/companies/LogoCognizant.webp";
import LogoSantander from "../img/companies/LogoSantander.webp";
import LogoUnderscope from "../img/companies/LogoUnderscope.webp";
import IBMLogo from "../img/companies/LogoIBM.svg";
import LogoUala from "../img/companies/logoUala.webp";
import LogoGlovo from "../img/companies/logoGlovo.webp";
import LogoPWC from "../img/companies/logoPwc.webp";
import LogoEtermax from "../img/companies/logoEtermax.webp";
import LogoCoca from "../img/companies/logoCoca.webp";
import LogoMural from "../img/companies/LogoMural.webp";
import LogoHendercross from "../img/companies/LogoHendercross.png";
import LogoEndava from "../img/companies/LogoEndava.jpg";

const companies = [
  {
    name: "microsoft",
    img: LogoMicrosoft,
  },
  {
    name: "meli",
    img: LogoMeli,
  },
  {
    name: "globant",
    img: LogoGlobant,
  },
  {
    name: "jp",
    img: LogoJPMorgan,
  },
  {
    name: "cloudgaia",
    img: LogoCloudGaia,
  },
  {
    name: "accenture",
    img: LogoAccenture,
  },
  {
    name: "uala",
    img: LogoUala,
  },
  {
    name: "coca-cola",
    img: LogoCoca,
  },
  {
    name: "mural",
    img: LogoMural,
  },
  {
    name: "etermax",
    img: LogoEtermax,
  },
  {
    name: "ibm",
    img: IBMLogo,
  },
  {
    name: "oracle",
    img: LogoOracle,
  },
  {
    name: "glovo",
    img: LogoGlovo,
  },
  {
    name: "cognizant",
    img: LogoCognizant,
  },
  {
    name: "pwc",
    img: LogoPWC,
  },
  {
    name: "santander",
    img: LogoSantander,
  },
  {
    name: "besedo",
    img: LogoBesedo,
  },
  {
    name: "freebird",
    img: LogoFreebird,
  },
  {
    name: "itesa",
    img: LogoItesa,
  },
  {
    name: "tehnisys",
    img: LogoTehnisys,
  },
  {
    name: "deloitte",
    img: LogoDeloitte,
  },
  {
    name: "underscope",
    img: LogoUnderscope,
  },
  {
    name: "hendercross",
    img: LogoHendercross,
  },
  {
    name: "endava",
    img: LogoEndava,
  },
];

export default companies;
