import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useTheme } from "@mui/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const useStyles = (theme) => ({
  button: {
    width: "227px",
    height: "64px",
    borderRadius: "100px",
    color: theme.palette.white.main,
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 550,
  },
  icon: {
    p: "5px",
    height: "14px",
    width: "14px",
    borderRadius: "58px",
    color: "#d00e2e",
    bgcolor: theme.palette.white.main,
    ml: "3px",
    boxShadow:
      "4px 8px 20px 0 rgba(0, 0, 0, 0.15), inset 7px 0 20px 0 rgba(255, 255, 255, 0.15)",
  },
});

const LandingButton = ({ background, color, pathname, text }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const navigate = useNavigate();

  const handleNavigate = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(pathname);
  };

  return (
    <Button
      sx={{ ...classes.button, background }}
      endIcon={<ArrowForwardIosIcon sx={{ ...classes.icon, color }} />}
      onClick={handleNavigate}
    >
      {text}
    </Button>
  );
};

export default LandingButton;
