export default {
  palette: {
    blue: {
      main: "#001f55",
      secondary: "#011434",
      tertiary: "#0e2246",
      light: "#d0eeff",
      lightblue: "#7ecefa",
      fourth: "#75bbe1",
      fifth: "#cbedff",
      sixth: "#8fc8e7",
      seventh: "#99CDEA",
      eighth: "#2894CF",
      ninth: "#0F9EED",
      tenth: "#0F9EED80",
    },
    red: {
      main: "#ff012b",
    },
    white: {
      main: "#ffffff",
      secondary: "#f4f4f4",
    },
    green: {
      main: "#00edd5",
      secondary: "#92eee5",
    },
    yellow: {
      main: "#EDA135",
      secondary: "#fbbf30",
      third: "#E39B2E",
    },
    grey: {
      main: "#c4c4c4",
      secondary: "#b6b6b6",
      tertiary: "#606060",
      fourth: "#eff1f4",
    },
  },
  typography: {
    montserrat: {
      light: {
        fontFamily: "Montserrat, sans-serif",
        fontWeight: "100",
      },

      regular: {
        fontFamily: "Montserrat, sans-serif",
        fontWeight: "400",
      },
      semibold: {
        fontFamily: "Montserrat, sans-serif",
        fontWeight: "600",
      },
      bold: {
        fontFamily: "Montserrat, sans-serif",
        fontWeight: "700",
      },
    },
    sourceSansPro: {
      semibold: {
        fontFamily: "Source Sans Pro, sans-serif",
        fontWeight: "600",
      },
    },
    anaHeim: {
      regular: {
        fontFamily: "Anaheim, sans-serif",
        fontWeight: "400",
      },
    },
    inter: {
      medium: {
        fontFamily: "Inter, sans-serif",
        fontWeight: "500",
      },
    },
  },
};
