import React from "react";
import Motivation from "../components/AboutUs/Motivation";
import Hero from "../commons/Hero";
import OverlapText from "../commons/OverlapText";
import { Box } from "@mui/material";

const useStyles = () => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
});

const AboutUs = () => {
  const classes = useStyles();

  return (
    <Box sx={classes.container}>
      <Hero />
      <OverlapText componentName="AboutUsText" />
      <Motivation />
    </Box>
  );
};

export default AboutUs;
