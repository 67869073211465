import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import StarRating from "../StarRating/StarRating";

const useStyles = (theme, isMobile) => ({
  container: {
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    minWidth: { md: "205px", lg: "249px" },
    lineHeight: "15px",
  },
  child: { display: "flex" },
  ratingContainer: { ml: "8.7px" },
  text: {
    color: theme.palette.white.main,
    fontSize: { xs: "10px", sm: "11px", md: "12px", lg: "15px", xl: "17px" },
    whiteSpace: "nowrap",
  },
  img: {
    width: isMobile ? "80px" : "108px",
    height: isMobile ? "66px" : "82px",
  },
});

const CodingPlatformRatings = ({
  img,
  mainText,
  rankingText,
  ranking,
  mb,
  mr,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const classes = useStyles(theme, isMobile, mr, mb);

  return (
    <Box sx={classes.container}>
      <Box sx={classes.child}>
        <img style={classes.img} src={img} alt={`${mainText}`} />
        <Box sx={classes.ratingContainer}>
          <StarRating />
          <Typography fontWeight={"bold"} sx={classes.text}>
            {mainText}
          </Typography>
          <Typography variant="h6" fontWeight={"medium"} sx={classes.text}>
            {rankingText}
          </Typography>
          <Typography fontWeight={"bold"} sx={classes.text}>
            {ranking}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CodingPlatformRatings;
