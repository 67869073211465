import React, { createContext, useCallback, useEffect, useState } from "react";
import instance from "../services/axiosConfig";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => localStorage.getItem("lang"));

  const setLanguageBasedOnIpLocation = useCallback(async () => {
    try {
      const {
        data: { country },
      } = await instance({
        method: "GET",
        url: "/location",
      });
      setLanguage(country === "Argentina" ? "es" : "en");
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (!language) {
      return setLanguageBasedOnIpLocation();
    }
    localStorage.setItem("lang", language);
  }, [language, setLanguageBasedOnIpLocation]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
