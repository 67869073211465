import React, { useEffect, useRef, useState } from "react";
import { Routes, Route, useSearchParams } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Navbar from "./components/Navbar";
import FrequentQuestions from "./pages/FrequentQuestions";
import IntroductoryCourse from "./pages/IntroductoryCourse";
import AppTheme from "./styles/theme";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translations/en";
import es from "./translations/es";
import Footer from "./components/Footer/Footer";
import GoTopButton from "./components/Buttons/GoTopButton";
import { Box, keyframes, useMediaQuery } from "@mui/material";
import Bootcamp from "./pages/Bootcamp";
import NotFound from "./pages/404Page";
import Campaign from "./pages/Campaign";

i18n.use(initReactI18next).init({
  resources: {
    es: {
      translation: es,
    },
    en: {
      translation: en,
    },
  },
  lng: localStorage.getItem("lang") ? localStorage.getItem("lang") : "es",
  fallbackLng: "es",

  interpolation: {
    escapeValue: false,
  },
});

const showUp = keyframes`
from {
  opacity: 0;
} to {
  opacity: 1;
}
`;

const useStyles = () => ({
  container: {
    maxHeight: "100vh",
    overflowY: "scroll",
    position: "relative",
    "&::-webkit-scrollbar": {
      width: { xs: 0, md: "16px", lg: "16px", xl: "16px" },
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f4f4f4",
    },
    "&::-webkit-scrollbar-track:hover": {
      backgroundColor: "#f4f4f4",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#babac0",
      borderRadius: "16px",
      border: "5px solid #fff",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#a0a0a5",
      border: "4px solid #f4f4f4",
    },
    "&::-webkit-scrollbar-button": { display: "none" },
  },
  goTopButton: {
    animation: `${showUp} 0.25s ease-in-out forwards`,
    position: "fixed",
    top: "32px",
    pr: { md: "16px", lg: "16px", xl: "16px" },
    right: "0px",
    zIndex: 100,
  },
});

const App = () => {
  const containerRef = useRef(null);
  const programRef = useRef(null);
  const pricesRef = useRef(null);
  const programmingPathRef = useRef(null);
  const [headerIsVisible, setHeaderIsVisible] = useState(true);
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [searchParams] = useSearchParams();
  const lsUtmSource = localStorage.getItem("utm_source");

  useEffect(() => {
    const utmSource =
      searchParams.get("utm_source") ||
      (searchParams.get("gclid") && "Google") ||
      (searchParams.get("fbclid") && "Facebook") ||
      "Orgánico";
    if (!lsUtmSource) {
      localStorage.setItem("utm_source", utmSource);
    }
  }, [lsUtmSource, searchParams]);

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;

      const handleScroll = () =>
        setHeaderIsVisible(
          isMobile ? container.scrollTop <= 64 : container.scrollTop <= 128
        );

      if (container) {
        container.addEventListener("scroll", handleScroll);
      }

      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [isMobile]);

  return (
    <AppTheme>
      <Box sx={classes.container} ref={containerRef}>
        <Navbar programRef={programRef} pricesRef={pricesRef} />
        {!headerIsVisible && (
          <GoTopButton
            sx={classes.goTopButton}
            onClick={() =>
              containerRef.current.scroll({ top: 0, behavior: "smooth" })
            }
          />
        )}
        <Routes>
          <Route
            exact
            path="/"
            element={<Home programmingPathRef={programmingPathRef} />}
          />
          <Route exact path="/campaign/lorem-ipsum" element={<Campaign />} />
          <Route
            path="/bootcamp/:type"
            element={<Bootcamp programRef={programRef} pricesRef={pricesRef} />}
          />
          <Route exact path="/intro" element={<IntroductoryCourse />} />
          <Route exact path="/faqs" element={<FrequentQuestions />} />
          <Route exact path="/nosotros" element={<AboutUs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Box>
    </AppTheme>
  );
};

export default App;
