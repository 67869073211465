import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import CodeBackground from "../../../img/P5Commitment/codeBackground.svg";

export const ContainerBox = styled(Box)(({ theme }) => ({
  paddingTop: "46px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "0.6rem",

  [theme.breakpoints.up("xs")]: {
    paddingTop: "50px",
    marginBottom: "50px",
  },

  [theme.breakpoints.up("md")]: {
    backgroundImage: `url(${CodeBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 570px",
    backgroundPosition: "-20px -100px",
    paddingTop: "80px",
    marginBottom: "40px",
  },
}));

export const TitleContainer = styled(Box)({
  marginTop: "4rem",
});

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
  textAlign: "center",
  fontSize: "25px",
  [theme.breakpoints.up("sm")]: {
    fontSize: "32px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "40px",
  },
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.lightBlue,
  fontFamily: theme.typography.Anaheim.fontFamily,
  fontWeight: 400,
  textAlign: "center",
  lineHeight: "30px",
  fontSize: "20px",
  [theme.breakpoints.up("sm")]: {
    fontSize: "22px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "26px",
  },
}));
