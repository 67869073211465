import React, { useState } from "react";

//material ui
import { useTheme } from "@mui/styles";
import { Button, Typography, useMediaQuery } from "@mui/material";

//icons
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const useStyles = (theme, isMobile) => ({
  button: {
    height: "fit-content",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: isMobile ? "10px" : "10px 25px",
    width: isMobile
      ? { xs: "276px", sm: "400px" }
      : { xl: "1375px", lg: "1075px", md: "860px" },
    bgcolor: theme.palette.white.main,
    borderRadius: isMobile ? "14px" : "56px",
    boxShadow: "0 1px 12px 0 rgba(0, 0, 0, 0.1)",
    textAlign: "left",
    "&:focus": {
      bgcolor: theme.palette.white.main,
    },
    textTransform: "none",
  },
  icon: {
    height: "23px",
    color: theme.palette.blue.main,
  },
  typography: {
    question: {
      fontFamily: theme.typography.montserrat.medium,
      color: theme.palette.blue.main,
      fontSize: "16px",
    },
  },
});

const ApplyButton = ({ question }) => {
  const isMobile = useMediaQuery("(max-width:899px)");

  const theme = useTheme();
  const classes = useStyles(theme, isMobile);

  const [up, setUp] = useState(true);

  const changeArrow = () => {
    setUp(!up);
  };

  return (
    <Button
      onClick={changeArrow}
      sx={classes.button}
      component={"span"}
      endIcon={
        up ? (
          <KeyboardArrowRightIcon sx={classes.icon} />
        ) : (
          <KeyboardArrowDownIcon sx={classes.icon} />
        )
      }
    >
      <Typography sx={classes.typography.question} component={"span"}>
        {question}
      </Typography>
    </Button>
  );
};

export default ApplyButton;
