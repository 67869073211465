import React from "react";

import {
  MenuOption,
  ButtonOption,
  TextOption,
  ArrowIconOption,
} from "./styles";

import selectArrow from "../../../../img/Icons/select-arrow.svg";

const Dropdown = ({
  options = [],
  handleClose,
  label,
  sx,
  children,
  handleNavigate,
}) => {
  const SubOption = ({ label, href }) => (
    <ButtonOption
      onClick={() => {
        handleClose();
        handleNavigate(href);
      }}
    >
      <ArrowIconOption src={selectArrow} alt="flecha de selección" suboption />
      <TextOption> {label}</TextOption>
    </ButtonOption>
  );
  return (
    <MenuOption sx={sx}>
      {options.map(({ label: optionLabel, options: subOptions = [], href }) => (
        <>
          <ButtonOption
            onClick={() => {
              handleClose();
              handleNavigate(href);
            }}
            key={`${label}-menu-${optionLabel}-option`}
            disableRipple
          >
            <TextOption fontWeight="bold">{optionLabel}</TextOption>
            {!!subOptions.length && (
              <ArrowIconOption src={selectArrow} alt="flecha de selección" />
            )}
          </ButtonOption>
          {subOptions.map((subOption) => (
            <SubOption
              key={`${label}-menu-${optionLabel}-option-${subOption.label}}`}
              {...subOption}
            />
          ))}
        </>
      ))}
      {children}
    </MenuOption>
  );
};

export default Dropdown;
