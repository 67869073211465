/** @format */
import LogoFacebook from "../img/Footer/LogoFacebook.svg";
import LogoLinkedin from "../img/Footer/LogoLinkedin.svg";
import LogoInsta from "../img/Footer/LogoInsta.svg";
import LogoTwitch from "../img/Footer/LogoTwitch.svg";
import LogoYoutube from "../img/Footer/LogoYoutube.svg";

const socialPlatforms = [
  {
    img: LogoFacebook,
    alt: "Facebook Logo",
    title: "Facebook",
    link: "https://www.facebook.com/PlataformaCinco/",
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    img: LogoInsta,
    alt: "Intagram Logo",
    title: "Instagram",
    link: "https://www.instagram.com/plataforma5/?hl=es",
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    img: LogoLinkedin,
    alt: "LinkedIn Logo",
    title: "LinkedIn",
    link: "https://www.linkedin.com/school/plataforma5/",
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    img: LogoYoutube,
    alt: "YouTube Logo",
    title: "YouTube",
    link: "https://www.youtube.com/c/Plataforma5",
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    img: LogoTwitch,
    alt: "Twitch Logo",
    title: "Twitch",
    link: "https://www.twitch.tv/plataforma5la?lang=es",
    rel: "noopener noreferrer",
    target: "_blank",
  },
];

export default socialPlatforms;
