import React, { useEffect } from "react";
import HomeBlueBackground from "../img/HomeBlueBackground.png";
import HomeBlueBackgroundDesktop from "../img/HomeBlueBackgroundDesktop.png";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import GetToKnowUs from "../components/Home/GetToKnowUs";
import Testimonials from "../components/Home/Testimonials";
import P5Commitment from "../components/Home/P5Commitment";
import Hero from "../commons/Hero";
import { useLocation } from "react-router-dom";
import useTranslation from "../hooks/useTranslation";
import HomeReviews from "../components/HomeReviews";
import CodeBlock from "../components/Home/CodeBlock";

const useStyles = (theme) => ({
  div: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    overflow: "hidden",
  },
  box: {
    height: { xs: "633px", md: "800px" },
    width: "100vw",
    backgroundImage: {
      xs: `url(${HomeBlueBackground})`,
      md: `url(${HomeBlueBackgroundDesktop})`,
    },
    borderRadius: { xs: "0 0 40px 40px", md: "inherit" },
    backgroundRepeat: "no-repeat",
    backgroundSize: { xs: "cover", md: "100% 100%" },
    display: "flex",
    justifyContent: { xs: "center", md: "flex-start" },
    alignItems: "center",
    flexDirection: "column",
    color: theme.palette.white.main,
  },
  homeBox: {
    display: { xs: "flex" },
    justifyContent: { xs: "center" },
    alignItems: { xs: "center", md: "flex-start" },
    flexDirection: { xs: "column" },
    width: { md: "850px", lg: "1100px", xl: "1300px" },
  },
});

const Home = ({ programmingPathRef }) => {
  const isMobile = useMediaQuery("(max-width:899px)");
  const theme = useTheme();
  const classes = useStyles(theme, isMobile);
  const location = useLocation();
  const { pathname } = location;
  const { ratings } = useTranslation(pathname, "Home");
  useEffect(() => {
    if (pathname === "/") {
      setTimeout(() => {
        if (location.hash) {
          const ref =
            location.hash.substring(1) === "programmingPath"
              ? programmingPathRef
              : null;
          if (ref?.current) {
            ref.current.scrollIntoView({
              block: "start",
              behavior: "smooth",
            });
          }
        }
      }, 50);
    }
  }, [location.hash]);
  return (
    <div>
      <Box sx={classes.div}>
        <Hero imageOverlap SecondaryComponent={CodeBlock} />
        <P5Commitment />
        <HomeReviews ratings={ratings} />
        <Testimonials />
        <GetToKnowUs />
      </Box>
    </div>
  );
};

export default Home;
