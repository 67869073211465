import React, { useState } from "react";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import styled from "@emotion/styled";
import { Link as MuiLink } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Link = ({ to, href, target, rel, children, sx }) => {
  const [isHovered, setIsHovered] = useState(false);

  const NavigateIcon = styled(NavigateNextRoundedIcon)(({ theme }) => ({
    transition: "all 0.3s ease-in-out",
    backgroundColor: "white",
    color: isHovered
      ? theme.palette.secondary.hover
      : theme.palette.secondary.main,
    borderRadius: "50%",
    fontSize: "22px",
  }));

  return (
    <MuiLink
      component={to ? RouterLink : undefined}
      variant="secondary"
      underline="none"
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      href={href}
      sx={sx}
      to={to}
      target={target}
      rel={rel}
    >
      {children}
      <NavigateIcon />
    </MuiLink>
  );
};

export default Link;
