import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

//material ui
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";

// components
import AccordionButton from "./AccordionButton";
import { Link } from "react-router-dom";
import TranslationText from "../Translations/TranslationText";

const useStyles = (theme, isMobile) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor:
      "linear-gradient(to top, #f4f4f4 0%, rgba(244, 244, 244, 0) 100%)",
  },
  section: {
    backgroundColor: "inherit",
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
    textAlign: "left",
    marginTop: "20px",
    "&:before": {
      display: "none",
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "56px",
    backgroundColor: theme.palette.white.main,
    width: isMobile ? "230px" : "90%",
    boxShadow: "0 1px 12px 0 rgba(0, 0, 0, 0.1)",
    margin: "auto",
  },
  textContainer: {
    fontFamily: theme.typography.anaHeim.regular,
    color: theme.palette.blue.main,
    fontSize: { xs: "16px", xl: "22px" },
    width: {
      xs: "260px",
      sm: "340px",
      md: "650px",
      lg: "950px",
      xl: "1200px",
    },
    textAlign: "left",
    marginTop: isMobile ? "10px" : "15px",
    fontWeight: "400",
  },
  typography: {
    question: {
      fontFamily: theme.typography.montserrat.medium,
      color: theme.palette.blue.main,
      fontSize: "16px",
      width: isMobile
        ? "185px"
        : { xl: "1413px", lg: "1100px", md: "900px", sm: "900px" },
      marginLeft: "63px",
    },
    info: {
      fontFamily: theme.typography.anaHeim.regular,
      color: theme.palette.blue.main,
      fontSize: { xs: "16px", xl: "22px" },
      width: {
        xs: "260px",
        sm: "340px",
        md: "650px",
        lg: "950px",
        xl: "1200px",
      },
      textAlign: "left",
      marginTop: isMobile ? "10px" : "15px",
      fontWeight: "400",
    },
    infoBold: {
      fontFamily: theme.typography.anaHeim.regular,
      color: theme.palette.blue.main,
      fontSize: { xs: "16px", xl: "22px" },
      width: {
        xs: "260px",
        sm: "340px",
        md: "650px",
        lg: "950px",
        xl: "1200px",
      },
      textAlign: "left",
      marginTop: isMobile ? "10px" : "15px",
      fontWeight: "600",
    },
    infoItalic: {
      fontFamily: theme.typography.anaHeim.regular,
      color: theme.palette.blue.main,
      fontSize: { xs: "16px", xl: "22px" },
      width: {
        xs: "260px",
        sm: "340px",
        md: "650px",
        lg: "950px",
        xl: "1200px",
      },
      textAlign: "left",
      marginTop: isMobile ? "10px" : "15px",
      fontWeight: "400",
      fontStyle: "italic",
    },
  },
});

export default function SimpleAccordion({ questions }) {
  const isMobile = useMediaQuery("(max-width:899px)");

  const theme = useTheme();
  const classes = useStyles(theme, isMobile);
  return (
    <Box sx={classes.container} component={"span"}>
      {questions.map((question) => {
        return (
          <Accordion key={question.question} sx={classes.section}>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <AccordionButton question={question.question} />
            </AccordionSummary>
            <AccordionDetails component={"span"}>
              {question.text.map((text) => {
                return (
                  <TranslationText
                    sx={classes.textContainer}
                    textSx={{
                      normal: classes.typography.info,
                      bold: classes.typography.infoBold,
                      italic: classes.typography.infoItalic,
                    }}
                    text={text}
                    key={text}
                  >
                    {question.link && text === question.text.at(-1) && (
                      <Link to={question.link.url}>{question.link.text}</Link>
                    )}
                  </TranslationText>
                );
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}
