import styled from "@emotion/styled";

import { Typography } from "@mui/material";

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
  textAlign: "center",
  fontSize: "25px",
  [theme.breakpoints.up("sm")]: {
    fontSize: "32px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "40px",
  },
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.lightBlue,
  fontFamily: theme.typography.Anaheim.fontFamily,
  fontWeight: 400,
  textAlign: "center",
  lineHeight: "30px",
  fontSize: "20px",
  [theme.breakpoints.up("sm")]: {
    fontSize: "22px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "26px",
  },
}));
