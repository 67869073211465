import styled from "@emotion/styled";

import { Box, Button, Divider as DividerMui } from "@mui/material";

export const Container = styled(Box)({
  display: "flex",
  gap: "17px",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "1rem",

  "@media (min-width:960px)": {
    flexDirection: "row",
    margin: 0,
  },
});

export const ApplyButton = styled(Button)({
  padding: "10px 20px !important",
  height: "40px",
  width: "144px",
});

export const ApplyButtonText = styled("span")({
  marginTop: "1px",
});

export const Divider = styled(DividerMui)({
  width: "80%",
  display: "block",
  "@media (min-width:960px)": {
    display: "none",
  },
});
