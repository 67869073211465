import { Box, IconButton, keyframes } from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useState } from "react";
import TranslationText from "../Translations/TranslationText";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const showUp = keyframes`
  from {
  height: 0;
  font-size: 0;
  padding: 0;
  } to {
  height: auto;
  font-size: inherit;
  padding: 16px,
  }
  `;

const hide = keyframes`
  from {
  height: auto;
  font-size: inherit;
  padding: 16px;
  } to {
  height: 0;
  font-size: 0;
  padding: 0;
  }
  `;

const useStyles = (theme, isExpanded) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "24px 36px 16px 36px",
    borderRadius: "30px",
    background: "linear-gradient(180deg, #F4F4F4 0%, #FFFFFF 100%)",
    boxShadow: "5px 5px 20px rgba(79, 79, 79, 0.2)",
  },
  titleContainer: {
    mb: "24px",
  },
  title: {
    container: {
      mr: "16px",
    },
    bold: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "25px",
      color: theme.palette.primary.main,
    },
  },
  subtitle: {
    normal: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "25px",
      color: theme.palette.primary.main,
    },
  },
  description: {
    container: {},
    normal: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "20px",
      color: theme.palette.primary.main,
    },
    bold: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "20px",
      color: theme.palette.primary.main,
    },
  },
  content: {
    padding: isExpanded ? "16px" : 0,
    margin: "0 0 16px 0",
    borderTop: `${isExpanded ? "1px" : "0.5px"} solid rgba(171, 171, 171, 0.6)`,
    borderBottom: `${
      isExpanded ? "1px" : "0.5px"
    } solid rgba(171, 171, 171, 0.6)`,
  },
  expanded: {
    background: "rgba(28, 122, 217, 0.1)",
    outline: `5px solid ${theme.palette.background.lightGray}}`,
  },
  iconButton: {
    alignSelf: "center",
  },
  tabIcon: { color: theme.palette.lightBlue, fontSize: "24px" },
  showUp: {
    animation: `${showUp} 0.25s ease-in-out`,
  },
  hide: {
    animation: `${hide} 0.25s ease-in-out`,
  },
});

const CollapsibleBox = ({
  title,
  subtitle,
  description,
  initiallyExpanded,
  sx,
}) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(initiallyExpanded);
  const classes = useStyles(theme, isExpanded);

  return (
    <Box
      sx={{ ...classes.container, ...sx, ...(isExpanded && classes.expanded) }}
    >
      <Box sx={classes.titleContainer}>
        <TranslationText textSx={{ bold: classes.title.bold }}>
          {title}
        </TranslationText>
        <TranslationText textSx={{ normal: classes.subtitle.normal }}>
          {subtitle}
        </TranslationText>
      </Box>
      <Box
        sx={{
          ...classes.content,
          ...(isExpanded ? classes.showUp : classes.hide),
        }}
      >
        {isExpanded &&
          (description?.mobile ? (
            <TranslationText
              textSx={{
                normal: classes.description.normal,
                bold: classes.description.bold,
              }}
              sx={classes.description.container}
            >
              {description.mobile}
            </TranslationText>
          ) : (
            description
          ))}
      </Box>
      <IconButton
        sx={classes.iconButton}
        onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
      >
        {isExpanded ? (
          <RemoveIcon sx={classes.tabIcon} />
        ) : (
          <AddIcon sx={classes.tabIcon} />
        )}
      </IconButton>
    </Box>
  );
};

export default CollapsibleBox;
