import { Box, IconButton, SvgIcon, useMediaQuery } from "@mui/material";
import React from "react";

const useStyles = () => ({
  container: {
    minWidth: { sm: "58px", md: "100px", lg: "100px", xl: "100px" },
    minHeight: { sm: "58px", md: "81px", lg: "81px", xl: "81px" },
    p: { xs: "13px", md: "0px", lg: "0px", xl: "0px" },
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "20px",
    boxShadow: "5px 8px 15px rgba(0, 0, 0, 0.25);",
    borderRadius: "20px 0px 0px 20px",
    display: "flex",
    flexDirection: "column",
    color: "#001F55",
    lineHeight: "180%",
    backgroundColor: "#F5F5F5",
    clipPath: {
      md: "inset(-32px 16px -32px -32px)",
      lg: "inset(-32px 16px -32px -32px)",
      xl: "inset(-32px 16px -32px -32px)",
    },
    "&:hover": {
      backgroundColor: "#f0eeee",
    },
  },
  box: {
    minWidth: "32px",
    minHeight: "32px",
  },
  iconContainer: {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    boxShadow:
      "5px 8px 20px rgba(0, 0, 0, 0.15), inset 7px 0px 20px rgba(255, 255, 255, 0.15);",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  arrowIcon: {
    width: "24px",
    height: "24px",
  },
});

const GoTopButton = ({ sx, onClick }) => {
  const { container, iconContainer, arrowIcon } = useStyles();
  const isMobile = useMediaQuery("(max-width:900px)");

  return (
    <IconButton
      sx={{ ...container, ...sx }}
      onClick={onClick}
      aria-label="Ir arriba de todo"
    >
      <Box sx={iconContainer}>
        <SvgIcon
          sx={arrowIcon}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.9993 13.8297C18.9998 13.6803 18.9668 13.5327 18.9028 13.3977C18.8388 13.2627 18.7453 13.1438 18.6293 13.0497L12.6293 8.21968C12.4504 8.07259 12.2259 7.99219 11.9943 7.99219C11.7627 7.99219 11.5382 8.07259 11.3593 8.21968L5.3593 13.2197C5.15508 13.3894 5.02666 13.6333 5.00228 13.8978C4.9779 14.1622 5.05956 14.4255 5.2293 14.6297C5.39904 14.8339 5.64295 14.9623 5.90738 14.9867C6.1718 15.0111 6.43508 14.9294 6.6393 14.7597L11.9993 10.2897L17.3593 14.6097C17.5061 14.732 17.6848 14.8096 17.8744 14.8335C18.0639 14.8574 18.2563 14.8265 18.4289 14.7444C18.6014 14.6624 18.7468 14.5326 18.8479 14.3705C18.949 14.2084 19.0015 14.0207 18.9993 13.8297Z"
            fill="#001F55"
          />
        </SvgIcon>
      </Box>
      {isMobile ? "" : "Top"}
    </IconButton>
  );
};

export default GoTopButton;
