import React, { useRef, useEffect } from "react";
import { useTheme } from "@mui/styles";
import { useMediaQuery, Box, Typography } from "@mui/material";
import BootcampOptions from "./BootcampOptions";
import { useLocation } from "react-router-dom";
import useTranslation from "../../hooks/useTranslation";

const useStyles = (theme, grid) => ({
  container: {
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    pb: "119px",
    bgcolor: { xs: "rgba(244, 244, 244, 0.54)", md: theme.palette.white.main },
    backgroundImage: { md: `url(${grid.src})` },
    backgroundRepeat: "no-repeat",
  },
  child: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  titleContainer: {
    display: { md: "flex" },
    justifyContent: { md: "center" },
    alignItems: { md: "center" },
    mt: { md: "188px" },
  },
  title: {
    color: theme.palette.red.main,
    fontWeight: "600",
    letterSpacing: { xs: "1.4px", md: "1.6px" },
    fontSize: { xs: "14px", md: "16px" },
    mt: { xs: "70px", md: 0 },
  },
  subtitle: {
    fontFamily: theme.typography.montserrat.light,
    color: theme.palette.blue.main,
    fontSize: { xs: "26px", md: "54px", lg: "65px" },
    lineHeight: { xs: "36px", md: "63px", lg: "79px" },
    textAlign: "center",
    maxWidth: { xs: "281px", md: "646px" },
    mt: { xs: "21px", md: "35px" },
    mb: { xs: "50px", md: "81px" },
  },
  span: { fontWeight: "bold" },
  greenCircle: {
    width: "7.5%",
    height: "65px",
    border: `solid 6.5px ${theme.palette.green.main}`,
    borderRadius: "0 35px 35px 0",
    borderLeft: theme.palette.white.main,
  },
  greenCircleContainer: { width: "100vw" },
  optionsContainer: {
    mb: "82px",
  },
  line: {
    width: "25px",
    border: `1px solid ${theme.palette.red.main}`,
    marginRight: "9px",
    height: 0,
    backgroundColor: theme.palette.red.main,
  },
  bootcampOptionsBox: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: { xs: "center" },
    justifyContent: { md: "space-evenly" },
    width: "85%",
  },
});

const TakeTheLeap = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:899px)");
  const focusElement = useRef(null);
  const { pathname } = useLocation();
  const { title, subtitle, jsbc, grid } = useTranslation(
    pathname,
    "TakeTheLeap"
  );
  const classes = useStyles(theme, grid);

  const focusOnElement = () => {
    focusElement.current.scrollIntoView();
  };

  useEffect(() => {
    if (focusElement) {
      focusOnElement();
    }
  }, []);

  return (
    <Box sx={classes.container}>
      <Box sx={classes.child}>
        <Box sx={classes.titleContainer} ref={focusElement}>
          {isMobile ? null : <div style={classes.line}></div>}
          <Typography sx={classes.title}>{title}</Typography>
        </Box>

        <Typography sx={classes.subtitle}>
          <span style={classes.span}>{subtitle}</span>
        </Typography>
      </Box>

      <Box sx={classes.bootcampOptionsBox}>
        {isMobile && (
          <Box sx={classes.greenCircleContainer}>
            <Box sx={classes.greenCircle}></Box>
          </Box>
        )}

        <Box>
          <BootcampOptions
            title={jsbc.title}
            subtitle={jsbc.subtitle}
            img={jsbc.icon.src}
            button={true}
            background={jsbc.card.src}
            redirect="/bootcamp/javascript"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TakeTheLeap;
