import React from "react";
import Slider from "react-slick";
import { useTheme } from "@mui/styles";
import { useMediaQuery } from "@mui/material";
import RightArrow from "../../img/RightArrow.svg";
import LeftArrow from "../../img/LeftArrow.svg";
import { ArrowContainer, ArrowImage, Container } from "./styles";

const useStyles = (_theme, _isMobile) => ({
  slider: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

function Arrow(props) {
  let className = props.type === "next" ? "nextArrow" : "prevArrow";
  className += " arrow";

  const char =
    props.type === "next" ? (
      <ArrowImage src={RightArrow} alt="flecha" />
    ) : (
      <ArrowImage alt="flecha" src={LeftArrow} />
    );

  return (
    <ArrowContainer
      className={className}
      onClick={props.onClick}
      onKeyDown={(e) => e.key === "Enter" && props.onClick()}
      role="button"
      tabIndex={0}
    >
      {char}
    </ArrowContainer>
  );
}

const Carrousel = ({ children, ...settings }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:899px)");
  const classes = useStyles(theme, isMobile);

  const defaultSettings = {
    autoplay: false,
    autoplaySpeed: 4000,
    swipeToSlide: true,
    swipe: true,
    infinite: true,
    nextArrow: <Arrow type="next" />,
    prevArrow: <Arrow type="prev" />,
    style: classes.slider,
    dots: true,
  };

  return (
    <Container style={classes.container}>
      <Slider {...defaultSettings} {...(settings || {})}>
        {children}
      </Slider>
    </Container>
  );
};

export default Carrousel;
