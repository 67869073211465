import styled from "@emotion/styled";

import { Stack, Box } from "@mui/material";

export const Container = styled(Box)({
  width: "100%",
  position: "relative",
});

export const CarrouselItem = styled(Box)`
  overflow: hidden;
  background: transparent;
  padding: 60px 0 40px 0;
  display: flex !important;
  justify-content: center;
`;

export const CardsContainer = styled(Stack)(({ theme }) => ({
  margin: "4rem 0",
  gap: 10,
  flexDirection: "row",

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "5rem",
  },

  [theme.breakpoints.up("lg")]: {
    gap: 50,
  },
}));

export const Background = styled("img")({
  position: "absolute",
  width: "100%",
  top: 0,
  left: 0,
});

export const TestimonialsContainer = styled(Box)({
  marginTop: "4rem",
  position: "relative",
  display: "flex",
  justifyContent: "center",
});
