import styled from "@emotion/styled";

import {
  Typography,
  Container as MuiContainer,
  Card,
  Box,
} from "@mui/material";

export const ActivitiesContainer = styled(Card)({
  padding: "0 !important",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: "#EBEBEB",
});

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
  textAlign: "center",
  fontSize: "25px",
  [theme.breakpoints.up("sm")]: {
    fontSize: "32px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "40px",
  },
}));

export const NoteContainer = styled(Box)({
  width: "100%",
  display: "grid",
  justifyContent: "center",
  padding: "1.5rem 0",
  background: "rgb(255, 255, 255,0.7)",
});

export const Note = styled(Typography)({
  maxWidth: "600px",
  textAlign: "center",
  padding: "0 4rem",
  lineHeight: "20px",
});

export const Subtitle = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  lineHeight: "36px",
  fontSize: "16px",

  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.up("lg")]: {
    lineHeight: "36px",
    fontSize: "22px",
  },
}));

export const Container = styled(MuiContainer)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
  alignItems: "center",
  padding: "4rem 0",
  width: "100%",

  [theme.breakpoints.down("md")]: {
    width: "90%",
    maxWidth: "650px",
  },
}));
