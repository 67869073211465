import React from "react";

//img
import Benefits from "../../img/AboutUs/About us benefits.webp";
import BenefitsDesktop from "../../img/AboutUs/About us benefits desktop.webp";
import Compromise from "../../img/AboutUs/About us compromise.webp";
import Background1 from "../../img/AboutUs/About us background 1.svg";
import Background2 from "../../img/AboutUs/About us background 2.svg";
import json2mq from "json2mq";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import useTranslation from "../../hooks/useTranslation";
import { useLocation } from "react-router-dom";

const useStyles = (theme, isMobile) => ({
  container: {
    width: "100%",
    marginTop: "6rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
  },
  intro: {
    width: "100%",
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    justifyContent: isMobile ? "center" : "space-evenly",
    alignItems: isMobile ? "center" : "center",
  },
  info: {
    display: "flex",
    flexDirection: "column",
    alignItems: isMobile ? "center" : "flex-start",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  line: {
    width: "25px",
    border: `1px solid ${theme.palette.red.main}`,
    marginRight: "8px",
    backgroundColor: theme.palette.red.main,
  },
  sliderContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  slider: {
    width: "100%",
    height: "fit-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  span: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  arrow: {
    color: theme.palette.grey.main,
    fontSize: "30px",
    fontWeight: "bold",
  },
  typography: {
    section: {
      fontSize: isMobile ? "14px" : "16px",
      fontWeight: "bold",
      letterSpacing: "2px",
      color: theme.palette.red.main,
    },
    sectionDescription: {
      fontSize: { xl: "60px", md: "50px", sm: "36px", xs: "32px" },
      fontFamily: theme.typography.montserrat.bold,
      color: theme.palette.blue.main,
      marginTop: { sm: "16px", xs: "27px" },
    },
    info: {
      fontSize: { md: "22px", sm: "22px", xs: "18px" },
      fontFamily: theme.typography.montserrat.regular,
      width: { xs: "280px", sm: "350px", xl: "600px" },
      color: theme.palette.blue.main,
      marginTop: { md: "16px", xs: "27px" },
      textAlign: isMobile ? "center" : "left",
    },
  },
});

const Motivation = () => {
  const isMobile = useMediaQuery("(max-width:899px)");
  const component = "Motivation";
  const path = useLocation().pathname;
  const { sections } = useTranslation(path, component);
  const theme = useTheme();
  const classes = useStyles(theme, isMobile);

  const xs = useMediaQuery(
    json2mq({
      maxWidth: 700,
    })
  );
  const sm = useMediaQuery(
    json2mq({
      maxWidth: 899,
    })
  );
  const md = useMediaQuery(
    json2mq({
      maxWidth: 1100,
    })
  );
  const lg = useMediaQuery(
    json2mq({
      maxWidth: 1640,
    })
  );
  const xl = useMediaQuery(
    json2mq({
      minWidth: 1641,
    })
  );

  const stylesHelper = () => {
    if (xs) return { img: { width: "100%" } };
    if (sm) return { img: { width: "100%" } };
    if (md) return { img: { width: "40%" } };
    if (lg) return { img: { width: "40%" } };
    if (xl) return { img: { width: "666px" } };
  };

  return (
    <Box sx={classes.container}>
      <Box
        sx={{
          ...classes.intro,
          flexDirection: isMobile ? "column" : "row-reverse",
        }}
      >
        {isMobile ? (
          <img
            src={Benefits}
            alt=""
            style={stylesHelper() && stylesHelper().img}
          />
        ) : (
          <img
            src={BenefitsDesktop}
            alt=""
            style={stylesHelper() && stylesHelper().img}
          />
        )}
        {isMobile ? (
          <> </>
        ) : (
          <img
            src={Background1}
            alt=""
            style={{
              position: "absolute",
              zIndex: "-99",
              left: "0",
              width: "100%",
            }}
          />
        )}
        <Box style={classes.info}>
          {!isMobile ? (
            <Box sx={classes.titleContainer}>
              <div style={classes.line}></div>
              <Typography sx={classes.typography.section}>
                {sections[0].section}
              </Typography>
            </Box>
          ) : (
            <Typography sx={classes.typography.section}>
              {sections[0].section}
            </Typography>
          )}
          <Typography sx={classes.typography.sectionDescription}>
            {sections[0].sectionDescription}
          </Typography>
          <Typography
            sx={{
              ...classes.typography.info,
              width: { xs: "270px", sm: "340px", xl: "600px" },
            }}
          >
            {sections[0].info[0]}
          </Typography>
        </Box>
      </Box>
      {isMobile ? (
        <> </>
      ) : (
        <img
          src={Background2}
          alt=""
          style={{
            position: "absolute",
            zIndex: "-99",
            right: "0%",
            marginTop: "400px",
          }}
        />
      )}
      <Box sx={classes.intro}>
        <img
          src={Compromise}
          alt=""
          style={stylesHelper() && stylesHelper().img}
        />
        <Box style={classes.info}>
          {isMobile ? (
            <Typography sx={classes.typography.section}>
              {sections[1].section}
            </Typography>
          ) : (
            <Box sx={classes.titleContainer}>
              <div style={classes.line}></div>
              <Typography sx={classes.typography.section}>
                {sections[1].section}
              </Typography>
            </Box>
          )}

          <Typography sx={classes.typography.sectionDescription}>
            {sections[1].sectionDescription}
          </Typography>
          <Typography sx={classes.typography.info}>
            {sections[1].info[0].map((text, i) =>
              i % 2 ? <b key={text + i}>{text}</b> : text
            )}
          </Typography>
          <Typography sx={classes.typography.info}>
            {sections[1].info[1].map((text, i) =>
              i % 2 ? <b key={text + i}>{text}</b> : text
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Motivation;
