import React from "react";
import { useLocation } from "react-router-dom";
import useTranslation from "../../../hooks/useTranslation";
import {
  ContainerBox,
  TitleContainer,
  Title,
  Subtitle,
} from "../P5Commitment/styles";
import Carrousel from "../../../commons/Carrousel";
import companies from "../../../helpers/companies";
import { useMediaQuery } from "@mui/material";
import json2mq from "json2mq";

const P5Commitment = () => {
  const { pathname } = useLocation();
  const { title, subtitle } = useTranslation(pathname, "P5Commitment");

  const xs = useMediaQuery(
    json2mq({
      maxWidth: 899,
    })
  );
  const md = useMediaQuery(
    json2mq({
      maxWidth: 1200,
      minWidth: 900,
    })
  );
  const lg = useMediaQuery(
    json2mq({
      maxWidth: 1535,
      minWidth: 1201,
    })
  );
  const xl = useMediaQuery(
    json2mq({
      minWidth: 1536,
    })
  );

  const stylesHelper = () => {
    if (xs)
      return {
        img: {
          width: "7rem",
          height: "5rem",
          objectFit: "scale-down",
          margin: "auto",
        },
      };
    else if (lg || xl)
      return {
        img: {
          width: "8rem",
          height: "6rem",
          objectFit: "scale-down",
          margin: "auto",
        },
      };
    else if (md)
      return {
        img: {
          width: "7rem",
          height: "5rem",
          objectFit: "scale-down",
          margin: "auto",
        },
      };
  };

  return (
    <ContainerBox>
      <TitleContainer>
        <Title> {title} </Title>
        <Subtitle> {subtitle} </Subtitle>
      </TitleContainer>
      {stylesHelper() && (
        <Carrousel
          autoplay={false}
          autoplaySpeed={4000}
          swipeToSlide={true}
          slidesToShow={4}
          slidesToScroll={4}
          swipe={true}
          infinite={true}
          rows={2}
          dots={true}
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
            {
              breakpoint: 570,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
          ]}
          items
        >
          {companies.map((img, index) => {
            return (
              <div
                key={index}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  src={img.img}
                  alt={`compañia ${index}`}
                  style={stylesHelper().img}
                />
              </div>
            );
          })}
        </Carrousel>
      )}
    </ContainerBox>
  );
};

export default P5Commitment;
