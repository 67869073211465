import React from "react";
import TakeTheLeap from "../components/LandingBootcamp/TakeTheLeap";
import FounderTestimony from "../components/LandingBootcamp/FounderTestimony";
import LandingBootcampTitle from "../components/LandingBootcamp/LandingBootcampTitle";
import CodingPlatformRatings from "../components/CodingPlatformsRatings/CodingPlatformRatings";
import { useTheme } from "@mui/styles";
import { Box, useMediaQuery } from "@mui/material";
import HomeBlueBackground from "../img/HomeBlueBackground.png";
import LandingBootcampBackground from "../img/LandingBootcampBackground.png";
import Miscelanea01LandingBc from "../img/Miscelanea01LandingBc.png";
import { useLocation } from "react-router-dom";
import useTranslation from "../hooks/useTranslation";

const useStyles = (theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundImage: { md: `url(${Miscelanea01LandingBc})` },
    backgroundRepeat: "no-repeat",
  },
  box: {
    height: { xs: "560px", md: "664px" },
    width: "100vw",
    backgroundImage: {
      xs: `url(${HomeBlueBackground})`,
      md: `url(${LandingBootcampBackground})`,
    },
    borderRadius: { xs: "0 0 40px 40px", md: "inherit" },
    backgroundRepeat: "no-repeat",
    backgroundSize: { xs: "cover", md: "100% 100%" },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: theme.palette.white.main,
  },
  chooseYourBcBox: {
    display: { xs: "flex" },
    justifyContent: { xs: "flex-start", md: "center" },
    alignItems: "center",
    flexDirection: { xs: "column" },
    width: { md: "718px", lg: "1026px" },
    height: { xs: "100%" },
    mt: { xs: "83px" },
  },
  ratingBox: {
    display: { md: "flex" },
    mt: { md: "49px" },
    height: { xs: "148.3px", md: "83px" },
    maxWidth: { xs: "250.9px", md: "718px" },
    justifyContent: { md: "space-between" },
  },
  greenCircle: {
    width: "8%",
    height: "70px",
    border: "solid 6.5px #edfefc",
    borderRadius: "0 100px 100px 0",
    borderLeft: theme.palette.white.main,
    bgcolor: "#edfefc",
  },
  greenCircleContainer: {
    display: "flex",
    width: "100vw",
    bgcolor: "rgba(244, 244, 244, 0.54)",
    height: "150px",
  },
});

const LandingBootcamp = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const isMobile = useMediaQuery("(max-width:899px)");
  const { pathname } = useLocation();
  const { courseReport, switchUp } = useTranslation(
    pathname,
    "LandingBootcamp"
  );

  return (
    <div>
      <Box sx={classes.container}>
        <Box sx={classes.box}>
          <Box sx={classes.chooseYourBcBox}>
            <LandingBootcampTitle />
            <Box sx={classes.ratingBox}>
              <CodingPlatformRatings
                mainText={courseReport.title}
                rankingText={courseReport.rankingText}
                ranking={courseReport.ranking}
                img={courseReport.img.src}
                mb="32.1px"
                mr="86px"
              />

              <CodingPlatformRatings
                mainText={switchUp.title}
                rankingText={switchUp.rankingText}
                ranking={switchUp.ranking}
                img={switchUp.img.src}
              />
            </Box>
          </Box>
        </Box>

        <TakeTheLeap />

        <Box sx={classes.container}>
          <FounderTestimony />
        </Box>

        {isMobile && (
          <Box sx={classes.greenCircleContainer}>
            <Box sx={classes.greenCircle}></Box>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default LandingBootcamp;
