import { useTheme } from "@emotion/react";
import { Box, SvgIcon } from "@mui/material";
import React from "react";
import TranslationText from "../Translations/TranslationText";
import Link from "../../commons/Link";
import { useLocation } from "react-router-dom";
import useTranslation from "../../hooks/useTranslation";

const useStyles = () => ({
  container: {
    boxSizing: "border-box",
    width: "100%",
    p: {
      xl: "1% 10% 64px 10%",
      lg: "1% 10% 64px 10%",
      md: "1% 10% 64px 10%",
      sm: "1% 10% 64px 10%",
      xs: "1% 5% 64px 5%",
    },
  },
  title: {
    boxSizing: "border-box",
    container: {
      p: {
        xl: "104px 27% 24px 27%",
        lg: "104px 27% 24px 27%",
        md: "104px 15% 24px 15%",
        sm: "104px 10% 24px 10%",
        xs: "104px 5% 24px 5%",
      },
      borderRadius: "0px 0px 60px 60px",
      background: "rgba(28, 122, 217, 0.10)",
      mt: "-72px",
      zIndex: -1,
    },
  },
  subtitle: {
    container: {
      position: "relative",
      margin: "16px auto 0 auto",
      width: "max-content",
      borderTop: "1px dashed rgba(171, 171, 171, 0.60)",
      borderBottom: "1px dashed rgba(171, 171, 171, 0.60)",
      p: "6px 0",
      mb: "32px",
    },
    bold: {
      color: "#001F55",
      textAlign: "center",
      fontFamily: "Montserrat",
      fontSize: { xl: "25px", lg: "25px", md: "25px", sm: "22px", xs: "22px" },
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "30px",
      fontVariant: "all-small-caps",
    },
  },
  dotImg: {
    width: "10px",
    height: "10px",
    position: "absolute",
    top: "-5px",
    left: "calc(50% - 5px)",
    zIndex: 1,
  },
  clarification: {
    italic: {
      color: "#001F55",
      textAlign: "center",
      fontFamily: "Montserrat",
      fontSize: { xl: "16px", lg: "16px", md: "16px", sm: "14px", xs: "14px" },
      fontStyle: "italic",
      fontWeight: 400,
      lineHeight: "18px",
      fontVariant: "all-small-caps",
      letterSpacing: "0.05em",
      display: "block",
    },
  },
  description: {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "16px",
      mb: "44px",
      px: "10%",
    },
    normal: {
      color: "#001F55",
      textAlign: "center",
      fontFamily: "Montserrat",
      fontSize: { xl: "22px", lg: "22px", md: "22px", sm: "16px", xs: "16px" },
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "36px",
    },
    bold: {
      color: "#001F55",
      textAlign: "center",
      fontFamily: "Montserrat",
      fontSize: { xl: "22px", lg: "22px", md: "22px", sm: "16px", xs: "16px" },
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "36px",
    },
  },
});

const Introduction = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { pathname } = useLocation();
  const { clarification, link } = useTranslation(pathname, "Introduction");

  return (
    <Box sx={classes.container}>
      <TranslationText
        sx={classes.subtitle.container}
        textSx={classes.subtitle}
        text={clarification[0]}
      >
        <SvgIcon
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          sx={classes.dotImg}
        >
          <circle
            cx="5"
            cy="5"
            r="5"
            transform="rotate(90 5 5)"
            fill="#1C7AD9"
          />
        </SvgIcon>
        <TranslationText textSx={classes.clarification}>
          {clarification[1]}
        </TranslationText>
      </TranslationText>
      <Link href={link.href} target="_blank" rel="noreferrer">
        {link.text}
      </Link>
    </Box>
  );
};

export default Introduction;
