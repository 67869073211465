import React from "react";

import { Grid, useMediaQuery } from "@mui/material";

import Activity from "./Activity";

const Activities = ({ activities }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const renderActivities = () =>
    activities.map((activity, i) => (
      <Grid
        item
        xs={isMobile ? 12 : 6}
        key={`bootcamp-activity-${activity.name}-${
          isMobile ? "mobile" : "desktop"
        }-${i}`}
      >
        <Activity isTop={i == 0 || i == 1} {...activity} />
      </Grid>
    ));

  return (
    <Grid
      container
      columnSpacing={12}
      rowSpacing={4}
      padding={{ xs: "2rem 1.5rem !important", sm: "2rem 4rem !important" }}
      background="transparent"
    >
      {renderActivities()}
    </Grid>
  );
};

export default Activities;
