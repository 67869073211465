/** @format */

import React from "react";

//material ui
import { useTheme } from "@mui/styles";
import { Box, Typography } from "@mui/material";

import background from "../img/ErrorBackground.png";
import wire from "../img/imgError404.svg";
import LandingButton from "../commons/Button";

const useStyles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundRepeat: "no-repeat",
    backgroundSize: { xs: "cover", md: "100% 109%" },
    width: "100vw",
    backgroundImage: {
      xs: `url(${background})`,
    },
    minHeight: { xs: "611px" },
    alignItems: "center",
    justifyContent: "center",
  },
  msg: {
    color: theme.palette.white.main,
    fontSize: { xs: "25px", md: "30px", lg: "35px", xl: "45px" },
    fontFamily: theme.typography.montserrat.bold,
    mt: { xs: "80px" },
    textAlign: { xs: "center" },
    width: { xs: "300px", md: "660px", lg: "740px", xl: "840px" },
  },
  description: {
    color: theme.palette.white.main,
    fontSize: { xs: "15px", md: "30px" },
    lineHeight: { xs: "43px", md: "36px" },
    fontFamily: theme.typography.montserrat,
    fontWeight: "400",
    mt: { xs: "40px" },
    textAlign: { xs: "center" },
    width: { xs: "350px", md: "650px" },
    mb: { md: "100px", xs: "70px" },
  },
  mainImage: {
    position: "absolute",
    top: "0",
    left: "0",
    width: { xs: "80%", sm: "48%", md: "48%", lg: "45%", xl: "38%" },
  },
});

const NotFound = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box sx={classes.container}>
      <Box src={wire} component="img" sx={classes.mainImage} />
      <Typography sx={classes.msg}>
        No hemos encontrado la página que buscas.
      </Typography>
      <Typography sx={classes.description}>Código de error: 404</Typography>
      <LandingButton
        background={"linear-gradient(105deg, #f32c4d, #d00e2e 100%);"}
        color={"#2894cf"}
        pathname="/"
        text="Volver al inicio"
      />
    </Box>
  );
};

export default NotFound;
