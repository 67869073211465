import styled from "@emotion/styled";

import { Button, MenuItem, MenuList, Paper, Box } from "@mui/material";

export const MobileContainer = styled(Box)({
  display: "flex",
  gap: "1rem",
  marginLeft: 2,
});

export const LanguageButton = styled(Button)(({ theme, active }) => ({
  borderRadius: "32px",
  border: `2px solid ${theme.palette.primary.main} `,
  background: active ? theme.palette.primary.hover : "transparent",
  display: "flex",
  justifyContent: "center",
  gap: "6px",
  width: "40px",
  height: "34px",
  "&:hover": {
    background: "rgba(28, 122, 217, 0.1)",
  },

  "@media (min-width:960px)": {
    width: "73px",
    height: "40px",
  },
}));

export const ArrowIcon = styled("img")(({ expand }) => ({
  transition: "transform 0.3s ease",
  transform: expand ? "rotate(0deg)" : "rotate(180deg)",
  width: "7px",
  height: "11px",
}));

export const LanguagePaper = styled(Paper)({
  borderRadius: "0 20px 20px 20px",
  marginLeft: "20px",
  width: "76px",
});

export const LanguageList = styled(MenuList)({
  background: "rgba(255, 255, 255, 1) !important",
  borderRadius: "0 20px 20px 20px",
  padding: "15px 0",
  width: "76px",
});

export const LanguageIcon = styled("img")({
  width: "19px",
  height: "19px",

  "@media (min-width:960px)": {
    width: "21px",
    height: "21px",
  },
});

export const LanguageItem = styled(MenuItem)({
  display: "grid",
  justifyContent: "center",
  "&:hover": {
    background: "rgba(28, 122, 217, 0.1)",
  },
});
