import React, { useState, useEffect } from "react";

import { code } from "./constants";

import { Code, CodeText, CodeNumber, CodeLine, Container } from "./styles";

const CodeBlock = ({ sx }) => {
  const initialState = code.map(() => []);

  const [displayText, setDisplayText] = useState(initialState);
  const writeSpeed = 30;

  const updateDisplayText = (paragraph, phrase, text) => {
    setDisplayText((prevArray) => {
      const newArray = [...prevArray];
      const subArray = [...newArray[paragraph]];
      subArray[phrase] = subArray[phrase]
        ? subArray[phrase].concat(text)
        : text;
      newArray[paragraph] = subArray;
      return newArray;
    });
  };

  useEffect(() => {
    let paragraphIndex = 0;
    let phraseIndex = 0;
    let letterIndex = 0;
    const typeWriter = () => {
      if (paragraphIndex < code.length) {
        if (phraseIndex < code[paragraphIndex].length) {
          if (letterIndex < code[paragraphIndex][phraseIndex].phrase.length) {
            updateDisplayText(
              paragraphIndex,
              phraseIndex,
              code[paragraphIndex][phraseIndex].phrase[letterIndex]
            );
            letterIndex++;
          } else {
            phraseIndex++;
            letterIndex = 0;
          }
        } else {
          paragraphIndex++;
          phraseIndex = 0;
        }
        setTimeout(typeWriter, writeSpeed);
      }
    };

    typeWriter();
  }, []);

  return (
    <Container sx={sx}>
      <Code>
        {displayText.map((paragraph, i) => (
          <CodeLine key={`code-block-${i}`}>
            <CodeNumber> {i + 1} </CodeNumber>
            {paragraph.map((phrase, j) => (
              <CodeText
                key={`code-block-${i}-phrase-${j}`}
                sx={{ color: code[i][j].color }}
              >
                {phrase}
              </CodeText>
            ))}
          </CodeLine>
        ))}
      </Code>
    </Container>
  );
};

export default CodeBlock;
