import React from "react";
import { useTheme } from "@mui/styles";
import { Box, useMediaQuery } from "@mui/material";
import TranslationSectionTitle from "../../commons/TranslationSectionTitle";
import { useLocation } from "react-router-dom";
import useTranslation from "../../hooks/useTranslation";
import FundingCard from "./FundingCard";

const useStyles = (theme, isMobile) => ({
  container: {
    boxSizing: "border-box",
    padding: isMobile ? "3rem 2.25rem" : "6rem",
    backgroundColor: theme.palette.primary.main,
  },
  titleContainer: {
    mb: "2rem",
  },
  title: {
    bold: {
      fontFamily: "Montserrat",
      fontSize: "2.5rem",
      fontWeight: 700,
      lineHeight: "2.8125rem",
      color: theme.palette.white.main,
    },
  },
  subtitle: {
    normal: {
      fontFamily: "Montserrat",
      fontSize: "1.375rem",
      fontWeight: 400,
      lineHeight: "2.25rem",
      color: theme.palette.white.main,
    },
  },
  fundingCardsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "3.5rem",
    justifyContent: "center",
  },
});

const Funding = ({ pricesRef }) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { title, subtitle, fundingCards } = useTranslation(pathname, "Funding");
  const isMobile = useMediaQuery("(max-width:899px)");
  const classes = useStyles(theme, isMobile);

  return (
    <Box ref={pricesRef} sx={classes.container} id="price">
      <TranslationSectionTitle
        title={title}
        subtitle={subtitle}
        titleSx={{ bold: classes.title.bold }}
        subtitleSx={{ normal: classes.subtitle.normal }}
        sx={classes.titleContainer}
        invertOrder
      />
      <Box sx={classes.fundingCardsContainer}>
        {fundingCards.map((fundingCard) => (
          <FundingCard {...fundingCard} key={fundingCard.title} />
        ))}
      </Box>
    </Box>
  );
};

export default Funding;
