import { Box } from "@mui/material";
import TranslationText from "../components/Translations/TranslationText";
import React from "react";
import { useTheme } from "@mui/styles";

const useStyles = (theme) => ({
  container: {},
  title: {
    container: {
      textAlign: "center",
    },
    normal: {
      fontFamily: theme.typography.Montserrat,
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: { xs: "25px", sm: "25px", md: "40px", lg: "40px", xl: "40px" },
      lineHeight: {
        xs: "35px",
        sm: "35px",
        md: "45px",
        lg: "45px",
        xl: "45px",
      },
      color: "#001055",
    },
    bold: {
      fontFamily: theme.typography.Montserrat,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: { xs: "25px", sm: "25px", md: "40px", lg: "40px", xl: "40px" },
      lineHeight: {
        xs: "35px",
        sm: "35px",
        md: "45px",
        lg: "45px",
        xl: "45px",
      },
      color: "#001055",
    },
    italic: {
      fontFamily: theme.typography.Montserrat,
      fontStyle: "italic",
      fontWeight: 400,
      fontSize: { xs: "25px", sm: "25px", md: "40px", lg: "40px", xl: "40px" },
      lineHeight: {
        xs: "35px",
        sm: "35px",
        md: "45px",
        lg: "45px",
        xl: "45px",
      },
      color: "#001055",
    },
  },
  subtitle: {
    container: {
      textAlign: "center",
    },
    normal: {
      fontFamily: theme.typography.Anaheim,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: { xs: "20px", sm: "20px", md: "26px", lg: "26px", xl: "26px" },
      lineHeight: "36px",
      color: "#1C7AD9",
    },
    bold: {
      fontFamily: theme.typography.Anaheim,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: { xs: "20px", sm: "20px", md: "26px", lg: "26px", xl: "26px" },
      lineHeight: "36px",
      color: "#1C7AD9",
    },
    italic: {
      fontFamily: theme.typography.Anaheim,
      fontStyle: "italic",
      fontWeight: 400,
      fontSize: { xs: "20px", sm: "20px", md: "26px", lg: "26px", xl: "26px" },
      lineHeight: "36px",
      color: "#1C7AD9",
    },
  },
});

const TranslationSectionTitle = ({
  title,
  subtitle,
  titleSx = {},
  subtitleSx = {},
  invertOrder,
  sx,
  containerSx = {},
  children,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  classes.title.container = Object.keys(containerSx).length
    ? { ...classes.title.container, ...containerSx }
    : classes.title.container;

  return (
    <Box sx={{ ...classes.container, ...sx }}>
      {invertOrder ? (
        <>
          <TranslationText
            textSx={{ ...classes.title, ...titleSx }}
            sx={classes.title.container}
          >
            {title}
          </TranslationText>
          <TranslationText
            textSx={{ ...classes.subtitle, ...subtitleSx }}
            sx={classes.subtitle.container}
          >
            {subtitle}
          </TranslationText>
        </>
      ) : (
        <>
          <TranslationText
            textSx={{ ...classes.subtitle, ...subtitleSx }}
            sx={classes.subtitle.container}
          >
            {subtitle}
          </TranslationText>
          <TranslationText
            textSx={{ ...classes.title, ...titleSx }}
            sx={classes.title.container}
          >
            {title}
          </TranslationText>
        </>
      )}
      {children}
    </Box>
  );
};

export default TranslationSectionTitle;
