import { Typography } from "@mui/material";
import React from "react";

const TranslationText = ({
  children,
  component,
  sx,
  textSx: { normal, bold, italic },
  text = "",
  variant,
}) => {
  if (typeof children === "string") {
    text = children;
  }

  const parse = (text) =>
    text
      .split("**")
      .map((substr, i) => {
        if (i % 2 === 0) {
          return substr.split("_").map((substr, i) => {
            if (i % 2 === 0) {
              return typography(substr, normal);
            } else {
              return typography(substr, italic);
            }
          });
        } else {
          return substr.split("_").map((substr, i) => {
            if (i % 2 === 0) {
              return typography(substr, bold);
            } else {
              return typography(substr, italic);
            }
          });
        }
      })
      .flat();

  const typography = (text, styleType) =>
    text ? (
      <Typography
        component="span"
        sx={styleType}
        key={text + Math.random().toString()}
      >
        {text}
      </Typography>
    ) : null;

  return (
    <Typography variant={variant} component={component} sx={sx}>
      {parse(text)}
      {typeof children === "string" ? null : children}
    </Typography>
  );
};

export default TranslationText;
