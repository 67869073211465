import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const headers = {
  common: {
    "Content-Type": "application/json",
  },
  post: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

const instance = axios.create({ baseURL, headers, withCredentials: true });

// instance.interceptors.request.use(async config => {
//   try {
//     const accessToken = JSON.parse(localStorage.getItem("AE_Token"));
//     if (accessToken) {
//       config.headers.Authorization = `Bearer ${accessToken}`
//     }
//   } catch (e) {
//     console.log('Couldnt get token silently', e)
//   }
//   return config
// })

export default instance;
