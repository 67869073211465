import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import DottedBackground from "../../../img/JobPlacement/dottedBackground.svg";

export const Container = styled(Box)(({ theme, ismobile }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "10rem",
  padding: ismobile ? "0 1rem" : "",
  backgroundImage: !ismobile && `url(${DottedBackground})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "99% 110%",
  paddingBottom: "5rem",
  [theme.breakpoints.up("lg")]: {
    paddingBottom: "6rem",
  },
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.lightBlue,
  fontFamily: theme.typography.Anaheim.fontFamily,
  fontWeight: 400,
  textAlign: "center",
  lineHeight: "30px",
  fontSize: "26px",

  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
  textAlign: "center",
  fontSize: "40px",
  lineHeight: "45px",

  [theme.breakpoints.down("md")]: {
    fontSize: "25px",
    lineHeight: "35px",
  },
}));

export const DescriptionContainer = styled(Box)(({ theme }) => ({
  margin: "3rem 0",
  textAlign: "center",

  [theme.breakpoints.down("md")]: {
    margin: "1rem 0",
  },
}));

export const DescriptionText = styled(Typography)(({ _theme }) => ({
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "22px",
  lineHeight: "36px",
}));

export const CardsContainer = styled(Box)(({ theme, ismobile }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: ismobile ? "65rem" : "15rem",
  flexDirection: ismobile ? "column" : "row",
  padding: "0 1rem",

  [theme.breakpoints.up("xs")]: {
    width: "90%",
  },

  [theme.breakpoints.up("lg")]: {
    width: "95%",
  },
}));

export const MobileCardDivider = styled(Box)(({ theme }) => ({
  width: "80%",
  borderRight: "none",
  borderLeft: "none",
  borderBottom: `0.5px dashed ${theme.palette.lightGray}`,
  borderTop: `0.5px dashed ${theme.palette.lightGray}`,
}));

export const DesktopCardDivider = styled(Box)(({ theme }) => ({
  height: "100%",
  borderRight: `0.5px dashed ${theme.palette.lightGray}`,
  borderLeft: `0.5px dashed ${theme.palette.lightGray}`,
  borderBottom: "none",
  borderTop: "none",
}));
