import React from "react";
import { useMediaQuery } from "@mui/material";
import LaborInsertionCard from "./Card";
import { useLocation } from "react-router-dom";
import useTranslation from "../../../hooks/useTranslation";
import {
  CardsContainer,
  Container,
  DescriptionContainer,
  DescriptionText,
  DesktopCardDivider,
  MobileCardDivider,
  Subtitle,
  Title,
} from "./styles";

const LaborInsertion = () => {
  const isMobile = useMediaQuery("(max-width:899px)");
  const { pathname } = useLocation();
  const { title, subtitle, description, jobPlacement } = useTranslation(
    pathname,
    "LaborInsertion"
  );

  return (
    <Container ismobile={isMobile}>
      <Subtitle variant="h3">{subtitle}</Subtitle>
      <Title variant="h2">
        {title[0]} {isMobile && <br></br>} {title[1]}
      </Title>

      <DescriptionContainer>
        {!isMobile ? (
          <>
            <DescriptionText text={description[0]} />
            <DescriptionText text={description[1]} />{" "}
          </>
        ) : (
          <DescriptionText text={description[0] + description[1]} />
        )}
      </DescriptionContainer>

      <CardsContainer ismobile={isMobile}>
        {jobPlacement.map((item, index) => {
          const { img, text } = item;
          return (
            <>
              <LaborInsertionCard
                key={text}
                index={index}
                img={img}
                text={text}
              />

              {index !== jobPlacement.length - 1 ? (
                isMobile ? (
                  <MobileCardDivider />
                ) : (
                  <DesktopCardDivider />
                )
              ) : null}
            </>
          );
        })}
      </CardsContainer>
    </Container>
  );
};

export default LaborInsertion;
