import React, { useContext, useMemo } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import ModalityCard from "./ModalityCard";
import { useLocation, useNavigate } from "react-router-dom";
import { LanguageContext } from "../../context/LangContext";
import useTranslation from "../../hooks/useTranslation";
import TranslationSectionTitle from "../../commons/TranslationSectionTitle";

const useStyles = (theme, isMobile) => ({
  titleContainer: {
    marginTop: isMobile ? "110.4px" : "262.3px",
    display: { md: "flex" },
    justifyContent: { md: "center" },
    alignItems: { md: "center" },
  },
  line: {
    width: "25px",
    border: `1px solid ${theme.palette.red.main}`,
    marginRight: "8px",
    backgroundColor: theme.palette.red.main,
  },
  span: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  arrow: {
    color: theme.palette.grey.main,
    fontSize: "2rem",
    fontWeight: "light",
  },
  container: {
    width: "100%",
    mt: "128px",
  },
  sliderContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "hidden",
  },
  slider: {
    marginTop: isMobile ? "50px" : "110px",
    width: "98%",
    height: "fit-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  typography: {
    section: {
      fontSize: isMobile ? "14px" : "16px",
      fontWeight: "bold",
      letterSpacing: "2px",
      color: theme.palette.red.main,
    },
    sectionDescription: {
      fontSize: isMobile ? "32px" : "65px",
      fontFamily: theme.typography.montserrat.bold,
      color: theme.palette.blue.main,
      marginTop: isMobile ? "16px" : "10px",
    },
    container: {
      marginTop: "77px",
      fontSize: "14px",
      letterSpacing: "1.4px",
      fontFamily: theme.typography.sourceSansPro.semibold,
      color: theme.palette.red.main,
    },
  },
  cardsContainer: {
    display: "flex",
    gap: "0px 100px",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  modalityCard: {
    flexGrow: { xs: 1, md: 0, lg: 0, xl: 0 },
  },
});

const Modalities = ({ showTitle = true }) => {
  const atrCourse = {
    alliance: "",
    availability: true,
    bootcampPreparation:
      "Podrás aplicar a las ediciones del Javascript Bootcamp de 2023 ",
    cohortLabel: "INTRO_JAVASCRIPT_ATR-2023-ENERO-FULL TIME-EIM6",
    endDate: "2023-12-31T00:00:00.000Z",
    endHour: "19:00:00",
    id: 72,
    limitOfStudents: 100,
    limitToApply: "2023-12-31T00:00:00.000Z",
    mode: "Online",
    month: "enero",
    partTimeEndDate: null,
    partTimeStartDate: null,
    priceARS: "4860",
    priceUSD: "10",
    shift: "full time",
    startDate: "2023-01-01T00:00:00.000Z",
    startHour: "09:30:00",
    status: "disponible",
    type: { id: 4, name: "Intro ATR", tag: "INTRO_JAVASCRIPT_ATR" },
    vacancyStatus: true,
    visibility: true,
    weekDays: "Lunes a Domingo",
    year: 2023,
  };

  const introCourses = {
    alliance: "",
    availability: true,
    bootcampPreparation: "",
    cohortLabel: "INTRO_JAVASCRIPT-2023-OCTUBRE-MAÑANA-2R1U",
    endDate: "2023-12-04T00:00:00.000Z",
    endHour: "13:00:00",
    id: 116,
    limitOfStudents: 100,
    limitToApply: "2023-10-11T00:00:00.000Z",
    mode: "Online",
    month: "octubre",
    partTimeEndDate: null,
    partTimeStartDate: null,
    priceARS: "29520",
    priceUSD: "100",
    shift: "mañana",
    startDate: "2023-10-11T00:00:00.000Z",
    startHour: "10:00:00",
    status: "disponible",
    type: { id: 2, name: "Intro con Profe", tag: "INTRO_JAVASCRIPT" },
    vacancyStatus: true,
    visibility: true,
    weekDays: "Lun, Mie y Vie",
    year: 2023,
  };

  const isMobile = useMediaQuery("(max-width:989px)");
  const theme = useTheme();
  const classes = useStyles(theme, isMobile);
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);

  const { pathname } = useLocation();
  const { title, description, modalityCardATR, modalityCardIntroTeacher } =
    useTranslation(pathname, "Modalities");
  const getPriceInRegionalFormat = (number, region) =>
    new Intl.NumberFormat(region, {
      style: "currency",
      currency: region === "es-AR" ? "ARS" : "USD",
      maximumFractionDigits: 0,
      currencyDisplay: "code",
    }).format(parseInt(number));

  const discount = useMemo(
    () => ({
      live: {
        ar: {
          price: null,
          percentage: 40,
        },
        rdm: {
          price: null,
          percentage: null,
        },
      },
      atr: {
        ar: {
          price: null,
          percentage: null,
        },
        rdm: {
          price: null,
          percentage: null,
        },
      },
      /* NOTE: Price and percentage are numbers or null */
    }),
    []
  );

  return isMobile ? (
    <Box sx={classes.container}>
      {showTitle && (
        <TranslationSectionTitle title={title} subtitle={description} />
      )}
      <Box sx={classes.cardsContainer}>
        <ModalityCard
          title={modalityCardATR.title}
          description={modalityCardATR.description}
          price={language === "es" ? atrCourse.priceARS : atrCourse.priceUSD}
          installment={
            language === "es"
              ? modalityCardATR.installment.arg
              : modalityCardATR.installment.rdm
          }
          discount={language === "es" ? discount.atr.ar : discount.atr.rdm}
          benefits={modalityCardATR.benefits}
          applyButtonText={modalityCardATR.applyButtonText}
          onButtonClick={() =>
            navigate("/aplica/intro/formulario?modalidad=atr")
          }
          sx={classes.modalityCard}
        />
        <ModalityCard
          title={modalityCardIntroTeacher.title}
          description={
            language === "es"
              ? modalityCardIntroTeacher.description.arg
              : modalityCardIntroTeacher.description.rdm
          }
          price={
            introCourses &&
            (language === "es"
              ? introCourses.priceARS / 6
              : introCourses.priceUSD)
          }
          discount={language === "es" ? discount.live.ar : discount.live.rdm}
          installment={
            language === "es"
              ? `${modalityCardIntroTeacher.installment.arg} ${
                  introCourses &&
                  getPriceInRegionalFormat(introCourses?.priceARS, "es-AR")
                }`
              : modalityCardIntroTeacher.installment.rdm
          }
          benefits={modalityCardIntroTeacher.benefits}
          applyButtonText={modalityCardIntroTeacher.applyButtonText}
          onButtonClick={() =>
            navigate("/aplica/intro/fechas?modalidad=videoconferencia")
          }
          sx={classes.modalityCard}
          starred
        />
      </Box>
    </Box>
  ) : (
    <Box sx={classes.container}>
      {showTitle && (
        <TranslationSectionTitle title={title} subtitle={description} />
      )}
      <Box sx={classes.cardsContainer}>
        <ModalityCard
          title={modalityCardATR.title}
          description={modalityCardATR.description}
          price={language === "es" ? atrCourse.priceARS : atrCourse.priceUSD}
          installment={
            language === "es"
              ? modalityCardATR.installment.arg
              : modalityCardATR.installment.rdm
          }
          benefits={modalityCardATR.benefits}
          discount={language === "es" ? discount.atr.ar : discount.atr.rdm}
          applyButtonText={modalityCardATR.applyButtonText}
          onButtonClick={() =>
            navigate("/aplica/intro/formulario?modalidad=atr")
          }
          sx={classes.modalityCard}
        />
        <ModalityCard
          title={modalityCardIntroTeacher.title}
          description={
            language === "es"
              ? modalityCardIntroTeacher.description.arg
              : modalityCardIntroTeacher.description.rdm
          }
          price={
            introCourses &&
            (language === "es"
              ? introCourses.priceARS / 6
              : introCourses.priceUSD)
          }
          discount={language === "es" ? discount.live.ar : discount.live.rdm}
          installment={
            language === "es"
              ? `${modalityCardIntroTeacher.installment.arg} ${
                  introCourses &&
                  getPriceInRegionalFormat(introCourses?.priceARS, "es-AR")
                }`
              : modalityCardIntroTeacher.installment.rdm
          }
          benefits={modalityCardIntroTeacher.benefits}
          applyButtonText={modalityCardIntroTeacher.applyButtonText}
          onButtonClick={() =>
            navigate("/aplica/intro/fechas?modalidad=videoconferencia")
          }
          sx={classes.modalityCard}
          starred
        />
      </Box>
    </Box>
  );
};

export default Modalities;
