import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import Questions from "./Questions";
import Tabs from "./Tabs";
import useTranslation from "../../hooks/useTranslation";
import { useLocation } from "react-router-dom";

const useStyles = (theme, isMobile) => ({
  container: {
    height: "fit-content",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    marginTop: "126px",
    padding: "0 0 135px",
    ovefflow: "hidden",
  },
  background: {
    width: "100%",
    height: "1550px",
    backgroundImage:
      "linear-gradient(to top, #f4f4f4 0%, rgba(244, 244, 244, 0) 100%)",
    borderRadius: "95px 0 100px 0",
    position: "absolute",
    top: "250px",
    zIndex: "-100",
  },
  circle: {
    width: "98px",
    height: "98px",
    border: "solid 8.8px #ff062e",
    borderRadius: "100%",
    position: "absolute",
    top: "130vh",
    marginLeft: "50%",
    zIndex: "-50",
  },
  section: {
    boxShadow: "none",
    backgroundColor: "inherit",
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "58px",
    "&:before": {
      display: "none",
    },
  },
  title: {
    padding: "12px",
    borderRadius: "47px",
    backgroundColor: theme.palette.white.main,
    width: "230px",
    boxShadow: "0 10px 50px 0 rgba(0, 0, 0, 0.05)",
  },
  questions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  typography: {
    descriptionSubtitle: {
      fontSize: isMobile ? 14 : 16,
      fontWeight: "bold",
      letterSpacing: "2px",
      color: theme.palette.red.main,
      marginBottom: "10px",
    },
    descriptionTitle: {
      fontSize: isMobile ? 32 : 65,
      width: "225px",
      fontFamily: theme.typography.montserrat.bold,
      color: theme.palette.blue.main,
      lineHeight: "43px",
    },
    section: {
      fontFamily: theme.typography.montserrat.bold,
      color: theme.palette.blue.main,
      fontSize: "30px",
    },
    info: {
      fontFamily: theme.typography.montserrat.regular,
      fontSize: { xs: "16px", xl: "22px" },
      width: { xs: "270px", md: "520px", xl: "800px" },
      textAlign: isMobile ? "center" : "left",
    },
  },
});

export default function SimpleAccordion() {
  const isMobile = useMediaQuery("(max-width:899px)");
  const component = "Faqs";
  const path = useLocation().pathname;
  const theme = useTheme();
  const classes = useStyles(theme, isMobile);

  const Faqs = useTranslation(path, component);

  return isMobile ? (
    <Box sx={classes.container}>
      <Box sx={classes.background} />
      {Faqs.map((section, index) => {
        return (
          <Accordion key={index} sx={classes.section}>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box sx={classes.title}>
                <Typography sx={classes.typography.section}>
                  {section.title}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={classes.questions}>
              <Typography sx={classes.typography.descriptionSubtitle}>
                {section.section}
              </Typography>
              <Typography sx={classes.typography.descriptionTitle}>
                {section.title}
              </Typography>
              <Questions questions={section.questions} />
            </AccordionDetails>
          </Accordion>
        );
      })}
      <Box sx={classes.circle} />
    </Box>
  ) : (
    <Tabs sections={Faqs} />
  );
}
