import styled from "@emotion/styled";

import { Box, Typography, Card } from "@mui/material";

export const Container = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "6rem 0",
  flexDirection: "column",
  gap: "4rem",
  position: "relative",
  overflow: "hidden",
});

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  textAlign: "center",
  fontWeight: "bold",
  fontSize: "25px",
  lineHeight: "35px",

  [theme.breakpoints.up("md")]: {
    fontSize: "32px",
    lineHeight: "45px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "40px",
  },
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.lightBlue,
  fontFamily: theme.typography.Anaheim.fontFamily,
  fontWeight: 400,
  textAlign: "center",
  lineHeight: "30px",
  fontSize: "20px",
  [theme.breakpoints.up("sm")]: {
    fontSize: "22px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "26px",
  },
}));

export const Text = styled(Typography)(({ theme }) => ({
  // color: theme.palette.blue.main,
  fontFamily: theme.typography.montserrat.regular,
  fontWeight: 400,
  textAlign: "center",
  lineHeight: "30px",
  fontSize: "18px",
  width: "90%",
  margin: "30px auto",
  [theme.breakpoints.up("sm")]: {
    margin: "40px auto",
    width: "85%",
    fontSize: "22px",
  },
  [theme.breakpoints.up("lg")]: {
    margin: "60px auto",
    width: "80%",
    fontSize: "24px",
  },
}));

export const ErrorContainer = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "80%",
  padding: "4.5rem 1rem !important",
  maxWidth: "1250px",

  [theme.breakpoints.up("md")]: {
    width: "80%",
  },
}));

export const ErrorMessage = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  textAlign: "center",

  [theme.breakpoints.up("sm")]: {
    fontSize: "20px",
  },

  [theme.breakpoints.up("md")]: {
    fontSize: "22px",
  },
}));
