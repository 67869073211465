import React, { Fragment } from "react";

import Actions from "../Actions";
import Option from "./Option";

import { Menu } from "./styles";

const DesktopMenu = ({ menuTranslation, handleNavigate }) => {
  return (
    <Fragment>
      <Menu>
        {menuTranslation.map((option) => (
          <Option
            key={option.label}
            {...option}
            handleNavigate={handleNavigate}
          />
        ))}
      </Menu>
      <Actions />
    </Fragment>
  );
};

export default DesktopMenu;
