import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import useTranslation from "../../hooks/useTranslation";
import { useLocation } from "react-router-dom";
import AccordionTab from "./AccordionTab";
import CollapsibleBox from "./CollapsibleBox";

const useStyles = () => ({
  container: {
    boxSizing: "border-box",
    p: "48px 64px",
    background: "linear-gradient(180deg, #F4F4F4 0%, #FFFFFF 100%)",
    borderRadius: "60px",
    boxShadow: "5px 5px 20px rgba(79, 79, 79, 0.2)",
    mb: "48px",
  },
  collapsibleBox: {
    mb: "24px",
  },
  lastElementMargin: {
    mb: "40px",
  },
});

const AccordionBox = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { pathname } = useLocation();
  const { tabs } = useTranslation(pathname, "AccordionBox");
  const isMobile = useMediaQuery("(max-width:1064px)");

  if (isMobile) {
    return tabs.map((tab, i, tabs) => (
      <CollapsibleBox
        key={tab.title}
        sx={{
          ...classes.collapsibleBox,
          ...(tab === tabs.at(-1) && classes.lastElementMargin),
        }}
        {...tab}
        initiallyExpanded={i === 0}
      />
    ));
  }

  return (
    <Box sx={classes.container}>
      {tabs.map((tab, i) => (
        <AccordionTab key={tab.title} {...tab} initiallyExpanded={i === 0} />
      ))}
    </Box>
  );
};

export default AccordionBox;
