import React from "react";

import {
  Quotes,
  Card,
  Text,
  CardHeader,
  CardContent,
  CardMedia,
  CardFooter,
  Author,
} from "./styles";

const Testimonial = ({ name, imageSrc, companySrc, text, companyName }) => {
  return (
    <Card>
      <CardHeader>
        <Quotes />
        <CardMedia component="img" alt={name} image={imageSrc} />
      </CardHeader>
      <CardContent>
        <Text variant="body1">{text}</Text>
      </CardContent>
      <CardFooter>
        <Author variant="h5">{name}</Author>
        <img
          src={companySrc}
          alt={`${companyName} logo`}
          style={{ width: 88, height: 88 }}
        />
      </CardFooter>
    </Card>
  );
};

export default Testimonial;
