/** @format */

import React from "react";

//material ui
import { useTheme } from "@mui/styles";
import { Typography, Box, useMediaQuery, Container } from "@mui/material";

//helpers
import { useLocation } from "react-router-dom";
import useTranslation from "../../../hooks/useTranslation";
import Carrousel from "../../../commons/Carrousel";
import backgrounImg from "../../../img/ToolsJS/background.svg";

import { Title, Subtitle } from "./styles";

const useStyles = (theme) => ({
  container: {
    mt: { xs: "54px", md: "53px", lg: "68px", xl: "143px" },
    background: `url(${backgrounImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "96%",
    height: "55vh !important",
    display: "flex",
    flexDirection: { xs: "column" },
    alignItems: { xs: "center", md: "center" },
  },
  title: {
    color: theme.palette.lightBlue,
    fontFamily: theme.typography.Anaheim,
    fontSize: { xs: "14px", md: "16px" },
    letterSpacing: { xs: "1.4px", md: "1.6px" },
  },
  subtitle: {
    color: theme.palette.primary.main,
    textAlign: { xs: "center" },
    maxWidth: { xs: "225px", md: "447px", lg: "558px", xl: "667px" },
    height: { xs: "72px", md: "73.25px", lg: "74.5px", xl: "79px" },
    mt: { xs: "16px", md: "22px", lg: "26.5px", xl: "31px" },
    fontWeight: 400,
    fontSize: { xs: "20px", sm: "20px", md: "26px", lg: "26px", xl: "26px" },
    lineHeight: "36px",
  },
  titleContainer: {
    display: { md: "flex" },
    justifyContent: { md: "center" },
    alignItems: { md: "center" },
  },
  sliderContainer: {
    width: "100%",
    height: "100%",
    mt: { xs: "10px", md: "8px", lg: "6px", xl: "6px" },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  slider: {
    ml: { xs: "70px !important", md: "5%", lg: "5%" },
    display: "flex",
    width: "100%",
    height: "100%",
  },
  span: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  arrow: {
    color: theme.palette.grey,
    fontSize: "30px",
    fontWeight: "bold",
  },
  content: {
    width: "158px",
    display: "flex",
    textAlign: "center",
    m: false,
    ml: false,
    mr: false,
  },
  boxTool: {
    left: "10px",
    width: {
      xs: "135px !important",
      sm: "68% !important",
      md: "95% !important",
      lg: "95% !important",
      xl: "100%",
    },
    height: { xs: "75px", sm: "80px", md: "85px", lg: "90px", xl: "90px" },
    mb: "35px",
    ml: { xs: "19%", md: "2%", lg: "5%" },
    alignItems: "center",
    borderRadius: "60px",
    background: theme.palette.background.lightGray,
    //mr: { xs: "28px", md: "35px", lg: "35px", xl: "35px" },
  },
  imgContainer: {
    top: { xs: "10px", sm: "10px", md: "10px", lg: "10px", xl: "8px" },
    position: "relative",
    left: { xs: "8px", sm: "10px", md: "10px", lg: "10px", xl: "10px" },
    display: "flex",
    alignItems: "center",
    borderRadius: "60px",
    boxShadow: "5px 5px 20px rgba(79, 79, 79, 0.2)",
    justifyContent: "center",
    width: {
      xs: "33px",
      sm: "38px",
      md: "44px",
      lg: "48px",
      xl: "48px",
    },
    height: {
      xs: "33px",
      sm: "38px",
      md: "44px",
      lg: "48px",
      xl: "48px",
    },
    p: "0.6rem !important",
    background: theme.palette.background.paper,
  },
  sliderOneColumn: {
    display: "flex",
    justifyContent: "end",
    alignItems: "end",
    width: "100%",

    boxTool: {
      ml: "50px",
    },
  },
});

const Tools = () => {
  const isMobile = useMediaQuery("(max-width:899px)");
  const theme = useTheme();
  const { pathname } = useLocation();
  const componentName = "Tools";
  const translation = useTranslation(pathname, componentName);
  const { logosImg, title, subtitle } = translation;
  const classes = useStyles(theme);
  return (
    <Box sx={classes.container}>
      <Subtitle> {title} </Subtitle>
      <Title> {subtitle} </Title>

      <Box sx={classes.sliderContainer}>
        <Carrousel
          slidesToShow={5}
          slidesToScroll={5}
          style={classes.slider}
          rows={2}
          autoplay={false}
          autoplaySpeed={3000}
          responsive={[
            {
              breakpoint: 1092,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                rows: 3,
              },
            },
            {
              breakpoint: 758,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                rows: 3,
              },
            },
            {
              breakpoint: 361,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                style: classes.sliderOneColumn,
                rows: 3,
              },
            },
          ]}
        >
          {logosImg.map(({ src, alt, title }, index) => {
            return (
              <Box key={index} sx={classes.boxTool}>
                <Container disableGutters={true} sx={classes.content}>
                  <Box sx={classes.imgContainer}>
                    <img src={src} alt={alt} />
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: theme.typography.Anaheim,
                      wordBreak: "break-word",
                      top: title.includes(" ")
                        ? { xs: "16px", sm: "20px", md: "15px", lg: "20px" }
                        : { xs: "25px", sm: "27px", md: "28px", lg: "35px" },
                      left: title.includes(" ")
                        ? { xs: "18px", sm: "15px", md: "5px", lg: "20px" }
                        : {
                            xs: "15px",
                            sm: "25px",
                            md: "35px",
                            lg: "30px",
                            xl: "20px",
                          },
                      marginTop: title === "Command Line" && "10px",
                      paddingRight: title.includes(" ") && {
                        xs: "45px",
                        sm: "15px",
                        md: "5px",
                        lg: "20px",
                      },
                      fontSize: isMobile ? "15px" : "18px",
                      position: "relative",
                      lineHeight: title.includes(" ") && "18px",
                    }}
                  >
                    {title}
                  </Typography>
                </Container>
              </Box>
            );
          })}
        </Carrousel>
      </Box>
    </Box>
  );
};

export default Tools;
