import React from "react";
import { Container, Title, Subtitle, Text } from "./styles";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import useTranslation from "../../../hooks/useTranslation";

const Dates = () => {
  const { pathname } = useLocation();
  const componentName = "Dates";
  const { title, subtitle } = useTranslation(pathname, componentName);

  return (
    <Container>
      <Box>
        <Subtitle variant="h3"> {subtitle} </Subtitle>
        <Title variant="h2"> {title} </Title>
        <Text>
          ¡Gracias por tu interés! 😊 Actualmente el Bootcamp sólo está dirigido
          a estudiantes a través de alianzas con gobiernos y empresas. Si no
          estás vinculado a una de estas alianzas, ¡estate atento a esta página
          para futuras novedades!
        </Text>
      </Box>
    </Container>
  );
};

export default Dates;
