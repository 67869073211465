import { useContext, useMemo } from "react";
import { LanguageContext } from "../context/LangContext";

const useTranslation = (pathname, componentName) => {
  const { language } = useContext(LanguageContext);
  const translations = require(`../translations/${language || "es"}`).default;

  return useMemo(
    () => translations[pathname][componentName],
    [translations, pathname, componentName]
  );
};

export default useTranslation;
