import React from "react";
import CodingPlatformRatings from "../CodingPlatformsRatings/CodingPlatformRatings";
import { useLocation } from "react-router-dom";
import useTranslation from "../../hooks/useTranslation";
import {
  RankingContent,
  RatingBox,
  Separator,
  Text,
  TextContent,
  Container,
} from "./styles";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";

const useStyles = (theme, isMobile) => ({
  text: {
    container: { textAlign: isMobile ? "center" : "start" },
    normal: {
      color: "#FFF",
      fontSize: isMobile ? "25px" : "40px",
      fontFamily: theme.typography.Montserrat,
      lineHeight: isMobile ? "35px" : "45px",
      fontWeight: 400,
    },
    bold: {
      color: "#FFF",
      fontSize: isMobile ? "25px" : "40px",
      fontFamily: theme.typography.Montserrat,
      lineHeight: isMobile ? "35px" : "45px",
      fontWeight: 600,
    },
  },
});

const HomeReviews = ({ ratings }) => {
  const { pathname } = useLocation();
  const { subtitle } = useTranslation(pathname, "Testimonies");
  const isMobile = useMediaQuery("(max-width:1313px)");
  const theme = useTheme();
  const classes = useStyles(theme, isMobile);

  return (
    <Container>
      <RatingBox isMobile={isMobile}>
        <TextContent>
          <Text sx={classes.text.container} textSx={classes.text}>
            {subtitle}
          </Text>
        </TextContent>
        <RankingContent isMobile={isMobile}>
          <CodingPlatformRatings {...ratings[0]} />
          <Separator
            orientation={isMobile ? "horizontal" : "vertical"}
            flexItem
          />
          <CodingPlatformRatings {...ratings[1]} />
        </RankingContent>
      </RatingBox>
    </Container>
  );
};

export default HomeReviews;
