import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import TranslationText from "../components/Translations/TranslationText";
import CheckMarkText from "./CheckMarkText";
import useTranslation from "../hooks/useTranslation";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/styles";

const useStyles = (
  theme,
  isMobile,
  isTablet,
  backgroundImg,
  imageOverlap,
  onlyTitle
) => ({
  container: {
    boxSizing: "border-box",
    width: "100%",
    minHeight: isMobile ? "auto" : onlyTitle ? "340px" : "548px",
    display: "flex",
    justifyContent: isMobile || onlyTitle ? "center" : "space-between",
    gap: "128px",
    alignItems: "center",
    borderRadius: "0px 0px 100px 100px",
    padding: "0 96px",
    background: `url(${backgroundImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "#001F55",
    py: isMobile || isTablet ? "61px" : "0",
    px: isMobile ? "32px" : isTablet ? "61px" : "96px",
  },
  subContainer: {
    display: isMobile ? "flex" : "block",
    flexDirection: isMobile ? "column" : "row",
    alignItems: "center",
    textAlign: "left",
  },
  title: {
    normal: {
      fontFamily: "Montserrat",
      fontWeight: 400,
      fontSize: isMobile ? "35px" : "50px",
      lineHeight: "60px",
      color: theme.palette.white.main,
      whiteSpace: isMobile ? "normal" : "nowrap",
    },
    bold: {
      fontFamily: "Montserrat",
      fontWeight: 700,
      fontSize: isMobile ? "35px" : "50px",
      lineHeight: "60px",
      color: theme.palette.white.main,
      whiteSpace: isMobile ? "normal" : "nowrap",
    },
    container: {
      marginBottom: "10px",
    },
  },
  subtitle: {
    container: {
      marginBottom: "18px",
      textAlign: isMobile ? "center" : "left",
      pr: isMobile
        ? "0px"
        : {
            sm: "5%",
            md: "10%",
            lg: "5%",
            xl: "15%",
          },
    },
    normal: {
      fontFamily: "Montserrat",
      fontWeight: 400,
      fontSize: isMobile ? "20px" : "22px",
      lineHeight: "35px",
      color: theme.palette.white.main,
    },
    bold: {
      fontFamily: "Montserrat",
      fontWeight: 700,
      fontSize: isMobile ? "20px" : "22px",
      lineHeight: "35px",
      color: theme.palette.white.main,
    },
  },
  description: {
    normal: {
      fontFamily: "Montserrat",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "40px",
      color: theme.palette.white.main,
    },
    container: {
      marginBottom: "36px",
      flexDirection: isMobile ? "column" : "row",
    },
  },
  mainImg: {
    height: imageOverlap ? "544px" : "379px",
  },
  imgContainer: imageOverlap && {
    position: "relative",
    top: "76px",
  },
  secondaryImg: imageOverlap && {
    position: "absolute",
    left: "-240px",
    top: "144px",
  },
  alignCenterMobile: {
    textAlign: isMobile ? "center" : "left",
  },
  textNoWrapAlways: {
    whiteSpace: "nowrap",
  },
});

const Hero = ({ imageOverlap, onlyTitle, SecondaryComponent }) => {
  const isMobile = useMediaQuery("(max-width:668px)");
  const isTablet = useMediaQuery("(max-width:1256px)");
  const isDesktop = !isMobile && !isTablet;
  const { pathname } = useLocation();
  const theme = useTheme();
  const {
    title,
    subtitle,
    description,
    checkList,
    mainImg,
    secondaryImg,
    backgroundImg,
  } = useTranslation(pathname, "Hero");
  const classes = useStyles(
    theme,
    isMobile,
    isTablet,
    isMobile ? backgroundImg.mobile.src : backgroundImg.desktop.src,
    imageOverlap,
    onlyTitle
  );

  return (
    <Box sx={classes.container}>
      <Box sx={classes.subContainer}>
        <TranslationText
          textSx={{ normal: classes.title.normal, bold: classes.title.bold }}
          sx={classes.alignCenterMobile}
        >
          {title[0]}
        </TranslationText>
        {title[1] && (
          <TranslationText
            textSx={{ normal: classes.title.normal, bold: classes.title.bold }}
            sx={{ ...classes.title.container, ...classes.alignCenterMobile }}
          >
            {title[1]}
          </TranslationText>
        )}
        {(subtitle.desktop || subtitle.mobile) && (
          <TranslationText
            textSx={{
              normal: classes.subtitle.normal,
              bold: classes.subtitle.bold,
            }}
            sx={{
              ...classes.subtitle.container,
              ...classes.alignCenterMobile,
              ...(description && { mb: "12px" }),
            }}
          >
            {isMobile ? subtitle.mobile : subtitle.desktop}
          </TranslationText>
        )}
        {isMobile
          ? checkList.mobile.map((text) => (
              <CheckMarkText
                key={text}
                textSx={{ normal: classes.description.normal }}
                sx={{
                  ...classes.description.container,
                  ...classes.textNoWrapAlways,
                }}
              >
                {text}
              </CheckMarkText>
            ))
          : checkList.desktop.map((text, i) => (
              <CheckMarkText
                key={text}
                textSx={{ normal: classes.description.normal }}
                sx={
                  checkList.desktop[i] === checkList.desktop.at(-1)
                    ? classes.description.container
                    : undefined
                }
              >
                {text}
              </CheckMarkText>
            ))}
        {description && (
          <TranslationText
            textSx={{ bold: classes.subtitle.bold }}
            sx={classes.subtitle.container}
          >
            {description}
          </TranslationText>
        )}
      </Box>
      {isDesktop && !onlyTitle && (
        <Box sx={classes.imgContainer}>
          <Box
            component="img"
            sx={classes.mainImg}
            src={mainImg.src}
            alt={mainImg.alt}
          />
          {SecondaryComponent ? (
            <SecondaryComponent sx={classes.secondaryImg} />
          ) : secondaryImg ? (
            <Box
              component="img"
              sx={classes.secondaryImg}
              src={secondaryImg.src}
              alt={secondaryImg.alt}
            />
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default Hero;
