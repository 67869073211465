import React, { useState, Fragment } from "react";

import { MenuIconContainer, MenuIcon, Menu } from "./styles";

import Dropdown from "./Dropdown";
import Actions from "../Actions";

const MobileMenu = ({ mobileMenuTranslation, isMobile, handleNavigate }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <MenuIconContainer onClick={handleClick} open={open}>
        <MenuIcon />
      </MenuIconContainer>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Dropdown
          label="MobileMenu"
          options={mobileMenuTranslation}
          handleClose={handleClose}
          handleNavigate={handleNavigate}
          sx={{ borderRadius: "20px 0px 20px 20px" }}
        >
          <Actions isMobile={isMobile} />
        </Dropdown>
      </Menu>
    </Fragment>
  );
};

export default MobileMenu;
