import styled from "@emotion/styled";

import {
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardContent as MuiCardContent,
  Box,
  Typography,
} from "@mui/material";

import FormatQuoteRoundedIcon from "@mui/icons-material/FormatQuoteRounded";

export const Card = styled(MuiCard)(({ theme }) => ({
  borderRadius: "60px 0px 60px 60px",
  width: "353px",
  height: "640px",
  position: "relative",
  overflow: "visible",
  display: "flex",
  flexDirection: "column",
  padding: 0,
  background: theme.palette.background.paper,
  "&.MuiPaper-root": {
    padding: 0,
    borderRadius: "60px 0px 60px 60px",
  },

  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },

  [theme.breakpoints.between("xs", "sm")]: {
    width: "320px",
  },

  [theme.breakpoints.up("md")]: {
    width: "353px",
    height: "640px",
  },
}));

export const CardHeader = styled(Box)({
  padding: "2.5rem 2rem 0 2.5rem",
  background: "rgba(244, 244, 244, 1)",
  borderRadius: "60px 0 0 0",
});

export const CardContent = styled(MuiCardContent)(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "2rem 4.5rem 0 2.5rem",
  background: "rgba(244, 244, 244, 1)",

  [theme.breakpoints.down("sm")]: {
    padding: "2rem 1.5rem 0 2rem",
  },
}));

export const CardMedia = styled(MuiCardMedia)({
  position: "absolute",
  right: "-2.4rem",
  top: "calc(-1rem - 50px)",
  width: "170px",
  height: "170px",
});

export const CardFooter = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 2.5rem 0 2.5rem",
  height: "130px",
  borderRadius: "0 0 60px 60px",
  backgroundColor: "white",
  [theme.breakpoints.between("md", "lg")]: {
    padding: "0 1.5rem 0 2.5rem",
  },
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.Anaheim.fontFamily,
  fontWeight: 400,
  lineHeight: "25px",
}));

export const Author = styled(Typography)({
  fontWeight: "bold",
  maxWidth: "min-content",
});

export const Quotes = styled(FormatQuoteRoundedIcon)(({ theme }) => ({
  fontSize: "35px",
  transform: "rotate(180deg)",
  borderRadius: "50%",
  backgroundColor: theme.palette.primary.main,
  color: "white",
  padding: "5px",
}));
