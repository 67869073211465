import styled from "@emotion/styled";

import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";

import { Stack, Box, Typography } from "@mui/material";

export const Description = styled(Typography)({
  maxWidth: "400px",
});

export const HeaderContainer = styled(Box)(({ theme, top: isTop }) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  padding: "10px 0",
  borderBottom: "1px solid rgba(171, 171, 171, 0.60)",

  [theme.breakpoints.up("md")]: {
    borderTop: !isTop ? "1px solid rgba(171, 171, 171, 0.60)" : null,
  },
}));

export const Hour = styled(Typography)({
  fontWeight: "bold",
});

export const Container = styled(Stack)({
  flexDirection: "column",
  gap: "0.7rem",
});

export const Clock = styled(AccessAlarmOutlinedIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "32px",
}));
