import React, { useEffect, useContext } from "react";

import { Box, Grow, Popper, ClickAwayListener } from "@mui/material";

import { LanguageContext } from "../../../context/LangContext";

import {
  LanguageButton,
  ArrowIcon,
  LanguagePaper,
  LanguageList,
  LanguageItem,
  MobileContainer,
  LanguageIcon,
} from "./styles";

import arrowIcon from "../../../img/Icons/select-arrow.svg";

import esIcon from "../../../img/Navbar/es.svg";
import enIcon from "../../../img/Navbar/en.svg";

const Language = ({ isMobile, onClick }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const { language, setLanguage } = useContext(LanguageContext);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleSelectLanguage = (event, language) => {
    setLanguage(language);
    handleClose(event);
    onClick && onClick();
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      if (anchorRef.current) {
        anchorRef.current.focus();
      }
    }
    prevOpen.current = open;
  }, [open]);

  return isMobile ? (
    <MobileContainer>
      <LanguageButton
        active={language === "es"}
        ref={anchorRef}
        onClick={(e) => handleSelectLanguage(e, "es")}
      >
        <LanguageIcon src={esIcon} alt="es language" />
      </LanguageButton>
      <LanguageButton
        active={language === "en"}
        ref={anchorRef}
        onClick={(e) => handleSelectLanguage(e, "en")}
      >
        <LanguageIcon src={enIcon} alt="en language" />
      </LanguageButton>
    </MobileContainer>
  ) : (
    <Box>
      <LanguageButton
        active={open}
        ref={anchorRef}
        id="composition-button"
        onClick={handleToggle}
      >
        <LanguageIcon
          src={language === "es" ? esIcon : enIcon}
          alt="language"
        />
        <ArrowIcon src={arrowIcon} alt="flecha de selección" expand={open} />
      </LanguageButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <LanguagePaper>
              <ClickAwayListener onClickAway={handleClose}>
                <LanguageList
                  autoFocusItem={open}
                  id="composition-menu"
                  onKeyDown={handleListKeyDown}
                >
                  <LanguageItem onClick={(e) => handleSelectLanguage(e, "es")}>
                    <LanguageIcon src={esIcon} alt="Argentina Flag" />
                  </LanguageItem>
                  <LanguageItem onClick={(e) => handleSelectLanguage(e, "en")}>
                    <LanguageIcon src={enIcon} alt="World" />
                  </LanguageItem>
                </LanguageList>
              </ClickAwayListener>
            </LanguagePaper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default Language;
