import React from "react";

import Testimonial from "../../../commons/Cards/Testimonial";
import { useMediaQuery } from "@mui/material";

import {
  TestimonialsContainer,
  CardsContainer,
  Background,
  CarrouselItem,
  Container,
} from "./styles";
import Carrousel from "../../../commons/Carrousel";

import useTranslation from "../../../hooks/useTranslation";
import { useLocation } from "react-router";

import background from "../../../img/Backgrounds/code_dots.svg";

const Testimonials = () => {
  const { pathname } = useLocation();
  const testimonials = useTranslation(pathname, "Testimonials");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const renderTestimonials = () =>
    testimonials.map((testimonial) => (
      <Testimonial
        key={`bootcamp-testimonial-${testimonial.name}`}
        {...testimonial}
      />
    ));

  const renderTestimonialsCarrouselItems = () =>
    testimonials.map((testimonial) => (
      <CarrouselItem key={`bootcamp-testimonial-${testimonial.name}`}>
        <Testimonial {...testimonial} />
      </CarrouselItem>
    ));

  return (
    <Container>
      <Background src={background} alt="testimonials background" />
      <TestimonialsContainer>
        {isDesktop ? (
          <CardsContainer>{renderTestimonials()}</CardsContainer>
        ) : (
          <Carrousel slidesToShow={isMobile ? 1 : 2}>
            {renderTestimonialsCarrouselItems()}
          </Carrousel>
        )}
      </TestimonialsContainer>
    </Container>
  );
};

export default Testimonials;
