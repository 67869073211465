import React, { useState, Fragment } from "react";

import { Box, Popper, Fade } from "@mui/material";

import {
  MenuButton,
  TextMenuButton,
  ArrowIconMenuButton,
  MenuOption,
  TextOption,
  ButtonOption,
  ArrowIconOption,
} from "./styles";

import selectArrow from "../../../../img/Icons/select-arrow.svg";

import { useLocation } from "react-router-dom";

const Option = ({
  label,
  options = [],
  href,
  children,
  placement,
  sx,
  handleNavigate,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { pathname } = useLocation();

  const handlePopperOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const SubOption = ({ label, href }) => (
    <ButtonOption
      onClick={() => {
        handlePopoverClose();
        handleNavigate(href);
      }}
    >
      <ArrowIconOption src={selectArrow} alt="flecha de selección" suboption />
      <TextOption> {label}</TextOption>
    </ButtonOption>
  );

  return (
    <Box onMouseEnter={handlePopperOpen} onMouseLeave={handlePopoverClose}>
      {children || (
        <MenuButton
          disableRipple
          onClick={() => {
            handleNavigate(href);
          }}
        >
          <TextMenuButton
            selected={open}
            active={
              pathname === href ||
              options?.some(({ href: subRef }) => subRef === pathname)
            }
          >
            {label}
          </TextMenuButton>
          {!!options.length && (
            <ArrowIconMenuButton
              collapse={open}
              src={selectArrow}
              alt="flecha de selección"
            />
          )}
        </MenuButton>
      )}
      {!!options.length && (
        <Popper
          open={open}
          anchorEl={anchorEl}
          transition
          placement={placement || "bottom-start"}
          sx={sx}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <MenuOption>
                {options.map(
                  (
                    { label: optionLabel, options: subOptions = [], href },
                    i
                  ) => (
                    <Fragment key={`${label}-menu-popper-${i}`}>
                      <ButtonOption
                        onClick={() => {
                          handlePopoverClose();
                          handleNavigate(href);
                        }}
                        key={`${label}-menu-${optionLabel}-option`}
                        disableRipple
                      >
                        <TextOption fontWeight="bold">{optionLabel}</TextOption>
                        {!!subOptions.length && (
                          <ArrowIconOption
                            src={selectArrow}
                            alt="flecha de selección"
                          />
                        )}
                      </ButtonOption>
                      {subOptions.map((subOption) => (
                        <SubOption
                          key={`${label}-menu-${optionLabel}-option-${subOption.label}}`}
                          {...subOption}
                        />
                      ))}
                    </Fragment>
                  )
                )}
              </MenuOption>
            </Fade>
          )}
        </Popper>
      )}
    </Box>
  );
};

export default Option;
