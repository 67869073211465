/** @format */

import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
//material ui
import { Button } from "@mui/material";
import { useTheme } from "@mui/styles";

//icons
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import useTranslation from "../../hooks/useTranslation";

const useStyles = (theme, mainColor, secondaryColor, iconColor) => ({
  icon: {
    p: "5px",
    height: {
      xs: "10px",
      sm: "11.25px",
      md: "12.5px",
      lg: "13.75",
      xl: "15px",
    },
    width: { xs: "10px", sm: "11.25px", md: "12.5px", lg: "13.75", xl: "15px" },
    borderRadius: "58px",
    bgcolor: theme.palette.white.secondary,
    color: `${iconColor}`,
    ml: { xs: "3px", sm: "6px", md: "9px", lg: "12px", xl: "15px" },
  },
  button: {
    borderRadius: "100px",
    color: theme.palette.white.secondary,
    background: `linear-gradient(103deg, ${mainColor}, ${secondaryColor})`,
    fontSize: {
      xs: "14px",
      sm: "14.5px",
      md: "15px",
      lg: "15.5px",
      xl: "16px",
    },
    lineHeight: {
      xs: "25.2px",
      sm: "27",
      md: "28.8",
      lg: "30.6",
      xl: "32.4px",
    },
    height: {
      xs: "38px",
      sm: "42.25px",
      md: "46.5px",
      lg: "50.75px",
      xl: "55px",
    },
    width: {
      xs: "156px",
      sm: "179.5px",
      md: "203px",
      lg: "226.5",
      xl: "250px",
    },
    textTransform: "none",
    "&:hover": {
      color: theme.palette.white.secondary,
      background: `linear-gradient(103deg, ${secondaryColor}, ${mainColor}`,
    },
    letterSpacing: { xs: "0.28px", md: "0.36px" },
  },
});

const MoreInfoButton = ({
  mb,
  mt,
  navigateTo,
  mainColor,
  secondaryColor,
  iconColor,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme, mainColor, secondaryColor, iconColor);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { buttonText } = useTranslation(pathname, "MoreInfoButton");

  const goTo = () => {
    navigate(navigateTo);
    window.scrollTo({ top: 0 });
  };

  return (
    <Button
      endIcon={<ArrowForwardIosIcon sx={classes.icon} />}
      variant="contained"
      sx={{ ...classes.button, mb, mt }}
      onClick={goTo}
    >
      {buttonText}
    </Button>
  );
};

export default MoreInfoButton;
