import styled from "@emotion/styled";

import { AppBar as MuiAppBar, Toolbar as MuiToolbar } from "@mui/material";

export const AppBar = styled(MuiAppBar)(({ theme }) => ({
  background: theme.palette.background.lightGray,
}));

export const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  justifyContent: "space-between",
  minHeight: "90px !important",
  flexDirection: "row",
  padding: "0 2rem !important",

  "@media (min-width:960px)": {
    padding: "0 1rem !important",
  },

  [theme.breakpoints.up("lg")]: {
    padding: "0 3rem !important",
  },
}));

export const Logo = styled("img")({
  width: "40px",
  height: "40px",

  "@media (min-width:960px)": {
    width: "180px",
    height: "43px",
  },
});
