import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import TranslationText from "../Translations/TranslationText";
import { useTheme } from "@mui/styles";

const useStyles = (theme, isMobile, clarification) => ({
  container: {
    boxSizing: "border-box",
    maxWidth: "22.0625rem",
    borderRadius: "3.75rem",
    background: "linear-gradient(180deg, #F4F4F4 0%, #FFF 100%)",
    boxShadow: "0.3125rem 0.3125rem 1.25rem 0rem rgba(79, 79, 79, 0.20)",
    p: isMobile ? "2.5rem 2.25rem" : "2.5rem 2.5rem 3.5rem 2.5rem",
  },
  subtitle: {
    container: {
      mb: "0.25rem",
    },
    bold: {
      color: theme.palette.lightBlue,
      leadingTrim: "both",
      textEdge: "cap",
      fontSize: "1.0625rem",
      fontFamily: "Anaheim",
      lineHeight: "0",
      fontWeight: 600,
    },
  },
  title: {
    container: {
      mb: "1.25rem",
    },
    bold: {
      color: theme.palette.primary.main,
      leadingTrim: "both",
      textEdge: "cap",
      fontSize: "1.5625rem",
      fontFamily: "Montserrat",
      fontWeight: 700,
      fontVariant: "all-small-caps",
      lineHeight: "0",
    },
  },
  description: {
    container: {
      mb: "1.25rem",
    },
    normal: {
      color: theme.palette.primary.main,
      leadingTrim: "both",
      textEdge: "cap",
      fontSize: "1rem",
      fontFamily: "Montserrat",
    },
    bold: {
      color: theme.palette.primary.main,
      leadingTrim: "both",
      textEdge: "cap",
      fontSize: "1rem",
      fontFamily: "Montserrat",
      fontWeight: 700,
    },
  },
  clarification: {
    container: {
      textAlign: "center",
      borderTop: "0.0625rem dashed rgba(171, 171, 171, 0.60)",
      borderBottom: clarification
        ? "0.0625rem dashed rgba(171, 171, 171, 0.60)"
        : undefined,
    },
    bold: {
      color: theme.palette.primary.main,
      fontSize: "0.875rem",
      fontFamily: "Montserrat",
      fontWeight: 700,
    },
  },
  pricing: {
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      py: clarification ? "1.25rem" : "2rem",
      mb: "1.5rem",
      borderBottom: "0.0625rem dashed rgba(171, 171, 171, 0.60)",
    },
    textContainer: {},
    bold: {
      color: theme.palette.primary.main,
      fontSize: "1rem",
      fontFamily: "Montserrat",
      fontWeight: 700,
    },
    discount: {
      container: {},
      bold: {
        color: "#FF062E",
        leadingTrim: "both",
        textEdge: "cap",
        fontSize: "1rem",
        fontFamily: "Montserrat",
        fontWeight: 700,
        textDecorationLine: "line-through",
      },
    },
  },
  price: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  finePrint: {
    container: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "5%",
    },
    normal: {
      color: theme.palette.primary.main,
      fontSize: "0.875rem",
      fontFamily: "Montserrat",
      fontWeight: 400,
    },
    bold: {
      color: theme.palette.primary.main,
      fontSize: "0.875rem",
      fontFamily: "Montserrat",
      fontWeight: 700,
    },
  },
  button: {
    maxHeight: "2.5rem",
    padding: 0,
    margin: 0,
    width: "auto",
    justifyContent: "center",
    alignItems: "center",
    "& > span": {
      margin: 0,
    },
  },
});

const FundingCard = ({
  title,
  subtitle,
  description,
  clarification,
  finePrint,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:56.1875rem)");
  const classes = useStyles(theme, isMobile, clarification);

  return (
    <Box sx={classes.container}>
      <TranslationText
        textSx={classes.subtitle}
        sx={classes.subtitle.container}
      >
        {subtitle}
      </TranslationText>
      <TranslationText textSx={classes.title} sx={classes.title.container}>
        {title}
      </TranslationText>
      <TranslationText
        textSx={classes.description}
        sx={classes.description.container}
      >
        {description}
      </TranslationText>
      <TranslationText
        textSx={classes.clarification}
        sx={classes.clarification.container}
      >
        {clarification}
      </TranslationText>
      {/* <Box sx={classes.pricing.container}>
        {pricing.map(([price, discount], i) => (
          <Box sx={classes.price} key={price + i}>
            <TranslationText
              textSx={classes.pricing}
              sx={classes.pricing.textContainer}
            >
              {price}
            </TranslationText>
            {discount && (
              <TranslationText
                textSx={classes.pricing.discount}
                sx={classes.pricing.discount.container}
              >
                {discount}
              </TranslationText>
            )}
          </Box>
        ))}
      </Box> */}
      <Box sx={classes.finePrint.container}>
        <TranslationText textSx={classes.finePrint}>
          {finePrint}
        </TranslationText>
      </Box>
    </Box>
  );
};

export default FundingCard;
