import React from "react";
import { Container, Text } from "./styles";
import { useMediaQuery } from "@mui/material";

const LaborInsertionCard = ({ index, img, text }) => {
  const isMobile = useMediaQuery("(max-width:899px)");
  return (
    <Container ismobile={isMobile}>
      <img src={img} alt={`job placement ${index + 1}`} />
      <Text>{text}</Text>
    </Container>
  );
};

export default LaborInsertionCard;
