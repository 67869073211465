import React from "react";
import { useTheme } from "@mui/styles";
import { Box, useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import useTranslation from "../hooks/useTranslation";
import TranslationText from "../components/Translations/TranslationText";

const useStyles = (theme, isMobile) => ({
  container: {
    position: "relative",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "73.5%",
    minHeight: "144px",
    margin: isMobile ? "-40px auto 0 auto" : "-72px auto 0 auto",
    borderRadius: "30px",
    background: "linear-gradient(180deg, #F4F4F4 0%, #FFF 100%)",
    boxShadow: "5px 5px 20px 0px rgba(79, 79, 79, 0.20)",
    p: isMobile
      ? "24px 56px"
      : { xl: "24px 15%", lg: "24px 9%", md: "24px 7.5%", sm: "24px 10%" },
  },
  content: {
    container: {
      textAlign: "center",
    },
    normal: {
      color: theme.palette.primary.main,
      textAlign: "center",
      fontSize: isMobile ? "16px" : "22px",
      fontFamily: "Montserrat",
      lineHeight: isMobile ? "20px" : "36px",
    },
    bold: {
      color: theme.palette.primary.main,
      textAlign: "center",
      fontWeight: 700,
      fontSize: isMobile ? "16px" : "22px",
      fontFamily: "Montserrat",
      lineHeight: isMobile ? "20px" : "36px",
    },
  },
  dotImg: {
    position: "absolute",
    bottom: isMobile
      ? "-32px"
      : {
          xs: "-72px",
          sm: "-72px",
          md: "-120px",
          lg: "-120px",
          xl: "-120px",
        },
    right: "-18%",
    zIndex: -1,
  },
});

const OverlapText = ({ componentName }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:668px)");
  const classes = useStyles(theme, isMobile);
  const { pathname } = useLocation();
  const translation = useTranslation(pathname, componentName);
  const { dotBackgroundImg, content } = translation;

  return (
    <Box sx={classes.container}>
      <TranslationText sx={classes.content.container} textSx={classes.content}>
        {isMobile ? content.mobile : content.desktop}
      </TranslationText>
      <Box
        component="img"
        src={dotBackgroundImg.src}
        alt={dotBackgroundImg.alt}
        sx={classes.dotImg}
      />
    </Box>
  );
};

export default OverlapText;
