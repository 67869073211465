import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Box, Divider, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import socialPlatforms from "../../helpers/socialPlatforms";
import Logo from "../../img/Footer/LogoP5Footer.svg";

const useStyles = (theme, isMobile) => ({
  footer: {
    bosSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    backgroundColor: "#001F55",
    padding: isMobile ? "30px 0" : "30px 100px",
  },
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: isMobile ? "column" : "row",
    flexWrap: "wrap",
    justifyContent: isMobile ? "center" : "space-between",
    width: "100%",
  },
  firstSection: {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "center",
    alignItems: "center",
    gap: isMobile ? 0 : "68px",
  },
  secondSection: {
    flexGrow: 1,
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "center",
    alignItems: "center",
    gap: isMobile ? "28px" : 0,
  },
  thirdSection: {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "center",
    alignItems: "center",
    gap: isMobile ? 0 : "68px",
  },
  containerLogos: {
    box: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    logos: {
      display: "flex",
      justifyContent: isMobile ? "space-around" : "space-between",
    },
    logoImg: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "16px",
      "&:last-child": {
        marginRight: "0",
      },
    },
  },
  containerContacts: {
    box: {
      display: "flex",
      flexDirection: "column",
      alignItems: isMobile ? "center" : "",
    },
    links: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textDecoration: "none",
      color: "#f4f4f4",
      margin: 0,
    },
    typography: {
      FontFamily: theme.typography.montserrat.regular,
      textDecoration: "underline",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "50px",
      color: "#f4f4f4",
      opacity: "0.75",
    },
  },
  containerSections: {
    box: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
      background: "red",
    },
    typography: {
      FontFamily: theme.typography.montserrat.regular,
      textDecoration: "underline",
      textAlign: isMobile ? "center" : "",
      color: "#f4f4f4",
      fontSize: "1 rem",
      margin: "auto",
    },
    links: {
      color: "#f4f4f4",
      cursor: "pointer",
    },
  },
  dividerDesktop: {
    borderWidth: ".05rem",
    height: isMobile ? "0" : "96px",
    width: isMobile ? "20rem" : "0",
    margin: isMobile ? "2rem .5rem" : "",
    background: "#f4f4f4",
    opacity: "0.55",
  },
  copyRight: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    lineHeight: "1.5rem",
    color: "#f4f4f4",
    width: "100%",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "15px",
    opacity: "0.75",
  },
});

const Footer = () => {
  const isMobile = useMediaQuery("(max-width:1222px)");
  const theme = useTheme();
  const classes = useStyles(theme, isMobile);

  return (
    <footer style={classes.footer}>
      <Box sx={classes.container}>
        <Box sx={classes.firstSection}>
          <img src={Logo} alt="Plataforma 5 Logo" height="46px" />
          <Divider variant="middle" sx={classes.dividerDesktop} />
        </Box>

        <Box sx={classes.secondSection}>
          {isMobile && (
            <Box sx={classes.containerLogos.box}>
              {socialPlatforms.map((social) => (
                <Box
                  component="a"
                  sx={classes.containerLogos.logoImg}
                  key={uuidv4()}
                  href={social.link}
                  rel={social.rel}
                  target={social.target}
                >
                  <img
                    src={social.img}
                    alt={social.alt}
                    title={social.title}
                    style={{ height: "24px" }}
                  />
                </Box>
              ))}
            </Box>
          )}

          {!isMobile && (
            <Box style={{ flexDirection: "column", textAlign: "center" }}>
              <Typography style={classes.copyRight}>
                Copyright © Plataforma 5 SAS | Políticas de Privacidad
              </Typography>
              <a
                style={classes.containerContacts.links}
                href="mailto:contacto@plataforma5.la"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Typography sx={classes.containerContacts.typography}>
                  contacto@plataforma5.la
                </Typography>
              </a>
            </Box>
          )}
        </Box>

        <Box sx={classes.thirdSection}>
          <Divider variant="middle" sx={classes.dividerDesktop} />

          {!isMobile && (
            <Box sx={classes.containerLogos.box}>
              {socialPlatforms.map((social) => (
                <Box
                  component="a"
                  sx={classes.containerLogos.logoImg}
                  key={uuidv4()}
                  href={social.link}
                  rel={social.rel}
                  target={social.target}
                >
                  <img
                    src={social.img}
                    alt={social.alt}
                    title={social.title}
                    style={{ height: "24px" }}
                  />
                </Box>
              ))}
            </Box>
          )}

          {isMobile && (
            <>
              <Typography style={classes.copyRight}>
                Copyright © Plataforma 5 SAS
                <br />
                Políticas de Privacidad
              </Typography>
              <a
                style={classes.containerContacts.links}
                href="mailto:contacto@plataforma5.la"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Typography sx={classes.containerContacts.typography}>
                  contacto@plataforma5.la
                </Typography>
              </a>
            </>
          )}
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
