import React from "react";

import { Typography, Box } from "@mui/material";

import { Clock, Container, HeaderContainer, Description, Hour } from "./styles";

const Activity = ({ hour, name, description, isTop }) => {
  return (
    <Container>
      <HeaderContainer top={isTop}>
        <Clock />
        <Box>
          <Hour>{hour}</Hour>
          <Typography>{name}</Typography>
        </Box>
      </HeaderContainer>
      <Description variant="body1">{description}</Description>
    </Container>
  );
};

export default Activity;
