export const buttonStyles = {
  MuiButton: {
    styleOverrides: {
      root: () => ({
        textTransform: "uppercase",
        borderRadius: "20px",
        fontSize: "14px",
        fontWeight: "bold",
      }),
    },
    variants: [
      {
        props: {
          variant: "primary",
        },
        style: {
          backgroundColor: "#001f55",
          color: "white",
          "&:hover": {
            backgroundColor: "rgba(0, 31, 85, 0.50)",
          },
          "&.Mui-disabled": {
            backgroundColor: "rgba(0, 31, 85, 0.50)",
            color: "rgba(255, 255, 255, 0.50)",
          },
          "&.Mui-disabled .MuiButton-endIcon": {
            opacity: 0.5,
            color: "rgba(0, 31, 85, 0.50)",
          },
          "&.Mui-disabled .MuiButton-endIcon svg": {
            color: "rgba(0, 31, 85, 0.50)",
          },
        },
      },
      {
        props: {
          variant: "secondary",
        },
        style: {
          backgroundColor: "#FF012B",
          color: "white",
          "&:hover": {
            backgroundColor: "rgba(207, 0, 0, 1)",
          },
          "&.Mui-disabled": {
            backgroundColor: "rgba(255, 1, 43, 0.50)",
            color: "#FFF",
          },
          "&.Mui-disabled .MuiButton-endIcon": {
            opacity: 0.5,
            color: "rgba(255, 1, 43, 0.5)",
          },
          "&.Mui-disabled .MuiButton-endIcon svg": {
            color: "rgba(255, 1, 43, 0.5)",
          },
        },
      },
    ],
  },
};
