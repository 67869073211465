import React from "react";
import Certification from "../components/IntroductoryCourse/Certification";
import Modalities from "../components/IntroductoryCourse/Modalities";
import Booking from "../components/IntroductoryCourse/Booking";
import Hero from "../commons/Hero";
import OverlapText from "../commons/OverlapText";
import { Box } from "@mui/material";
import DescriptionBanner from "../commons/DescriptionBanner";
import Introduction from "../components/IntroductoryCourse/Introduction";
import Tools from "../components/IntroductoryCourse/Tools/Tools";

const useStyles = () => ({
  container: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    overflow: "hidden",
  },
});

const IntroductoryCourse = () => {
  const classes = useStyles();

  return (
    <Box sx={classes.container}>
      <Hero />
      <OverlapText componentName="IntroText" />
      <Introduction />
      <DescriptionBanner />
      <Tools />
      <Modalities />
      <Certification />
      <Booking />
    </Box>
  );
};

export default IntroductoryCourse;
