import { Box, SvgIcon, Typography } from "@mui/material";
import React, { useContext } from "react";
import starredIcon from "../../img/starred.png";
import { LanguageContext } from "../../context/LangContext";
import { useTheme } from "@mui/styles";

const resize = (pxValue, baseSize) => {
  if (pxValue.split(" ").length > 1) {
    return pxValue
      .split(" ")
      .map((px) => `${parseFloat(px.replace("px", "")) * baseSize}px`)
      .join(" ");
  } else {
    return `${parseFloat(pxValue.replace("px", "")) * baseSize}px`;
  }
};

const useStyles = (theme, language) => ({
  container: {
    minWidth: {
      xs: "0",
      md: resize("674px", 0.6),
      lg: resize("674px", 0.625),
      xl: resize("674px", 0.85),
    },
    minHeight: {
      xs: resize("837px", 0.7),
      md: resize("837px", 0.6),
      lg: resize("837px", 0.625),
      xl: resize("837px", 0.85),
    },
    display: "flex",
    flexDirection: "column",
    borderRadius: {
      xs: resize("60px", 0.7),
      md: resize("60px", 0.6),
      lg: resize("60px", 0.625),
      xl: resize("60px", 0.85),
    },
    userSelect: "none",
    boxShadow: {
      xs: `${resize("0px", 0.7)} ${resize("10px", 0.7)} ${resize(
        "54px",
        0.7
      )} rgba(3, 21, 51, 0.16);`,
      md: `${resize("0px", 0.6)} ${resize("10px", 0.6)} ${resize(
        "54px",
        0.6
      )} rgba(3, 21, 51, 0.16);`,
      lg: `${resize("0px", 0.625)} ${resize("10px", 0.625)} ${resize(
        "54px",
        0.625
      )} rgba(3, 21, 51, 0.16);`,
      xl: `${resize("0px", 0.85)} ${resize("10px", 0.85)} ${resize(
        "54px",
        0.85
      )} rgba(3, 21, 51, 0.16);`,
    },
    mx: {
      xs: resize("32px", 0.7),
      md: resize("0px", 0.6),
      lg: resize("0px", 0.625),
      xl: resize("0px", 0.85),
    },
    mt: {
      xs: resize("50px", 0.7),
      md: resize("50px", 0.6),
      lg: resize("50px", 0.625),
      xl: resize("50px", 0.85),
    },
  },
  infoContainer: {
    borderRadius: {
      xs: resize("60px", 0.7),
      md: resize("60px", 0.6),
      lg: resize("60px", 0.625),
      xl: resize("60px", 0.85),
    },
    padding: {
      xs: resize("40px 40px 10px 40px", 0.7),
      md: resize("40px 40px 10px 40px", 0.6),
      lg: resize("40px 40px 10px 40px", 0.625),
      xl: resize("40px 40px 10px 40px", 0.85),
    },
  },
  title: {
    mx: "auto",
    whiteSpace: "nowrap",
    width: {
      xs: "100%",
      md: resize("381px", 0.6),
      lg: resize("381px", 0.625),
      xl: resize("381px", 0.85),
    },
    padding: {
      xs: resize("9.5px 0px", 0.7),
      md: 0,
      lg: 0,
      xl: 0,
    },
    minHeight: {
      xs: resize("41px", 0.7),
      md: resize("41px", 0.6),
      lg: resize("41px", 0.625),
      xl: resize("41px", 0.85),
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: {
      xs: resize("8px", 0.7),
      md: resize("8px", 0.6),
      lg: resize("8px", 0.625),
      xl: resize("8px", 0.85),
    },
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: {
      xs: resize("25px", 0.7),
      md: resize("25px", 0.6),
      lg: resize("25px", 0.625),
      xl: resize("25px", 0.85),
    },
    lineHeight: {
      xs: resize("24px", 0.7),
      md: resize("24px", 0.6),
      lg: resize("24px", 0.625),
      xl: resize("24px", 0.85),
    },
    color: "#FFFFFF",
    background: "linear-gradient(180deg, #001F55 0%, #04132D 100%)",
    borderRadius: "19px",
    mb: {
      xs: resize("20px", 0.7),
      md: resize("20px", 0.6),
      lg: resize("20px", 0.625),
      xl: resize("20px", 0.85),
    },
  },
  emphasis: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: {
      xs: resize("25px", 0.7),
      md: resize("25px", 0.6),
      lg: resize("25px", 0.625),
      xl: resize("25px", 0.85),
    },
    lineHeight: {
      xs: resize("24px", 0.7),
      md: resize("24px", 0.6),
      lg: resize("24px", 0.625),
      xl: resize("24px", 0.85),
    },
    textAlign: "center",
    color: "#FFFFFF",
    borderRadius: {
      xs: resize("19px", 0.7),
      md: resize("19px", 0.6),
      lg: resize("19px", 0.625),
      xl: resize("19px", 0.85),
    },
    fontWeight: 700,
  },
  descriptionText: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: language === "es" ? 400 : 700,
    fontSize: {
      xs: resize("25px", 0.7),
      md: resize("25px", 0.6),
      lg: resize("25px", 0.625),
      xl: resize("25px", 0.85),
    },
    lineHeight: {
      xs: resize("40px", 0.7),
      md: resize("40px", 0.6),
      lg: resize("40px", 0.625),
      xl: resize("40px", 0.85),
    },
    textAlign: "center",
    color: "#001F55",
  },
  priceText: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: {
      xs: resize("50px", 0.7),
      md: resize("50px", 0.6),
      lg: resize("50px", 0.625),
      xl: resize("50px", 0.85),
    },
    lineHeight: {
      xs: resize("75px", 0.7),
      md: resize("75px", 0.6),
      lg: resize("75px", 0.625),
      xl: resize("75px", 0.85),
    },
    textAlign: "center",
    color: "#001F55",
    mb: {
      xs: resize("7px", 0.7),
      md: resize("7px", 0.6),
      lg: resize("7px", 0.625),
      xl: resize("7px", 0.85),
    },
  },
  promotionPriceText: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: {
      xs: resize("50px", 0.7),
      md: resize("50px", 0.6),
      lg: resize("50px", 0.625),
      xl: resize("50px", 0.85),
    },
    lineHeight: {
      xs: resize("75px", 0.7),
      md: resize("75px", 0.6),
      lg: resize("75px", 0.625),
      xl: resize("75px", 0.85),
    },
    textAlign: "center",
    color: "#001F55",
    mb: {
      xs: resize("7px", 0.7),
      md: resize("7px", 0.6),
      lg: resize("7px", 0.625),
      xl: resize("7px", 0.85),
    },
    // background: `linear-gradient(to left, #534015 22%,#634f2c 24%, #cfc09f 26%, #cfc09f 27%,#f3c22fe8 40%,#bb9447cc 78%); `,
    // backgroundClip: "text",
    // textFillColor: "transparent",
    // textDecoration: "underline overline #001F55",
    // textDecoration: "underline #001F55",
    // "-webkitTextStrokeWidth": "0.5px",
    // "-webkitTextStrokeColor": "#001F55",
    // textShadow: "0 0 3px #001f5513, 0 0 5px #001f5511"
  },
  basePriceText: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: {
      xs: resize("50px", 0.7),
      md: resize("50px", 0.6),
      lg: resize("50px", 0.625),
      xl: resize("50px", 0.85),
    },
    lineHeight: {
      xs: resize("75px", 0.7),
      md: resize("75px", 0.6),
      lg: resize("75px", 0.625),
      xl: resize("75px", 0.85),
    },
    textAlign: "center",
    color: "#001F55",
    mb: {
      xs: resize("7px", 0.7),
      md: resize("7px", 0.6),
      lg: resize("7px", 0.625),
      xl: resize("7px", 0.85),
    },
    textDecoration: "line-through",
  },
  installmentText: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: language === "es" ? "500" : "700",
    fontSize: {
      xs: resize("20px", 0.7),
      md: resize("20px", 0.6),
      lg: resize("20px", 0.625),
      xl: resize("20px", 0.85),
    },
    lineHeight: {
      xs: resize("24px", 0.7),
      md: resize("24px", 0.6),
      lg: resize("24px", 0.625),
      xl: resize("24px", 0.85),
    },
    mb: {
      xs: resize("10px", 0.7),
      md: resize("10px", 0.6),
      lg: resize("10px", 0.625),
      xl: resize("10px", 0.85),
    },
    textAlign: "center",
    textTransform: "uppercase",
    color: language === "es" ? "#001F55" : "#FF062E",
  },
  promotionText: {
    mx: "auto",
    whiteSpace: "nowrap",
    width: {
      xs: "100%",
      md: resize("381px", 0.6),
      lg: resize("381px", 0.625),
      xl: resize("381px", 0.85),
    },
    padding: {
      xs: resize("9.5px 0px", 0.7),
      md: 0,
      lg: 0,
      xl: 0,
    },
    minHeight: {
      xs: resize("41px", 0.7),
      md: resize("41px", 0.6),
      lg: resize("41px", 0.625),
      xl: resize("41px", 0.85),
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: {
      xs: resize("8px", 0.7),
      md: resize("8px", 0.6),
      lg: resize("8px", 0.625),
      xl: resize("8px", 0.85),
    },
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: {
      xs: resize("25px", 0.7),
      md: resize("25px", 0.6),
      lg: resize("25px", 0.625),
      xl: resize("25px", 0.85),
    },
    lineHeight: {
      xs: resize("24px", 0.7),
      md: resize("24px", 0.6),
      lg: resize("24px", 0.625),
      xl: resize("24px", 0.85),
    },
    borderRadius: "19px",
    mb: {
      xs: resize("20px", 0.7),
      md: resize("20px", 0.6),
      lg: resize("20px", 0.625),
      xl: resize("20px", 0.85),
    },
    position: "relative",
    color: theme.palette.white.main,
    backgroundColor: "#1C7AD9",
  },
  starsEmoji: {
    // position: "absolute",
    right: {
      xs: resize("0", 0.4),
      md: resize("0", 0.5),
      lg: resize("0", 0.525),
      xl: resize("0", 0.65),
    },
    top: {
      xs: resize("0", 0.7),
      md: resize("0", 0.6),
      lg: resize("0", 0.625),
      xl: resize("0", 0.85),
    },
    width: {
      xs: resize("50px", 0.5),
      md: resize("50px", 0.4),
      lg: resize("50px", 0.425),
      xl: resize("50px", 0.55),
    },
  },
  subContainer: {
    flexGrow: 1,
    p: {
      xs: resize("37px 40px", 0.7),
      md: resize("37px 40px", 0.6),
      lg: resize("37px 40px", 0.625),
      xl: resize("37px 40px", 0.85),
    },
    background: "#EFF1F4",
    borderRadius: {
      xs: resize("0px 0px 60px 60px", 0.7),
      md: resize("0px 0px 60px 60px", 0.6),
      lg: resize("0px 0px 60px 60px", 0.625),
      xl: resize("0px 0px 60px 60px", 0.85),
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: {
      xs: "center",
      md: "flex-start",
      lg: "flex-start",
      xl: "flex-start",
    },
  },
  itemsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: {
      xs: "center",
      md: "flex-start",
      lg: "flex-start",
      xl: "flex-start",
    },
    gap: {
      xs: resize("30px", 0.7),
      md: resize("0px", 0.6),
      lg: resize("0px", 0.625),
      xl: resize("0px", 0.85),
    },
  },
  item: {
    width: { xs: "100%", md: "70%", lg: "70%", xl: "70%" },
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: "auto",
    gap: {
      xs: resize("0px 30px", 0.7),
      md: resize("0px 30px", 0.6),
      lg: resize("0px 30px", 0.625),
      xl: resize("0px 30px", 0.85),
    },
  },
  itemText: {
    whiteSpace: "nowrap",
    fontFamily: "Anaheim",
    fontStyle: "normal",

    fontWeight: 400,
    fontSize: {
      xs: resize("29px", 0.7),
      md: resize("29px", 0.6),
      lg: resize("29px", 0.625),
      xl: resize("29px", 0.85),
    },
    lineHeight: {
      xs: resize("75px", 0.7),
      md: resize("75px", 0.6),
      lg: resize("75px", 0.625),
      xl: resize("75px", 0.85),
    },
    color: "#001F55",
  },
  tickIcon: {
    width: {
      xs: resize("29px", 0.7),
      md: resize("29px", 0.6),
      lg: resize("29px", 0.625),
      xl: resize("29px", 0.85),
    },
    height: {
      xs: resize("29px", 0.7),
      md: resize("29px", 0.6),
      lg: resize("29px", 0.625),
      xl: resize("29px", 0.85),
    },
  },
  crossIcon: {
    width: {
      xs: resize("29px", 0.7),
      md: resize("29px", 0.6),
      lg: resize("29px", 0.625),
      xl: resize("29px", 0.85),
    },
    height: {
      xs: resize("29px", 0.7),
      md: resize("29px", 0.6),
      lg: resize("29px", 0.625),
      xl: resize("29px", 0.85),
    },
  },
  applyButton: {
    mt: {
      xs: resize("43px", 0.7),
      md: resize("43px", 0.6),
      lg: resize("43px", 0.625),
      xl: resize("43px", 0.85),
    },
    p: {
      xs: resize("18px 37px", 0.7),
      md: resize("18px 37px", 0.6),
      lg: resize("18px 37px", 0.625),
      xl: resize("18px 37px", 0.85),
    },
    borderRadius: {
      xs: resize("100px", 0.7),
      md: resize("100px", 0.6),
      lg: resize("100px", 0.625),
      xl: resize("100px", 0.85),
    },
    height: "auto",
    width: "auto",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: {
      xs: resize("20px", 0.7),
      md: resize("20px", 0.6),
      lg: resize("20px", 0.625),
      xl: resize("20px", 0.85),
    },
    lineHeight: "180%",
    letterSpacing: {
      xs: resize("0.4px", 0.7),
      md: resize("0.4px", 0.6),
      lg: resize("0.4px", 0.625),
      xl: resize("0.4px", 0.85),
    },
    color: "#F4F4F4",
    background: "linear-gradient(135deg, #F32C4D 0%, #D00E2E 100%)",
    alignSelf: "center",
    gap: {
      xs: resize("14px", 0.7),
      md: resize("14px", 0.6),
      lg: resize("14px", 0.625),
      xl: resize("14px", 0.85),
    },
    minWidth: {
      xs: resize("363px", 0.7),
      md: resize("363px", 0.6),
      lg: resize("363px", 0.625),
      xl: resize("363px", 0.85),
    },
  },
  applyButtonIcon: {
    maxHeight: "none",
    maxWidth: "none",
    ml: {
      xs: resize("-8px", 0.7),
      md: resize("-8px", 0.6),
      lg: resize("-8px", 0.625),
      xl: resize("-8px", 0.85),
    },
    mr: {
      xs: resize("4px", 0.7),
      md: resize("4px", 0.6),
      lg: resize("4px", 0.625),
      xl: resize("4px", 0.85),
    },
    mt: "0",
    mb: "0",
    borderRadius: "50%",
    width: {
      xs: resize("18px", 0.7),
      md: resize("18px", 0.6),
      lg: resize("18px", 0.625),
      xl: resize("18px", 0.85),
    },
    height: {
      xs: resize("18px", 0.7),
      md: resize("18px", 0.6),
      lg: resize("18px", 0.625),
      xl: resize("18px", 0.85),
    },
  },
  withStarred: {
    p: {
      xs: resize("0px 40px 37px 40px", 0.7),
      md: resize("0px 40px 37px 40px", 0.6),
      lg: resize("0px 40px 37px 40px", 0.625),
      xl: resize("0px 40px 37px 40px", 0.85),
    },
  },
  starredIcon: {
    width: {
      xs: resize("104px", 0.7),
      md: resize("96px", 0.6),
      lg: resize("96px", 0.625),
      xl: resize("96px", 0.85),
    },
    mt: {
      xs: resize("48px", 0.7),
      md: resize("20px", 0.6),
      lg: resize("20px", 0.625),
      xl: resize("20px", 0.85),
    },
    mb: {
      xs: resize("20px", 0.7),
      md: 0,
      lg: 0,
      xl: 0,
    },
    alignSelf: "center",
  },
  bold: {
    fontWeight: 700,
  },
});

const ModalityCard = ({
  title,
  description,
  discount = null,
  benefits,
  starred,
  sx,
}) => {
  const { language } = useContext(LanguageContext);
  const theme = useTheme();
  const classes = useStyles(theme, language);

  if (discount) {
    discount.price = (discount.price && parseInt(discount.price)) || null;
    discount.percentage =
      (discount.percentage && parseInt(discount.percentage)) || null;
  }

  return (
    <Box sx={{ ...classes.container, ...sx }}>
      <Box sx={classes.infoContainer}>
        <Typography variant="h2" sx={classes.title}>
          {title[0]}
          <Typography variant="span" sx={classes.emphasis}>
            {title[1]}
          </Typography>
        </Typography>
        <Typography variant="h2" sx={classes.descriptionText}>
          {description}
        </Typography>
      </Box>
      <Box sx={classes.subContainer}>
        <Box sx={classes.itemsContainer}>
          {benefits.map(({ description, isPro, isCon }) => (
            <Box sx={classes.item} key={description}>
              {isCon ? (
                <SvgIcon
                  sx={classes.crossIcon}
                  viewBox="0 0 29 29"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5 0C6.50478 0 0 6.50478 0 14.5C0 22.4952 6.50478 29 14.5 29C22.4952 29 29 22.4952 29 14.5C29 6.50478 22.4952 0 14.5 0Z"
                    fill="#FF062E"
                  />
                  <rect
                    x="20.7285"
                    y="8"
                    width="1"
                    height="18"
                    transform="rotate(45 20.7285 8)"
                    fill="#EFF1F4"
                    stroke="#EFF1F4"
                  />
                  <rect
                    x="21.4355"
                    y="20.728"
                    width="1"
                    height="18"
                    transform="rotate(135 21.4355 20.728)"
                    fill="#EFF1F4"
                    stroke="#EFF1F4"
                  />
                </SvgIcon>
              ) : (
                <SvgIcon
                  sx={classes.tickIcon}
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5 0C6.50478 0 0 6.50478 0 14.5C0 22.4952 6.50478 29 14.5 29C22.4952 29 29 22.4952 29 14.5C29 6.50478 22.4952 0 14.5 0ZM11.851 21.7765L6.23221 15.5324L7.89065 14.0399L11.7945 18.3774L20.85529 7.35178L22.7636 8.78365L11.851 21.7765Z"
                    fill="url(#paint0_linear_57_1368)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_57_1368"
                      x1="14.5"
                      y1="0"
                      x2="14.5"
                      y2="29"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#19B9A9" />
                      <stop offset="1" stopColor="#07C5B1" />
                    </linearGradient>
                  </defs>
                </SvgIcon>
              )}
              <Typography
                sx={
                  isPro || isCon
                    ? { ...classes.itemText, ...classes.bold }
                    : { ...classes.itemText }
                }
              >
                {description}
              </Typography>
            </Box>
          ))}
        </Box>
        {starred && (
          <Box component="img" src={starredIcon} sx={classes.starredIcon} />
        )}
      </Box>
    </Box>
  );
};

export default ModalityCard;
