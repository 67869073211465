import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import TranslationSectionTitle from "../../commons/TranslationSectionTitle";
import TranslationText from "../Translations/TranslationText";
import AccordionBox from "../AccordionBox/AccordionBox";
import useTranslation from "../../hooks/useTranslation";
import { useLocation } from "react-router-dom";
import Link from "../../commons/Link";

const useStyles = (theme, isMobile) => ({
  container: {
    boxSizing: "border-box",
    backgroundColor: theme.palette.background.lightGray,
    padding: isMobile
      ? "64px 36px"
      : {
          sm: "64px 104px",
          md: "64px 120px",
          lg: "64px 152px",
          xl: "64px 184px",
        },
  },
  description: {
    container: {
      m: "32px 0",
      textAlign: "center",
    },
    normal: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "22px",
      lineHeight: "36px",
    },
  },
  linkContainer: {
    display: "flex",
    justifyContent: "center",
  },
});

const AccordionProgram = ({ programRef }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:1064px)");
  const classes = useStyles(theme, isMobile);
  const { pathname } = useLocation();
  const { title, subtitle, description, link } = useTranslation(
    pathname,
    "AccordionProgram"
  );

  return (
    <Box ref={programRef} sx={classes.container} id="program">
      <TranslationSectionTitle title={title} subtitle={subtitle} />
      <TranslationText
        textSx={{ normal: classes.description.normal }}
        sx={classes.description.container}
      >
        {isMobile ? description.mobile : description.desktop}
      </TranslationText>
      <AccordionBox />
      <Box sx={classes.linkContainer}>
        <Link href={link.href} target="_blank" rel="noreferrer">
          {link.text}
        </Link>
      </Box>
    </Box>
  );
};

export default AccordionProgram;
