import React, { useEffect } from "react";

import { AppBar, Toolbar, Logo } from "./styles";

import { useMediaQuery } from "@mui/material";

import MobileMenu from "./MobileMenu";
import DesktopMenu from "./DesktopMenu";

import useTranslation from "../../hooks/useTranslation";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Navbar = ({ pricesRef, programRef }) => {
  const pathname = "Global";
  const componentName = "Navbar";
  const {
    logo: {
      src: { mobile: mobileSrc, desktop: desktopSrc },
      alt: logoAlt,
    },
    menu: menuTranslation,
    mobileMenu: mobileMenuTranslation,
  } = useTranslation(pathname, componentName);

  const { pathname: locationPathname, hash } = useLocation();
  const navigate = useNavigate();

  const scroll = (selectedHash) => {
    setTimeout(() => {
      if (selectedHash) {
        const ref = selectedHash === "program" ? programRef : pricesRef;
        if (ref?.current) {
          ref.current.scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        }
      }
    }, 100);
  };

  useEffect(() => {
    if (hash) {
      scroll(hash.substring(1));
    }
  }, [locationPathname]);

  const handleNavigate = (href) => {
    if (!href) return;
    const [pathname, hash] = href.split("#");
    if (hash && pathname === locationPathname) {
      scroll(hash);
    } else {
      navigate(href);
    }
  };

  const isMobile = useMediaQuery("(max-width:960px)");

  const menuProps = {
    menuTranslation,
    mobileMenuTranslation,
    isMobile,
    handleNavigate,
  };

  return (
    <AppBar component="nav" position="static">
      <Toolbar>
        <Link to="/">
          <Logo src={isMobile ? mobileSrc : desktopSrc} alt={logoAlt} />
        </Link>
        {isMobile ? (
          <MobileMenu {...menuProps} />
        ) : (
          <DesktopMenu {...menuProps} />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
