export const getSlidesToShow = (isDesktop, isMobile) => {
  switch (true) {
    case isDesktop:
      return 3;
    case !isMobile:
      return 2;
    default:
      return 1;
  }
};
