import * as React from "react";

//components
import Questions from "./Questions";

//material ui
import { Box, Typography, Tab, Tabs, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";

const useStyles = (theme, isMobile) => ({
  container: {
    width: "100%",
    paddingBottom: "100px",
    marginBottom: "117px",
    background:
      "linear-gradient(to top, #f4f4f4 0%, rgba(244, 244, 244, 0) 100%)",
    borderRadius: "0 0 240px 0",
  },
  titleContainer: {
    marginTop: "100px",
    display: { md: "flex" },
    justifyContent: { md: "center" },
    alignItems: { md: "center" },
  },
  line: {
    width: "25px",
    border: `1px solid ${theme.palette.red.main}`,
    marginRight: "8px",
    backgroundColor: theme.palette.red.main,
  },
  tabs: {
    marginTop: "117px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "div.MuiTabs-flexContainer": {
      paddingTop: "11px",
    },
    "button.Mui-selected": {
      backgroundColor: theme.palette.white.main,
      color: "inherit",
    },
  },
  tab: {
    borderRadius: "20px 20px 0 0",
    fontFamily: theme.typography.montserrat.bold,
    fontSize: { xl: "22px", lg: "20px", md: "15px" },
    backgroundColor: "#f4f4f4",
    color: theme.palette.blue.main,
    boxShadow: "5px -5px 12px 0 rgba(0, 0, 0, 0.05)",
  },
  typography: {
    section: {
      fontSize: isMobile ? "14px" : "16px",
      fontWeight: "bold",
      letterSpacing: "2px",
      color: theme.palette.red.main,
    },
    sectionDescription: {
      fontSize: isMobile ? "32px" : "65px",
      fontFamily: theme.typography.montserrat.bold,
      color: theme.palette.blue.main,
      marginTop: isMobile ? "16px" : "10px",
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const FaqTabs = ({ sections }) => {
  const isMobile = useMediaQuery("(max-width:899px)");

  const theme = useTheme();
  const classes = useStyles(theme, isMobile);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box theme={theme} sx={classes.container}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={classes.tabs}
          centered
          TabIndicatorProps={{ style: { background: "none" } }}
          textColor="primary"
        >
          {sections.map((section, index) => {
            return (
              <Tab
                label={section.title}
                {...a11yProps(index)}
                sx={classes.tab}
                textColor="primary"
                key={index}
              />
            );
          })}
        </Tabs>
      </Box>
      {sections.map((section, index) => {
        return (
          <TabPanel value={value} index={index} key={index}>
            <Box sx={classes.titleContainer} component={"span"}>
              <Box sx={classes.line} component={"span"}></Box>
              <Typography sx={classes.typography.section} component={"span"}>
                {section.section}
              </Typography>
            </Box>
            <Typography
              sx={classes.typography.sectionDescription}
              component={"span"}
            >
              {section.title}
            </Typography>
            <Questions questions={section.questions} />
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default FaqTabs;
