import React from "react";

//icons
import StarIcon from "@mui/icons-material/Star";

//material ui
import { useTheme } from "@mui/styles";

const useStyles = (theme) => ({
  star: {
    color: theme.palette.yellow.main,
    width: "13.7px",
    height: "13.7px",
  },
});

const StarRating = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div>
      <StarIcon sx={classes.star} />
      <StarIcon sx={classes.star} />
      <StarIcon sx={classes.star} />
      <StarIcon sx={classes.star} />
      <StarIcon sx={classes.star} />
    </div>
  );
};

export default StarRating;
