import React from "react";

import Activities from "./Activities";
import {
  ActivitiesContainer,
  Title,
  Container,
  Subtitle,
  Note,
  NoteContainer,
} from "./styles";

import { Box, useMediaQuery } from "@mui/material";

import useTranslation from "../../../hooks/useTranslation";
import { useLocation } from "react-router";

import { formatActivitiesPlaces } from "../../../helpers/activities";

const TypicalDay = () => {
  const { pathname } = useLocation();
  const componentName = "TypicalDay";

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const translation = useTranslation(pathname, componentName);

  const {
    title,
    firstSubtitle,
    secondSubtitle,
    desktopActivities,
    mobileActivities,
    desktopNote,
    mobileNote,
  } = translation;

  return (
    <Box sx={{ width: "100%", background: "rgba(244, 244, 244, 1)" }}>
      <Container>
        <Box>
          <Title component="h2">{title}</Title>
          <Subtitle component="h3">{firstSubtitle}</Subtitle>
          {!isMobile && <Subtitle component="h3"> {secondSubtitle}</Subtitle>}
        </Box>
        <ActivitiesContainer>
          <Activities
            activities={
              isMobile
                ? mobileActivities
                : formatActivitiesPlaces(desktopActivities)
            }
          />
          <NoteContainer>
            <Note>{isMobile ? mobileNote : desktopNote}</Note>
          </NoteContainer>
        </ActivitiesContainer>
      </Container>
    </Box>
  );
};

export default TypicalDay;
