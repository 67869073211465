import React, { createContext, useEffect, useMemo } from "react";
import Tools from "../components/Bootcamp/ToolsJs/Tools";
import Funding from "../components/Bootcamp/Funding";
import Dates from "../components/Bootcamp/Dates";
import Testimonials from "../components/Bootcamp/Testimonials";
import TypicalDay from "../components/Bootcamp/TypicalDay";
import CardsBenefits from "../components/Bootcamp/CardsBenefits";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/styles";
import Hero from "../commons/Hero";
import AccordionProgram from "../components/Bootcamp/AccordionProgram";
import LaborInsertion from "../components/Bootcamp/LaborInsertion/LaborInsertion";
import OverlapText from "../commons/OverlapText";
export const ColorContext = createContext({
  colorTheme: null,
  mainColor: null,
  secondaryColor: null,
  iconColor: null,
});

const Bootcamp = ({ programRef, pricesRef }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;
  const theme = useTheme();
  const selectedBootcamp = pathname.split("/").at(-1);
  const colors = useMemo(
    () => ({
      javascript: {
        colorTheme: "yellow",
        mainColor: theme.palette.yellow.main,
        secondaryColor: theme.palette.yellow.secondary,
        iconColor: theme.palette.yellow.third,
      },
    }),
    [
      theme.palette.yellow.main,
      theme.palette.yellow.secondary,
      theme.palette.yellow.third,
      theme.palette.blue.ninth,
      theme.palette.blue.tenth,
      theme.palette.blue.lightblue,
    ]
  );

  useEffect(() => {
    if (!["javascript"].includes(selectedBootcamp)) {
      navigate("/404");
    }
  }, [selectedBootcamp, navigate]);

  return (
    <ColorContext.Provider
      value={colors[selectedBootcamp]}
      displayName="ColorContext"
    >
      <Hero />
      <OverlapText componentName="BootcampText" />
      <CardsBenefits />
      <AccordionProgram programRef={programRef} />
      <Tools />
      <LaborInsertion />
      <TypicalDay />
      <Testimonials />
      <Funding pricesRef={pricesRef} />
      <Dates />
    </ColorContext.Provider>
  );
};

export default Bootcamp;
